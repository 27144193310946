import styled from "styled-components";
import MessageBanner from "../MessageBanner";
import { rem, responsive } from "../../utils/style";
import Text from "../Text";
import Currency from "../Currency";
import { useSelector } from "react-redux";
import userSelectors from "../../store/user/selectors";

import { MagicSVGImage } from "../MagicSVGImage";
import { IconCaretRightWhite, IconHighFiveWhite } from "../../utils/svg";

const BannerAnchorStyle = styled.a`
  padding: 16px 0;
  font-size: ${rem(16)};
  line-height: 21px;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  color: #fff;

  &:hover {
    color: #fff;
  }

  ${responsive.sm`
    justify-content: start;  
  `};

  ${responsive.md`
    justify-content: center;      
  `};

  img {
    opacity: 0.56;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-top: 2px;
      margin-left: 8px;
    }
  }
`;

export default function ReferralBanner(props) {
  const authenticated = useSelector(userSelectors.isLoggedIn);

  if (!authenticated) {
    return null;
  }

  return (
    <MessageBanner {...props}>
      <BannerAnchorStyle href={`${process.env.GATSBY_ACCOUNT_URL}/refer`}>
        <MagicSVGImage
          aria-hidden={true}
          alt={"two hands high five"}
          src={IconHighFiveWhite}
          width={24}
          height={24}
        />
        <Text
          id="banners.referral-banner.message"
          defaultMessage={"Get {amount} off for each friend you refer"}
          values={{
            amount: <Currency value={35} round={true} />,
          }}
        />
        <MagicSVGImage
          alt={"right arrow"}
          src={IconCaretRightWhite}
          width={6.6}
          height={12}
          aria-hidden={true}
        />
      </BannerAnchorStyle>
    </MessageBanner>
  );
}
