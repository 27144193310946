import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "COUPON";

export default class Coupon extends Model {
  // Attributes
  code: string = "";
  amountOff: number = 0;
  percentOff: number = 0;
  duration: string = "";
  durationInMonths: number = 0;

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
