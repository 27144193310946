import ProductOffer, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addProductOffersFromGraphQL(productOfferData) {
  return (dispatch) => {
    productOfferData.forEach((data) => {
      dispatch(addProductOffer(data));
    });
  };
}

export function addProductOffer(data) {
  const productOffer = new ProductOffer();
  productOffer.deserialize(data);

  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().productOffers,
      ModelType,
      productOffer,
    );
  };
}
