import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";
import { createSelector } from "reselect";
import { allProductOffersPlansForCurrentStoreByIds } from "../product-offer-plan/selectors";
import { contentfulProductBundlesForSlug } from "../staticData/selectors";
import ProductOfferPlan from "../product-offer-plan/model";

export const ModelType = "PRODUCT-OFFER";

export default class ProductOffer extends Model {
  slug: string = "";
  name: string = "";
  imageUrl: string = "";
  productOfferPlanIds: Array<string> = [];

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.productOfferPlanIds = this.getRelationshipIds(
      relationships,
      "product_offer_plans",
    );
  }

  productOfferPlans = createSelector(
    (state) => state,
    (state) => {
      return allProductOffersPlansForCurrentStoreByIds(
        state,
        this.productOfferPlanIds,
      );
    },
  );

  initialPlanIds = createSelector(
    (state) => state,
    (state) => {
      return this.productOfferPlans(state).map(
        (productOfferPlan: ProductOfferPlan) => {
          return productOfferPlan.initialPlanId;
        },
      );
    },
  );

  futurePlanIds = createSelector(
    (state) => state,
    (state) => {
      return this.productOfferPlans(state).map(
        (productOfferPlan: ProductOfferPlan) => {
          return productOfferPlan.futurePlanId;
        },
      );
    },
  );

  currency(state) {
    return this.productOfferPlans(state)[0].initialPlan(state).currency;
  }

  initialAmount(state) {
    const productOfferPlans = this.productOfferPlans(state);

    return productOfferPlans.reduce((acc, productOfferPlan) => {
      const initialPlan = productOfferPlan.initialPlan(state);
      return acc + initialPlan.amount;
    }, 0);
  }

  futureAmount(state) {
    const productOfferPlans = this.productOfferPlans(state);

    return productOfferPlans.reduce((acc, productOfferPlan) => {
      const initialPlan = productOfferPlan.futurePlan(state);
      return acc + initialPlan.amount;
    }, 0);
  }

  contentfulProductBundle(state) {
    return contentfulProductBundlesForSlug(state, this.slug);
  }

  productQuantity(state) {
    return this.productOfferPlans(state).reduce((acc, productOfferPlan) => {
      return acc + productOfferPlan.quantity;
    }, 0);
  }
}
