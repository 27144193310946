export const BannerType = {
  GIFT: "GIFT",
  IDME: "IDME",
  PODCAST: "PODCAST",
  WELCOME: "WELCOME",
  REFERRAL: "REFERRAL",
  PROMOTION: "PROMOTION",
  CUSTOM: "CUSTOM",
  BFCM_PROSPECT: "BFCM_PROSPECT",
  BFCM_SUBSCRIBER: "BFCM_SUBSCRIBER",
};
