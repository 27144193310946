import { createSelector } from "reselect";

import subscriptionItemSelectors from "../subscription-item/selectors";
import invoiceItemSelectors from "../invoice-item/selectors";
import i18nSelectors from "../i18n/selectors";

const _byId = (state) => state.subscriptions.byId;
const _allIds = (state) => state.subscriptions.allIds;

const allSubscriptions = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

const hasActiveSubscription = createSelector(_allIds, (allIds) => {
  return !!allIds.length;
});

const availableSubscriptions = createSelector(
  (state) => state,
  allSubscriptions,
  i18nSelectors.maxOrderItems,
  (state, allSubscriptions, maxOrderItems) => {
    return allSubscriptions.filter(
      ({ id }) => itemQuantityForSubscription(state, { id }) < maxOrderItems,
    );
  },
);

const hasAvailableSubscription = createSelector(
  availableSubscriptions,
  (availableSubscriptions) => !!availableSubscriptions.length,
);

const hasBundlePromoEligibleSubscription = createSelector(
  availableSubscriptions,
  (availableSubscriptions) => {
    if (!availableSubscriptions.length) return false;

    return !!availableSubscriptions.find((subscription) => {
      const isBundlePromoEligible =
        !subscription.promotions ||
        !subscription.promotions.find(
          (promotion) => promotion.name === "bundle",
        );
      return isBundlePromoEligible;
    });
  },
);

const itemQuantityForSubscription = createSelector(
  subscriptionItemSelectors.itemQuantityForSubscription,
  invoiceItemSelectors.itemQuantityForSubscription,
  (subscriptionItemQuantity, invoiceItemQuantity) =>
    subscriptionItemQuantity + invoiceItemQuantity,
);

export default {
  allSubscriptions,
  hasActiveSubscription,
  availableSubscriptions,
  hasAvailableSubscription,
  hasBundlePromoEligibleSubscription,
  itemQuantityForSubscription,
};
