import { createSelector } from "reselect";

const navigation = (state) => state.navigation;

const panels = createSelector(navigation, (navigation) => navigation.panels);
const isOpen = createSelector(panels, (panels) => !!panels[0]);

export default {
  panels,
  isOpen,
};
