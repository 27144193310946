import ProductGroup, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addProductGroupsFromGraphQL(productGroupData) {
  return (dispatch) => {
    productGroupData.forEach((data) => {
      const productGroup = new ProductGroup();
      productGroup.deserialize(data);
      dispatch(addProductGroup(productGroup));
    });
  };
}

export function addProductGroupsFromNormalizedData(data, associations) {
  return (dispatch) => {
    const productGroup = new ProductGroup();
    productGroup.deserialize(data, associations);
    dispatch(addProductGroup(productGroup));
  };
}

export function addProductGroup(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().productGroups,
      ModelType,
      data,
      associations,
    );
  };
}
