import MagicLink from "../../MagicLink";
import Text from "../../Text";
import TextNotice from "./TextNotice";

const FDAText = () => {
  return (
    <TextNotice>
      <Text
        id="footer.fdatext"
        defaultMessage="The FDA plays a specific role in regulating the nutraceutical industry."
      />{" "}
      <MagicLink
        to="/articles/the-fdas-role-in-the-supplement-industry"
        title="Read more about the FDAs role in the supplement industry."
        ariaLabel="Read more about the FDAs role in the supplement industry."
      >
        <Text id="general.learn-more" defaultMessage="Learn More" />
      </MagicLink>
      .
    </TextNotice>
  );
};
export default FDAText;
