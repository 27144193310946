import { ActionType } from "../../constants/product";

// Hooks
import { useSelector } from "react-redux";
import useHasMounted from "../useHasMounted";

// Selectors
import promotionSelectors from "../../store/promotion/selectors";

export const useSiteWidePromotion = (actionType) => {
  const promotion = useSelector(promotionSelectors.siteWidePromotion);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);

  const hasMounted = useHasMounted();

  if (!hasMounted || !promotion) {
    return null;
  }

  if (actionType === ActionType.Checkout) {
    return promotion === appliedPromotion ? promotion : null;
  }

  return actionType !== ActionType.Switch ? promotion : null;
};

export default useSiteWidePromotion;
