import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PROMOTION-INTENT";

export default class PromotionIntent extends Model {
  // Attributes
  promotionName: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
