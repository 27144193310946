import { useState } from "react";
import styled from "styled-components";
import MagicLink from "../MagicLink";
import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

const Banner = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fdf8ec;

  ${responsive.lg`
    align-items: center;
  `};
`;

const Copy = styled.p`
  margin: 0;
  color: ${Color.ritualBlue};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 300;
  padding-right: 16px !important;

  ${responsive.sm`
    padding-right: 24px !important;
  `};

  ${responsive.md`
    padding-right: 40px !important;
  `};

  a {
    text-decoration: underline !important;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${Color.ritualBlue};
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 400;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  display: block;
  flex: 0 0 16px;

  svg {
    width: 16px;
    height: 16px;

    g {
      stroke-width: 1;
    }
  }
`;

export default function USCookieBanner(props) {
  const [showBanner, setShowBanner] = useState(props.showBanner);

  if (!showBanner) return null;

  const handleClose = () => {
    setShowBanner(false);
  };

  return (
    <Banner data-test="banner">
      <Copy>
        Ritual, and the companies we work with, use cookies and other
        technologies to collect data to improve your experience on our site,
        analyze site usage, and facilitate advertising. By continuing to browse
        this site, you agree to our{" "}
        <MagicLink to="/terms-of-service">Terms of Service</MagicLink> and{" "}
        <MagicLink to="/privacy-policy">Privacy Policy</MagicLink>.
      </Copy>
      <CloseButton onClick={handleClose}>
        <Icons.Close />
      </CloseButton>
    </Banner>
  );
}
