import { useEffect, useState } from "react";
import { variation as ldVariation } from "../services/launchDarkly";

const useVariation = (
  featureKey: string,
  defaultValue: any = false,
  shouldSample = true,
) => {
  const [variation, setVariation] = useState(defaultValue);

  useEffect(() => {
    if (shouldSample) {
      setVariation(ldVariation(featureKey, defaultValue));
    }
  }, [shouldSample]);

  return variation;
};

export default useVariation;
