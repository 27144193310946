import { useEffect, useState } from "react";
import styled from "styled-components";
import FocusTrap from "focus-trap-react";

// Store
import { useSelector } from "react-redux";
import userSelectors from "../../../store/user/selectors";
import i18nSelectors from "../../../store/i18n/selectors";

// Utils
import { Color, responsive } from "../../../utils/style";
import { Icons } from "../../../utils/react-svg";

// Services
import intl from "../../../services/intl";

// Components
import MagicLink from "../../MagicLink";
import Text from "../../Text";
import metrics from "../../../utils/metrics";

const IconContainer = styled.div`
  position: relative;
`;

const Dropdown = styled.div`
  display: none;

  width: 307px;
  padding: 32px;
  background-color: ${Color.white};
  box-shadow: 0px 8px 30px -10px rgba(20, 43, 111, 0.16);

  position: absolute;
  right: -64px;
  top: 37px;

  ${responsive.md`
    right: -61px;
    top: 45px;  
  `}

  &.expanded {
    display: flex;
    flex-direction: column;
  }
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const LinkItem = styled.li`
  font-weight: 300;
  margin-bottom: 12px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    opacity: 0.56;
  }
`;

const Button = styled.button`
  padding: 0;
  border: 0;
  background: none;

  display: flex;
  align-items: center;
`;

const AccountIcon = styled(Icons.Account)`
  height: 20px;
  width: 21px;

  ${responsive.md`
    height: 24px;
    width: 25px;
  `}
`;

const AccountUrl = process.env.GATSBY_ACCOUNT_URL;

const AccountDropdown = ({ onClick }) => {
  const [expanded, setExpanded] = useState(false);

  const isLoggedIn = useSelector(userSelectors.isLoggedIn);

  const locale = useSelector(i18nSelectors.locale);

  const Links = [
    { title: "nav.account.home", href: `${AccountUrl}` },
    { title: "nav.account.profile", href: `${AccountUrl}/me/update` },
    { title: "nav.account.order-history", href: `${AccountUrl}/order-history` },
    ...(locale === "en-US"
      ? [{ title: "nav.account.refer", href: `${AccountUrl}/refer` }]
      : []),
    { title: "nav.account.sign-out", href: `${AccountUrl}/logout` },
  ];

  function handleClick() {
    if (isLoggedIn) {
      setExpanded(!expanded);
      const eventName = !expanded ? "Nav Opened" : "Nav Closed";
      metrics.track(eventName, {
        location: "Header Nav",
      });
    }
    onClick && onClick();
  }

  function hideDropdown() {
    setExpanded(false);
  }

  function onLinkClick(e) {
    const { href, innerHTML } = e.target;
    e.preventDefault();

    metrics.track("Nav Item Clicked", {
      location: "Header Nav",
      title: innerHTML,
    });

    window.location = href;
  }

  if (!isLoggedIn) {
    return (
      <IconContainer>
        <MagicLink
          aria-label={intl.t("nav.account.label")}
          to="/login"
          onClick={handleClick}
        >
          <AccountIcon />
        </MagicLink>
      </IconContainer>
    );
  }

  return (
    <FocusTrap
      active={expanded}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
        onDeactivate: hideDropdown,
      }}
    >
      <IconContainer>
        <Button
          aria-label={intl.t("nav.account.label")}
          aria-haspopup="true"
          aria-expanded={expanded}
          onClick={handleClick}
        >
          <AccountIcon />
        </Button>
        <Dropdown className={expanded && "expanded"}>
          <LinkList>
            {Links.map((link) => (
              <LinkItem key={link.title}>
                <a href={link.href} onClick={onLinkClick}>
                  <Text id={link.title} />
                </a>
              </LinkItem>
            ))}
          </LinkList>
        </Dropdown>
      </IconContainer>
    </FocusTrap>
  );
};

export default AccountDropdown;
