import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "INVOICE-ITEM";

export default class InvoiceItem extends Model {
  // Attributes
  quantity: number = 0;

  // Relationships
  planId: string = "";
  subscriptionId: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.planId = this.getRelationshipId(relationships, "plan");
    this.subscriptionId = this.getRelationshipId(relationships, "subscription");
  }
}
