// RitualLogos
import RitualLogo from "../../static/react-svg/logo.svg";
// Icons

import Account from "../../static/react-svg/icon-account.svg";
import StarFull from "../../static/react-svg/icon-star.svg";
import StarHalfYellow from "../../static/react-svg/icon-star-half-yellow.svg";
import StarThirdYellow from "../../static/react-svg/icon-star-third-yellow.svg";
import StarThreeQuartersYellow from "../../static/react-svg/icon-star-three-quarters-yellow.svg";
import CaretLeft from "../../static/react-svg/icon-caret-left.svg";
import CaretRight from "../../static/react-svg/icon-caret-right.svg";
import CaretDown from "../../static/react-svg/icon-caret-down.svg";
import Close from "../../static/react-svg/icon-close.svg";
import ArrowRight from "../../static/react-svg/arrow-right.svg";
import ArrowLeft from "../../static/react-svg/arrow-left.svg";
import ArrowLeftLarge from "../../static/react-svg/arrow-left-large.svg";
import ArrowRightLarge from "../../static/react-svg/arrow-right-large.svg";
import ArrowRoundedLeft from "../../static/react-svg/arrow-rounded-left.svg";
import ArrowRoundedRight from "../../static/react-svg/arrow-rounded-right.svg";
import ArrowRoundedUp from "../../static/react-svg/arrow-rounded-up.svg";
import ArrowScroll from "../../static/react-svg/arrow-scroll.svg";
import Vegan from "../../static/react-svg/icon-vegan.svg";
import Gluten from "../../static/react-svg/icon-gluten.svg";
import Non from "../../static/react-svg/icon-non.svg";
import Fillers from "../../static/react-svg/icon-fillers.svg";
import Flag from "../../static/react-svg/icon-flag.svg";
import Clinical from "../../static/react-svg/icon-clinical.svg";
import Balloon from "../../static/react-svg/icon-balloon.svg";
import Recurring from "../../static/react-svg/icon-recurring.svg";
import CircleStar from "../../static/react-svg/icon-circle-star.svg";
import Cancel from "../../static/react-svg/icon-cancel.svg";
import Nonausea from "../../static/react-svg/icon-no-nausea.svg";
import Upvote from "../../static/react-svg/icon-yes.svg";
import Downvote from "../../static/react-svg/icon-no.svg";
import Check from "../../static/react-svg/icon-check.svg";
import CheckRounded from "../../static/react-svg/icon-good.svg";
import CheckYellow from "../../static/react-svg/icon-check-yellow.svg";
import CheckLightBlue from "../../static/react-svg/icon-check-light-blue.svg";
import Download from "../../static/react-svg/icon-download.svg";
import Play from "../../static/react-svg/icon-play.svg";
import Quote from "../../static/react-svg/icon-quote.svg";
import PlusCircle from "../../static/react-svg/icon-plus-circle.svg";
import Plus from "../../static/react-svg/icon-plus.svg";
import Minus from "../../static/react-svg/icon-minus.svg";
import Bad from "../../static/react-svg/icon-bad.svg";
import InfoSVG from "../../static/react-svg/icon-info.svg";
import Lock from "../../static/react-svg/icon-lock.svg";
import CaretDownRounded from "../../static/react-svg/caret-down-rounded.svg";
import CaretUpRounded from "../../static/react-svg/caret-up-rounded.svg";
import Hamburger from "../../static/react-svg/icon_hamburger.svg";
import BundlePackage from "../../static/react-svg/icon-bundle-package.svg";
import BundlePlan from "../../static/react-svg/icon-bundle-plan.svg";
import BundleDual from "../../static/react-svg/icon-bundle-dual.svg";
import Bottle from "../../static/react-svg/icon-bottle.svg";
import ArrowCurvedLg from "../../static/react-svg/arrow-curve-yellow-lg.svg";
import ArrowCurvedSm from "../../static/react-svg/arrow-curve-yellow-sm.svg";
import Peace from "../../static/react-svg/icon-peace.svg";
import Trash from "../../static/react-svg/icon-trash.svg";
import Remove from "../../static/react-svg/icon-remove.svg";
import Email from "../../static/react-svg/icon-email.svg";
import UsCheck from "../../static/react-svg/icon-us-check.svg";
import ThemCross from "../../static/react-svg/icon-them-x.svg";
import IconCfu from "../../static/react-svg/icon-cfu.svg";
import Shelfstable from "../../static/react-svg/icon-shelf-stable.svg";
import Obgyn from "../../static/react-svg/icon-obgyn.svg";

// Social
import Facebook from "../../static/react-svg/icon_facebook.svg";
import Instagram from "../../static/react-svg/icon_instagram.svg";
import Pinterest from "../../static/react-svg/icon_pinterest.svg";
import Twitter from "../../static/react-svg/icon_twitter.svg";
import YouTube from "../../static/react-svg/icon-youtube.svg";
// Benefits
import Gene from "../../static/react-svg/icon-gene.svg";

import Traceable from "../../static/react-svg/icon-traceable.svg";
// Shop Landing (used in Icons list)
import Delivered from "../../static/react-svg/icon-delivered.svg";
import Freeshipping from "../../static/react-svg/icon-freeshipping.svg";
import Goodstuff from "../../static/react-svg/icon-goodstuff.svg";
import Changedelivery from "../../static/react-svg/icon-changedelivery.svg";
import Cancellation from "../../static/react-svg/icon-cancellation.svg";
import Guarantee from "../../static/react-svg/icon-guarantee.svg";
// Clinical
import University from "../../static/react-svg/icon-university.svg";
import Blind from "../../static/react-svg/icon-blind.svg";
import Placebo from "../../static/react-svg/icon-placebo.svg";
import Weeks from "../../static/react-svg/icon-weeks.svg";
import Women from "../../static/react-svg/icon-women.svg";
// Cart
import CartBagFull from "../../static/react-svg/icon-cart-full.svg";
import CartBagEmpty from "../../static/react-svg/icon-cart-empty.svg";

import Loading from "../../static/react-svg/icon-loading.svg";
// Value Props
import Tested from "../../static/react-svg/icon-tested.svg";
import Sugar from "../../static/react-svg/icon-sugar.svg";
import Flavor from "../../static/react-svg/icon-flavor.svg";

// Subscription Benefits
import StarCircle from "../../static/react-svg/icon-star-circle.svg";
import Shipping from "../../static/react-svg/icon-shipping.svg";
import CircleX from "../../static/react-svg/icon-circle-x.svg";

// Custom styles
import styled from "styled-components";
import { Color } from "./styleDesignSystem";

export const RitualLogos = {
  RitualLogo,
};

type InfoProps = { $color?: string; $background?: string; $outline?: string };
const Info = styled(InfoSVG).attrs<InfoProps, Required<InfoProps>>(
  ({ $color, $background, $outline }) => ({
    $color: $color || Color.indigoBlue,
    $background: $background || "transparent",
    $outline: $outline || $color || Color.indigoBlue,
  }),
)`
  path {
    stroke: ${({ $color }) => $color};
    &.icon-info-i-dot {
      stroke: none;
      fill: ${({ $color }) => $color};
    }
    &.icon-info-circle {
      stroke: ${({ $outline }) => $outline};
      fill: ${({ $background }) => $background};
    }
  }
`;

export const Icons = {
  Account,
  StarFull,
  StarHalfYellow,
  StarThirdYellow,
  StarThreeQuartersYellow,
  CaretLeft,
  CaretRight,
  CaretDown,
  Close,
  ArrowRight,
  ArrowLeft,
  ArrowLeftLarge,
  ArrowRightLarge,
  ArrowRoundedLeft,
  ArrowRoundedRight,
  ArrowRoundedUp,
  ArrowScroll,
  Vegan,
  Gluten,
  Non,
  Fillers,
  Flag,
  Clinical,
  Balloon,
  Recurring,
  CircleStar,
  Cancel,
  Nonausea,
  Gene,
  Upvote,
  Downvote,
  Check,
  CheckRounded,
  CheckYellow,
  CheckLightBlue,
  Download,
  Play,
  Quote,
  PlusCircle,
  Plus,
  Minus,
  Bad,
  Delivered,
  Freeshipping,
  Goodstuff,
  Changedelivery,
  Cancellation,
  Guarantee,
  Info,
  Lock,
  CaretDownRounded,
  CaretUpRounded,
  Hamburger,
  BundlePackage,
  BundlePlan,
  BundleDual,
  Bottle,
  ArrowCurvedLg,
  ArrowCurvedSm,
  University,
  Traceable,
  Peace,
  Trash,
  Remove,
  Tested,
  Sugar,
  Flavor,
  Email,
  UsCheck,
  ThemCross,
  Loading,
  IconCfu,
  Shelfstable,
  Obgyn,
};

export const Social = {
  Facebook,
  Instagram,
  Pinterest,
  Twitter,
  YouTube,
};

export const ClinicalIcons = {
  University,
  Blind,
  Placebo,
  Weeks,
  Women,
};

export const CartIcons = {
  CartBagFull,
  CartBagEmpty,
};

export const SubBenefits = {
  StarCircle,
  Shipping,
  CircleX,
};
