export default function reducer(state = {}, action) {
  switch (action.type) {
    case `WAITLISTED`:
      const waitlisted = { ...state.waitlisted };
      waitlisted[action.payload.sku] = true;
      return {
        ...state,
        waitlisted,
      };
    default:
      return state;
  }
}
