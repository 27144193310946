import { shouldPolyfill } from "@formatjs/intl-pluralrules/should-polyfill";
import intl from "../services/intl";
import translationImports from "../data/translations/index";
import locales from "../../config/locales";

export async function polyfillPluralRules() {
  if (shouldPolyfill()) {
    await Promise.all([
      import("@formatjs/intl-locale/polyfill"),
      import("@formatjs/intl-pluralrules/polyfill"),
    ]);
  }
  if (Intl.PluralRules.polyfilled) {
    await import("@formatjs/intl-pluralrules/locale-data/en");
  }
}

export async function preloadTranslations(
  activeLocale = findLocaleFromPathname(),
) {
  const translationData = await translationImports[activeLocale]();
  intl.addTranslationData(activeLocale, translationData.default);
}

export function findLocaleFromPathname() {
  if (typeof window === "undefined") return intl.defaultLocale.locale;

  let found = locales[Object.keys(locales)[0]];
  for (let key in locales) {
    const path = window.location.pathname.replace(/^\/|\/$/g, "", "");
    if (`/${path}/`.startsWith(`/${locales[key].path}/`)) found = locales[key];
  }

  return found.locale;
}
