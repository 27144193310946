import { createSelector } from "reselect";
import { matchesAll } from "../../utils/filter";
import planSelectors from "../plan/selectors";
import productGroupSelectors from "../product-group/selectors";
import subscriptionSelectors from "../subscription/selectors";
import subscriptionItemSelectors from "../subscription-item/selectors";

const _byId = (state) => state.products.byId;
const _allIds = (state) => state.products.allIds;

const allProducts = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

const productForSku = createSelector(
  allProducts,
  (_, props) => props.sku,
  (allProducts, sku) => allProducts.find((product) => product.sku === sku),
);

const productForContentfulId = createSelector(
  allProducts,
  (_, props) => props.contentfulId,
  (allProducts, contentfulId) =>
    allProducts.find((product) => product.contentfulId === contentfulId),
);

const productForId = createSelector(
  allProducts,
  (_, props) => props.id,
  (allProducts, id) => allProducts.find((product) => product.id === id),
);

const reviewCountsMap = createSelector(allProducts, (allProducts) => {
  const countsMap = {};
  allProducts.forEach((product) => {
    countsMap[product.id] = product.reviews.count;
  });
  return countsMap;
});

const reviewRatingsMap = createSelector(allProducts, (allProducts) => {
  const ratingsMap = {};
  allProducts.forEach((product) => {
    ratingsMap[product.id] = product.reviews.average_rating;
  });
  return ratingsMap;
});

const allDefaultProducts = createSelector(
  allProducts,
  productGroupSelectors.allProductGroups,
  (allProducts, allProductGroups) =>
    allProducts.filter((product) => {
      const productGroup = allProductGroups.find(
        (productGroup) => productGroup.id === product.productGroupId,
      );
      return !productGroup || product.id === productGroup.defaultProductId;
    }),
);

const filteredProducts = createSelector(
  allDefaultProducts,
  (_, props) => props.filters,
  (allDefaultProducts, filters) => {
    return allDefaultProducts.filter(
      (product) => matchesAll(product, filters) && product.sku,
    );
  },
);

const productBillingTypes = createSelector(
  (state, props) =>
    planSelectors.allPlansForProductSku(state, { sku: props.sku }),
  (_, props) => props.sku,
  (currentPlans) => currentPlans,
);

const productsFromAllSubscriptions = createSelector(
  [(state) => state, subscriptionSelectors.allSubscriptions],
  (state, subscriptions) => {
    const subscriptionItems = subscriptions
      .map((subscription) =>
        subscriptionItemSelectors.allItemsForSubscription(state, subscription),
      )
      .flat();

    const plans = subscriptionItems
      .map((subscriptionItem) =>
        planSelectors.planForId(state, { id: subscriptionItem.planId }),
      )
      .filter(Boolean);

    return plans.map(({ id }) =>
      planSelectors.productForPlanId(state, { id: id }),
    );
  },
);

export default {
  allProducts,
  productForSku,
  productForId,
  filteredProducts,
  reviewCountsMap,
  reviewRatingsMap,
  productForContentfulId,
  productBillingTypes,
  productsFromAllSubscriptions,
};
