import { findLocaleFromPathname } from "../../utils/intl";

const defaultLocale = findLocaleFromPathname();

export default function reducer(
  state = {
    locale: defaultLocale,
    activeLocales: ["en-US"],
    stores: [],
    localeSuggestion: null,
  },
  action,
) {
  switch (action.type) {
    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload.locale,
      };
    case "SET_ACTIVE_LOCALES":
      return {
        ...state,
        activeLocales: action.payload.activeLocales,
      };
    case "SET_STORES":
      return {
        ...state,
        stores: action.payload.stores,
      };
    case "SET_LOCALE_SUGGESTION":
      return {
        ...state,
        localeSuggestion: action.payload.localeSuggestion,
      };
    default:
      return state;
  }
}
