import intl from "../services/intl";

export function formatTotal(
  amountInCents = 0,
  deduction = false,
  isProcessing = false,
  postfix = false,
) {
  if (isProcessing) return <>&mdash;</>;
  const amountInDollars = (amountInCents / 100) * (deduction ? -1 : 1);
  return intl.formatCurrency(amountInDollars, { postfix });
}
