import { useEffect, useState, useRef } from "react";
import countdown from "../../../utils/vendor/countdown/countdown";

export default function CountdownTimer(props) {
  const { promotion } = props;

  const [count, setCount] = useState("");
  const timerId = useRef();
  useEffect(() => {
    if (shouldShowCountdownTimer()) {
      timerId.current = startCountdown();
    }
    return () => {
      window.clearInterval(timerId.current);
    };
  }, []);

  function shouldShowCountdownTimer() {
    if (!promotion) return false;
    const { expiresAt } = promotion;

    const window = 86400000 * 2; // 48 hours
    return expiresAt && new Date(expiresAt) - window < Date.now();
  }

  function startCountdown() {
    if (typeof window === "undefined") return;
    return countdown(
      (ts) => {
        if (!shouldShowCountdownTimer()) {
          endCountdown();
          return;
        }
        setCount(formatTS(ts));
      },
      new Date(promotion.expiresAt),
      countdown.HOURS | countdown.MINUTES | countdown.SECONDS,
    );
  }

  function endCountdown() {
    window.clearInterval(timerId.current);
    setCount("");
  }

  function formatTS(ts) {
    const hours = ts.hours < 10 ? `0${ts.hours}` : ts.hours;
    const minutes = ts.minutes < 10 ? `0${ts.minutes}` : ts.minutes;
    const seconds = ts.seconds < 10 ? `0${ts.seconds}` : ts.seconds;

    return `${hours}h ${minutes}m ${seconds}s`;
  }
  if (!shouldShowCountdownTimer()) return null;
  return <span data-testId="countdown-timer">{count}</span>;
}
