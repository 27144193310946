// Hooks
import { useSelector } from "react-redux";
import useHasMounted from "../useHasMounted";

// Constants
import { ActionType } from "../../constants/product";

// Selectors
import promotionSelectors from "../../store/promotion/selectors";

import { ProductSKU } from "../../utils/product";

const useProductSpecificPromotion = (actionType, product) => {
  const promotions = useSelector(promotionSelectors.productSpecificPromotions);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const hasMounted = useHasMounted();

  if (!hasMounted || !product || !promotions.length) {
    return null;
  }

  const promotion = promotions.find((promotion) => {
    if (actionType === ActionType.Checkout) {
      return promotion === appliedPromotion;
    }

    if (promotion.name === "Postnatal" && product.sku === ProductSKU.EPOST60) {
      return true;
    }
  });

  return promotion || null;
};

export default useProductSpecificPromotion;
