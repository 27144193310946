import styled, { keyframes } from "styled-components";
import classNames from "classnames";

import { responsive } from "../../utils/style";

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(255, 214, 0, 0.7);
  }
  70% {
    box-shadow: 0 0 0 9px rgba(255, 214, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 214, 0, 0);
  }
`;

const Pulse = styled.div`
  display: flex;
  align-items: center;

  span {
    position: relative;
    display: flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ffd600;
    animation: ${pulseAnimation} 2s infinite;
    margin: 10px;
  }

  span::after {
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #ffd600;
    border-radius: 50%;
    position: absolute;
    left: -3px;
    top: -3px;
  }

  &.big {
    ${responsive.sm`
      span {
        width: 15px;
        height: 15px;
      }

      span::after {
        width: 23px;
        height: 23px;

        left: -4px;
        top: -4px;
      }
    `}
  }
`;

const PulseIcon = (props) => {
  return (
    <Pulse className={classNames(props.className)} data-testid="pulseIcon">
      <span></span>
    </Pulse>
  );
};

export default PulseIcon;
