import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";
import { createSelector } from "reselect";
import planSelectors from "../plan/selectors";

export const ModelType = "PRODUCT-OFFER-PLAN";

export default class ProductOfferPlan extends Model {
  quantity: number = 0;
  futurePlanId: string = "";
  initialPlanId: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.futurePlanId = this.getRelationshipId(relationships, "future_plan");
    this.initialPlanId = this.getRelationshipId(relationships, "initial_plan");
  }

  futurePlan = createSelector(
    (state) => state,
    (state) => {
      return planSelectors.planForId(state, { id: this.futurePlanId });
    },
  );

  initialPlan = createSelector(
    (state) => state,
    (state) => {
      return planSelectors.planForId(state, { id: this.initialPlanId });
    },
  );
}
