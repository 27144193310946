const NameMap = function (node) {
  let productName = node.name;
  let plainName = node.name;

  if (productName && productName.indexOf("Essential ") !== -1) {
    let nameSuffix = productName.split("Essential ")[1];
    productName = `Essential <em>${nameSuffix}</em>`;
  }

  // if productname contains "™" or "®", then wrap it in a <sup> tag
  if (productName && productName.indexOf("™") !== -1) {
    // TODO: uncomment next line to enable superscripting of ™
    // productName = productName.replace("™", "<sup>™</sup>");
    // strip out the ™ from the plain name
    plainName = plainName.replace("™", "");
  } else if (productName && productName.indexOf("®") !== -1) {
    // TODO: uncomment next line to enable superscripting of ®
    // productName = productName.replace("®", "<sup>®</sup>");
    // strip out the ® from the plain name
    plainName = plainName.replace("®", "");
  }

  return { plain: plainName, html: productName };
};

module.exports = NameMap;
