import { BillingType } from "../constants/plan";
import intl from "../services/intl";
import { getStore } from "../store/createStore";
import planSelectors from "../store/plan/selectors";
import productSelectors from "../store/product/selectors";
import staticDataSelectors from "../store/staticData/selectors";
import NameMap from "./nameMap";

export function getPlanForId(planId) {
  const state = getStore().getState();
  return planSelectors.planForId(state, { id: planId });
}

export function getProductForSku(sku) {
  const state = getStore().getState();
  return productSelectors.productForSku(state, { sku });
}

export function getProductForContentfulId(contentfulId) {
  const state = getStore().getState();
  return productSelectors.productForContentfulId(state, { contentfulId });
}

export function getProductSkuForPlanId(planId) {
  const state = getStore().getState();
  const product = planSelectors.productForPlanId(state, { id: planId });
  return product ? product.sku : "";
}

export function getProductIdForSku(sku) {
  const product = getProductForSku(sku);
  return product ? product.id : "";
}

export function getPlanForProductSku(sku, type = BillingType.Recurring) {
  const state = getStore().getState();
  const plan = planSelectors.planForProductSku(state, {
    sku,
    type,
  });
  return plan || null;
}

export function getAllPlansForProductSku(sku) {
  const state = getStore().getState();
  const plans = planSelectors.allPlansForProductSku(state, { sku });
  return plans || [];
}

export function getPlanIdForProductSku(sku, type = BillingType.Recurring) {
  const plan = getPlanForProductSku(sku, type);
  return plan ? plan.id : null;
}

export function getContentfulProductForPlan(planId) {
  const state = getStore().getState();
  const sku = getProductSkuForPlanId(planId);
  return staticDataSelectors
    .contentfulProducts(state)
    .find((product) => product.sku === sku);
}

export function getProductAttributes(planId, quantity = 1, skuParam) {
  const state = getStore().getState();

  const productSku = skuParam || getProductSkuForPlanId(planId);
  const product = getProductForSku(productSku);
  const plan = planSelectors.planForId(state, { id: planId }) || {};

  const contentfulProduct = staticDataSelectors
    .contentfulProducts(state)
    .find((product) => product.sku === productSku);

  if (!contentfulProduct) return null;

  let { sku, name, slug, socialImage } = contentfulProduct;
  let productAttributes = {
    id: product.id,
    category: product.subCategory,
    variant: planId,
    variant_contents: plan.variantContents,
    variant_description: plan.variantDescription,
    sku: sku,
    name: NameMap(name).plain,
    currency: (plan.currency || "").toUpperCase(),
    price: plan.amount / 100,
    quantity,
    slug: slug,
    urlSlug: product.urlSlug,
    url: `${process.env.GATSBY_URL}${intl.localizePath(`/products/${slug}`)}`,
    image_url: `https:${socialImage.file.url}`,
    billing_type: plan.billingType,
  };
  return productAttributes;
}

export function getProductAttributesForSku(
  productSku,
  type = BillingType.Recurring,
) {
  const planId = getPlanIdForProductSku(productSku, type);
  return getProductAttributes(planId);
}
