import { RenderNode } from "@contentful/rich-text-react-renderer";
import { INLINES, MARKS } from "@contentful/rich-text-types";
import { graphql, useStaticQuery } from "gatsby";
import { ImageDataLike, getImage } from "gatsby-plugin-image";
import {
  RenderRichTextData,
  renderRichText,
} from "gatsby-source-contentful/rich-text";
import React, { ComponentType, ReactNode } from "react";
import intlService from "../../services/intl";

export type HelloBarPromotionNode = {
  promotionSlug: string;
  content: RenderRichTextData<any>;
  image: {
    gatsbyImageData: ImageDataLike;
  };
  infoBody: RenderRichTextData<any> | null;
};

export type LinkComponent = ComponentType<{ to: string; children: ReactNode }>;
export type InfoComponent = ComponentType<{ children: ReactNode }>;
export type CountdownComponent = ComponentType<{ expiresAt: string }>;
export type RenderContent = {
  Link?: LinkComponent;
  Info?: InfoComponent;
  Countdown? : CountdownComponent;
};

const DefaultCodeMark = ({ children }: { children: ReactNode }) => (
  <code>{children}</code>
);

const renderLinkNode = (Link?: LinkComponent): RenderNode | undefined =>
  Link && {
    [INLINES.HYPERLINK]: ({ data }, content) => (
      <Link to={data.uri}>{content}</Link>
    ),
  };

const renderCodeMark =
  (codeMarks: {
    [k: string]: (text: ReactNode) => React.JSX.Element | undefined;
  }) =>
  (text: ReactNode): ReactNode => {
    const trimmedText =
      (typeof text === "string" && text?.trim()) || "_default";
    return codeMarks[trimmedText]?.(text) || codeMarks["_default"](text);
  };

const parseNode = (
  node: HelloBarPromotionNode,
  opts: UseHelloBarPromotionOpts = {},
) => {
  const { Link, Info, Countdown } = opts.renderContent || {};
  const expiresAt = opts.expiresAt;
  const infoBody = node.infoBody && renderRichText(node.infoBody);
  const content = renderRichText(node.content, {
    renderNode: {
      ...renderLinkNode(Link),
    },
    renderMark: {
      [MARKS.CODE]: renderCodeMark({
        "<Info>": () => Info && <Info>{infoBody}</Info>,
        "<Countdown>": () => Countdown && expiresAt ? <Countdown expiresAt={expiresAt} /> : undefined,
        _default: (text) => <DefaultCodeMark>{text}</DefaultCodeMark>,
      }),
    },
  });

  const image = getImage(node.image);

  return { node, content, image };
};

type UseHelloBarPromotionOpts = {
  renderContent?: RenderContent;
  expiresAt?: string;
};
const useHelloBarPromotion = (
  promotionSlug: string,
  opts: UseHelloBarPromotionOpts = {},
) => {
  const { allContentfulHelloBarPromotion } = useStaticQuery(graphql`
    query HelloBarPromotion {
      allContentfulHelloBarPromotion {
        nodes {
          node_locale
          promotionSlug
          content {
            raw
          }
          image {
            gatsbyImageData(placeholder: NONE)
          }
          infoBody {
            raw
          }
        }
      }
    }
  `);
  const localNodes = intlService.cf(
    allContentfulHelloBarPromotion.nodes,
  ) as HelloBarPromotionNode[];
  const node = localNodes.find((n) => n.promotionSlug === promotionSlug);

  return node ? parseNode(node, opts) : null;
};

export default useHelloBarPromotion;
