// Utils
import metrics from "../metrics";
import { getProductForSku } from "../planToProduct";

// Services
import intl from "../../services/intl";

export function trackProductPromotionViewed(sku) {
  metrics.track("Promotion Viewed", getProductPromotionAttributes(sku));
}

// TODO: Currently the promotion data isn't available when the promotion is
// viewed, it is only available when the promotion is added to cart. This should
// be refactored to be more dynamic once promotion data is queried on page load.
export function getProductPromotionAttributes(sku) {
  if (sku !== "PROSHAKER") {
    console.error(
      `Cannot get tracking attributes for product promotion ${sku}`,
    );
    return {};
  }

  const product = getProductForSku(sku);
  if (!product) return {};

  const env = process.env.GATSBY_ACTIVE_ENV || "production";
  const promotionId = PromotionIds[sku][env];

  return {
    promotion_id: promotionId,
    name: "Protein Shaker Promotion",
    description: intl.t(`product.promotions.${sku}.description`),
    product_name: product.name,
    image_url: product.imageUrl,
    sku,
    amount: Number(intl.t(`product.promotions.${sku}.price`)),
    currency: intl.currency.type,
    discounted_price: "Free",
    type: "ProductPromotion",
  };
}

const PromotionIds = {
  PROSHAKER: {
    development: "64612603-c00a-4971-bb36-ac4b092d5944",
    staging: "ee7ec31d-9d3d-450c-8e66-ca1062c4bb17",
    production: "534b364c-2f72-4655-a87d-f8852dec7352",
  },
};
