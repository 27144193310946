import { IsStorageSupported } from "./helpers";

export function appendParamFromStorage(url, key, param) {
  if (!IsStorageSupported) return url;

  const value = window.sessionStorage.getItem(key);
  if (value === null || typeof value === "undefined") return url;

  return appendParamToUrl(url, param, value);
}

export function getCheckoutUrlWithoutPlan() {
  let checkoutUrl = process.env.GATSBY_CHECKOUT_URL || "//checkout.ritual.com";
  return appendParamFromStorage(checkoutUrl, "gift_card_for_checkout", "gc");
}

export function appendCheckoutParams(checkoutUrl, promoCode) {
  if (promoCode) {
    checkoutUrl = appendParamToUrl(checkoutUrl, "promo", promoCode);
  } else {
    checkoutUrl = appendParamFromStorage(
      checkoutUrl,
      "promo_for_checkout",
      "promo",
    );
  }

  checkoutUrl = appendParamFromStorage(
    checkoutUrl,
    "gift_card_for_checkout",
    "gc",
  );

  return checkoutUrl;
}

function appendParamToUrl(url, key, value) {
  const appendSymbol = url.includes("?") ? "&" : "?";
  return `${url}${appendSymbol}${key}=${value}`;
}
