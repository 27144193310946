import { combineReducers } from "redux";
import normalizedReducers from "../utils/normalized/reducers";
import { ModelType } from "./model";

export const UPDATE_BEST_ELIGIBLE_PROMOTION_ID = "UPDATE_BEST_ELIGIBLE_PROMOTION_ID";

function meta(state = { bestEligiblePromotionId: undefined }, action) {
  state = normalizedReducers.metaReducer(ModelType, state, action);

  switch (action.type) {
    case UPDATE_BEST_ELIGIBLE_PROMOTION_ID:
      return {
        ...state,
        bestEligiblePromotionId: action.payload.bestEligiblePromotionId,
      };
    default:
      return state;
  }
}

function byId(state = {}, action) {
  return normalizedReducers.byIdReducer(ModelType, state, action);
}

function allIds(state = [], action) {
  return normalizedReducers.allIdsReducer(ModelType, state, action);
}

export default combineReducers({ meta, byId, allIds });
