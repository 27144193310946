import { Component, createRef } from "react";
import styled from "styled-components";

import FocusTrap from "focus-trap-react";

// Redux
import { connect } from "react-redux";
import { closeNavPanel, openNavPanel } from "../../store/navigation/actions";
import navigationSelectors from "../../store/navigation/selectors";
import userSelectors from "../../store/user/selectors";

// Components
import SkipLink from "./navigation/SkipLink";
import TopNav from "./navigation/TopNav";

// Utils
import keyCodes from "../../utils/keyCode";
import { lockScroll } from "../../utils/lockScroll";
import metrics from "../../utils/metrics";

// Services
import { variation } from "../../services/launchDarkly";
import { detectIOS } from "../../utils/user-agent";
import NavigationMenu from "./NavigationMenu";

// Styled Elements
const NavigationContainer = styled.header``;

export class NavigationComponent extends Component {
  constructor(props) {
    super(props);

    this.toggleNavigation = this.toggleNavigation.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNavClick = this.handleNavClick.bind(this);

    this.handleEscDown = this.handleEscDown.bind(this);

    this.openNavPanel = this.openNavPanel.bind(this);
    this.closeNavPanel = this.closeNavPanel.bind(this);

    this.trapOptions = {
      allowOutsideClick: true,
      tabbableOptions: {
        getShadowRoot: true,
      },
    };
    this.navRef = createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEscDown);

    const navigationFlag = variation("navigation", {
      disabled: true,
    });

    this.setState({
      newNavigation: !!navigationFlag?.panelId,
    });
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const isIOS = detectIOS();

    if (prevProps.isOpen !== isOpen && !isIOS) {
      lockScroll(isOpen, this.navRef.current);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscDown);
  }

  handleEscDown(e) {
    if (e.keyCode === keyCodes.ESC && this.props.isOpen) {
      this.toggleNavigation();
    }
  }

  openNavPanel(depth, key) {
    this.props.dispatchOpenPanel(depth, key);
  }

  closeNavPanel(depth) {
    this.props.dispatchClosePanel(depth);
  }

  toggleNavigation() {
    const navOpen = this.props.isOpen;

    if (navOpen) {
      this.closeNavPanel(0);
    } else {
      this.openNavPanel(0, "top");
    }
  }

  handleClose(depth = 0, triggerEvent = true) {
    this.props.dispatchClosePanel(depth, triggerEvent);
  }

  handleNavClick(e, location) {
    const title = e.currentTarget.innerText;
    const ariaLabel = e.currentTarget.getAttribute("aria-label");
    let href = e.currentTarget.getAttribute("href");

    const homeUrl = process.env.GATSBY_URL;

    if (href.startsWith("/")) {
      href = `${homeUrl}${href}`;
    }

    metrics.track("Nav Item Clicked", {
      title: ariaLabel || title,
      url: href,
      location: location
        ? location
        : this.props.isOpen
        ? "Open Nav"
        : "Closed Nav",
    });

    this.props.dispatchClosePanel(0);
  }

  render() {
    const { isOpen, panels } = this.props;

    return (
      <NavigationContainer id="navigation">
        <SkipLink />
        <FocusTrap active={false} focusTrapOptions={this.trapOptions}>
          <div ref={this.navRef}>
            <TopNav
              isOpen={isOpen}
              panels={panels}
              pathname={this.props.pathname}
              toggleNavigation={this.toggleNavigation}
              handleNavClick={this.handleNavClick}
              closeNavPanel={this.closeNavPanel}
            >
              {/* Banner Elements */}
              {this.props.children}
            </TopNav>
            <FocusTrap active={false} focusTrapOptions={this.trapOptions}>
              <div ref={this.navRef}>
                <NavigationMenu open={isOpen} handleClose={this.handleClose} />
              </div>
            </FocusTrap>
          </div>
        </FocusTrap>
      </NavigationContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  navigation: state.navigation,
  isOpen: navigationSelectors.isOpen(state),
  panels: navigationSelectors.panels(state),
  isLoggedIn: userSelectors.isLoggedIn(state),
  ...ownProps,
});

export default connect(mapStateToProps, {
  dispatchOpenPanel: openNavPanel,
  dispatchClosePanel: closeNavPanel,
})(NavigationComponent);
