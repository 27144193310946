import styled from "styled-components";
import { Font, rem, responsive } from "../../utils/style";
import MessageBanner from "../MessageBanner";
import Text from "../Text";
import { useSelector } from "react-redux";
import userSelectors from "../../store/user/selectors";

const BannerContent = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 12px;

  ${responsive.md`
    padding-top: 12px;
    padding-bottom: 14px;
  `}

  p {
    ${Font.circular}
    font-size: ${rem(16)};
    letter-spacing: 0;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: 28px;
    `}
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }
`;

export default function CustomBanner(props) {
  const { content } = props;
  const authenticated = useSelector(userSelectors.isLoggedIn);

  if (authenticated && content?.prospects) return null;

  return (
    <MessageBanner {...props}>
      <BannerContent>
        <p className="d-md-flex align-items-center justify-content-center text-center">
          {content?.copy}
        </p>
      </BannerContent>
    </MessageBanner>
  );
}
