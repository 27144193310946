export function setLocale(locale) {
  return {
    type: "SET_LOCALE",
    payload: {
      locale,
    },
  };
}

export function setActiveLocales(activeLocales) {
  return {
    type: "SET_ACTIVE_LOCALES",
    payload: {
      activeLocales,
    },
  };
}

export function setStores(stores) {
  return {
    type: "SET_STORES",
    payload: {
      stores,
    },
  };
}

export function setLocaleSuggestion(localeSuggestion) {
  return {
    type: "SET_LOCALE_SUGGESTION",
    payload: {
      localeSuggestion,
    },
  };
}
