import * as Castle from "@castleio/castle-js";
import * as currentUser from "../utils/currentUser";

class CastleService {
  initialize() {
    const castlePublishableKey = process.env.GATSBY_CASTLE_PUBLISHABLE_KEY;
    if (!castlePublishableKey) return;

    this.castle = Castle.configure({
      pk: castlePublishableKey,
    });
  }

  createRequestToken() {
    if (!this.castle) return null;
    return this.castle.createRequestToken();
  }

  reset() {
    this.castle = null;
  }

  async trackPageView() {
    if (!this.castle) return null;

    const user = await currentUser.getCurrentUser();

    if (user) {
      this.castle.page({
        userJwt: user.castleJwt,
      });
    }
  }
}

const castleService = new CastleService();
export default castleService;
