import { graphql, useStaticQuery } from "gatsby";
import { useEffect } from "react";
import { isEmpty } from "lodash";

import GiftBanner from "./GiftBanner";
import IdMeConfirmationBanner from "./IdMeConfirmationBanner";
import PodcastBanner from "./PodcastBanner";
import ReferralBanner from "./ReferralBanner";
import WelcomeBanner from "./WelcomeBanner";
import CustomBanner from "./CustomBanner";
import HelloBar, { renderContent } from "./HelloBar";
import MessageBanner from "../MessageBanner";

import { BannerType } from "../../utils/bannerType";
import { createSessionQueryParams } from "../../utils/location";

// Services
import intl from "../../services/intl";

// Redux
import { useDispatch, useSelector } from "react-redux";
import appSelectors from "../../store/app/selectors";
import { setGiftCardCode, setUtmCampaign } from "../../store/banners/actions";

// Hooks
import useHasMounted from "../../hooks/useHasMounted";
import useVariation from "../../hooks/useVariation";
import useHelloBarPromotion from "../../hooks/contentful/useHelloBarPromotion";
import { bestEligiblePromotion } from "../../store/promotion/selectors";
import BFCMSubscriberBanner from "./BFCMSubscriberBanner";
import BFCMProspectBanner from "./BFCMProspectBanner";

export const PersistedQueryKey = "banner-params";

export default function Banners(props) {
  const dispatch = useDispatch();
  const hasMounted = useHasMounted();

  const { allContentfulBanner } = useStaticQuery(graphql`
    query BannersComponentQuery {
      allContentfulBanner {
        nodes {
          node_locale
          content {
            content
          }
          vanityUrl
          utmContent
          utmSource
          utmMedium
          utmCampaign
          promoCode
        }
      }
    }
  `);

  const referralVisibility = useVariation("referral-home-visibility");
  let {
    bannerType: bannerTypeOverride,
    utmCampaign,
    utmContent,
    giftCardCode,
  } = useSelector((state) => state.banners);

  const isUserDataLoaded = useSelector(appSelectors.isUserDataLoaded);

  const homepageHelloBar = useVariation("homepage-hello-bar");

  const promotion = useSelector(bestEligiblePromotion);


  const helloBarPromotionSlug =
    (homepageHelloBar?.type === "promotion" && homepageHelloBar.slug) ||
    promotion?.slug;

  const expiresAt = promotion?.expiresAt;
  const helloBarPromotion = useHelloBarPromotion(helloBarPromotionSlug, {
    renderContent,
    expiresAt,
  });

  const params = createSessionQueryParams(
    ["utm_content", "utm_campaign", "utm_source", "utm_medium", "gc"],
    PersistedQueryKey,
  );

  const podcastBanner = getPodcastBanner(
    utmCampaign || params.utmCampaign,
    utmContent || params.utmContent,
  );

  useEffect(() => {
    const { utmCampaign, utmContent, gc: giftCardCode } = params;
    if (utmCampaign) dispatch(setUtmCampaign(utmCampaign, utmContent));
    if (giftCardCode) dispatch(setGiftCardCode(giftCardCode));
  }, [params.utmCampaign, params.utmContent, params.gc]);

  function getBannerType() {
    if (!hasMounted) return null;

    if (bannerTypeOverride) return bannerTypeOverride;

    // Prioritize podcast banners over all other banner types. If a podcast
    // banner is found, ignore other banner types.
    if (podcastBanner) return BannerType.PODCAST;

    if (params.gc) return BannerType.GIFT;
    if (params.utmCampaign === "pp") return BannerType.WELCOME;

    // Wait launchdarkly and API data to load before making any further decisions.
    // LD data can load first, causing Banners to temporarily render an incorrect
    // banner type.
    if (!homepageHelloBar || !isUserDataLoaded) return null;

    if (promotion?.slug === "bfcm23-prospect-promotion")
      return BannerType.BFCM_PROSPECT;

    if (promotion?.slug === "bfcm23-subscriber-promotion")
      return BannerType.BFCM_SUBSCRIBER;

    if (helloBarPromotion) return BannerType.PROMOTION;

    const homepageHelloBarEnabled = !isEmpty(homepageHelloBar);
    if (homepageHelloBarEnabled) return BannerType.CUSTOM;

    if (referralVisibility?.components?.includes("sitewide-banner")) {
      return BannerType.REFERRAL;
    }
  }

  function getPodcastBanner(campaign, content) {
    if (!campaign) return;

    const contentfulBanners = intl.cf(allContentfulBanner.nodes);

    return contentfulBanners.find((banner) => {
      const campaignMatch =
        banner.utmCampaign.toLowerCase() === campaign.toLowerCase();

      // If the utm_campaign does not match, return false
      if (!campaignMatch) return false;

      // If there's no utm_content, return the first banner that matches the
      // utm_campaign.
      if (!content) return true;

      // Return the first banner that matches the campaign and content.
      return (
        banner.utmContent &&
        banner.utmContent.toLowerCase() === content.toLowerCase()
      );
    });
  }

  function closeBannerAction() {
    window.sessionStorage.removeItem(PersistedQueryKey);
  }

  function bannerProps() {
    return {
      ...props,
      closeAction: closeBannerAction,
    };
  }

  const bannerType = getBannerType();

  switch (bannerType) {
    case BannerType.PODCAST:
      return <PodcastBanner {...bannerProps} banner={podcastBanner} />;
    case BannerType.GIFT:
      return <GiftBanner {...bannerProps} code={giftCardCode} />;
    case BannerType.WELCOME:
      return <WelcomeBanner {...bannerProps} />;
    case BannerType.IDME:
      return <IdMeConfirmationBanner {...bannerProps} />;
    case BannerType.REFERRAL:
      return <ReferralBanner />;
    case BannerType.PROMOTION:
      return (
        <MessageBanner
          {...bannerProps}
          renderTopBar={({ closeTopBar }) => (
            <HelloBar
              content={helloBarPromotion.content}
              image={helloBarPromotion.image}
              onCloseClick={() => closeTopBar()}
            />
          )}
        />
      );
    case BannerType.CUSTOM:
      return <CustomBanner {...bannerProps} content={homepageHelloBar} />;
    case BannerType.BFCM_SUBSCRIBER:
      return <BFCMSubscriberBanner promotion={promotion} />;
    case BannerType.BFCM_PROSPECT:
      return <BFCMProspectBanner promotion={promotion} />;
    default:
      return null;
  }
}
