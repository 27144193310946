// Reducers
function metaReducer(modelType, state = {}, action) {
  switch (action.type) {
    case `${modelType}_REQUEST_START`:
      return {
        ...state,
        activeRequest: true,
      };
    case `${modelType}_REQUEST_SUCCESS`:
      return {
        ...state,
        activeRequest: false,
      };
    case `${modelType}_REQUEST_FAILURE`:
      return {
        ...state,
        activeRequest: false,
      };
    default:
      return state;
  }
}

function byIdReducer(modelType, state = {}, action) {
  const { payload } = action;
  switch (action.type) {
    case `CREATE_${modelType}`:
      return _addEntry(state, payload.id, payload.data);
    case `UPDATE_${modelType}`:
      state = _removeEntry(state, payload.oldId);
      return _addEntry(state, payload.id, payload.data);
    case `REMOVE_${modelType}`:
      return _removeEntry(state, payload.id);
    default:
      return state;
  }
}

function allIdsReducer(modelType, state = [], action) {
  const { payload } = action;
  switch (action.type) {
    case `CREATE_${modelType}`:
      return _addId(state, payload.id);
    case `UPDATE_${modelType}`:
      state = _removeId(state, payload.oldId);
      return _addId(state, payload.id);
    case `REMOVE_${modelType}`:
      return _removeId(state, payload.id);
    default:
      return state;
  }
}

// Helpers
function _addEntry(state, id, data) {
  return {
    ...state,
    [id]: data,
  };
}

function _removeEntry(state, id) {
  const updatedState = { ...state };
  delete updatedState[id];
  return {
    ...updatedState,
  };
}

function _addId(state, id) {
  const index = state.indexOf(id);
  if (index === -1) {
    return state.concat(id);
  }
  return state;
}

function _removeId(state, id) {
  const index = state.indexOf(id);
  if (index >= 0) {
    const updatedState = [...state];
    updatedState.splice(index, 1);
    return updatedState;
  }
  return state;
}

export default {
  metaReducer,
  byIdReducer,
  allIdsReducer,
};
