import {
  findModels,
  findModelByAssociations,
  findModelsByAssociations,
} from "./selectors";

// Add to Store Actions
import { addNormalizedCart } from "../../cart/actions";
import { addNormalizedCartProduct } from "../../cart-product/actions";
import { addCouponFromNormalizedData } from "../../coupon/actions";
import { addNormalizedGuest } from "../../guest/actions";
import { addPlanFromNormalizedData } from "../../plan/actions";
import { addProductFromNormalizedData } from "../../product/actions";
import { addPromotionFromNormalizedData } from "../../promotion/actions";
import { addShippingOptionFromNormalizedData } from "../../shipping-option/actions";
import { addSubscriptionFromNormalizedData } from "../../subscription/actions";
import { addSubscriptionItemFromNormalizedData } from "../../subscription-item/actions";
import { addInvoiceItemFromNormalizedData } from "../../invoice-item/actions";
import { addNormalizedUser } from "../../user/actions";

function requestStart(dispatch, modelType) {
  dispatch({
    type: `${modelType}_REQUEST_START`,
  });
}

function requestSuccess(dispatch, modelType) {
  dispatch({
    type: `${modelType}_REQUEST_SUCCESS`,
  });
}

function requestFailure(dispatch, modelType) {
  dispatch({
    type: `${modelType}_REQUEST_FAILURE`,
  });
}

function addDataToStore(dispatch, normalizedData, associations) {
  Object.keys(normalizedData).forEach((modelType) => {
    const action = _getAddToStoreActionForModelType(modelType);

    // If there's no corresponding action for the adding the specified model
    // type to the store, the type should not be added.
    if (!action) return;

    const modelData = normalizedData[modelType];
    const associationsForType = associations && associations[modelType];

    Object.keys(modelData).forEach((id) => {
      const modelDataForId = modelData[id];
      dispatch(action(modelDataForId, associationsForType));
    });
  });
}

function updateOrCreateModel(dispatch, store, modelType, model, associations) {
  // If no associations are specified, or if the incoming model does not match
  // the current associations, fall back to updating the model by id.
  if (!associations || !model.matchesAssociations(associations)) {
    associations = { id: model.id };
  }

  const existingModel = findModelByAssociations(store, associations);

  if (!existingModel) {
    dispatch({
      type: `CREATE_${modelType}`,
      payload: {
        id: model.id,
        data: model,
      },
    });
    return;
  }

  model.key = existingModel.key;
  const oldId = existingModel.id;

  dispatch({
    type: `UPDATE_${modelType}`,
    payload: {
      id: model.id,
      oldId,
      data: model,
    },
  });
}

function removeModel(dispatch, modelType, id) {
  dispatch({
    type: `REMOVE_${modelType}`,
    payload: { id },
  });
}

function removeModels(dispatch, store, modelType, associations) {
  let existingModels;
  if (associations) {
    existingModels = findModelsByAssociations(store, associations);
  } else {
    existingModels = findModels(store);
  }

  existingModels.forEach((model) => {
    removeModel(dispatch, modelType, model.id);
  });
}

function _getAddToStoreActionForModelType(type) {
  switch (type) {
    case "carts":
      return addNormalizedCart;
    case "coupons":
      return addCouponFromNormalizedData;
    case "cart_products":
      return addNormalizedCartProduct;
    case "guests":
      return addNormalizedGuest;
    case "products":
      return addProductFromNormalizedData;
    case "promotion":
      return addPromotionFromNormalizedData;
    case "plans":
      return addPlanFromNormalizedData;
    case "shipping_options":
      return addShippingOptionFromNormalizedData;
    case "subscriptions":
      return addSubscriptionFromNormalizedData;
    case "subscription_items":
      return addSubscriptionItemFromNormalizedData;
    case "invoice_items":
      return addInvoiceItemFromNormalizedData;
    case "user":
      return addNormalizedUser;
    default:
      return null;
  }
}

export default {
  requestStart,
  requestSuccess,
  requestFailure,
  addDataToStore,
  updateOrCreateModel,
  removeModel,
  removeModels,
};
