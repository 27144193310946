// Redux
import { getStore } from "../store/createStore";
import i18nSelectors from "../store/i18n/selectors";
import { setLocaleSuggestion } from "../store/i18n/actions";

// Helpers
import flatten from "flat";
import { createIntl, createIntlCache } from "react-intl";
import { domainRegex } from "../../config/domain";
import locales from "../../config/locales";
import { setCookie } from "../utils/cookies";
import { suggestLocale } from "../utils/suggestLocale";

// Services
import { variation } from "./launchDarkly";

// This is optional but highly recommended since it prevents memory leak.
const cache = createIntlCache();

const CookieKey = `ritual_active-locale`;

class IntlService {
  constructor() {
    this.formatter = null;
    this.translationData = {};
  }

  get locale() {
    return i18nSelectors.locale(getStore().getState());
  }

  get country() {
    return i18nSelectors.country(getStore().getState());
  }

  get siteLang() {
    return i18nSelectors.siteLang(getStore().getState());
  }

  get ogLocale() {
    return i18nSelectors.ogLocale(getStore().getState());
  }

  get currency() {
    return i18nSelectors.currency(getStore().getState());
  }

  get path() {
    return i18nSelectors.path(getStore().getState());
  }

  get payments() {
    return i18nSelectors.payments(getStore().getState());
  }

  get activeLocales() {
    return i18nSelectors
      .activeLocales(getStore().getState())
      .map((locale) => locales[locale]);
  }

  get multipleLocalesEnabled() {
    return this.activeLocales.length > 1;
  }

  get canToggleLocales() {
    /**
     * If the user lands in the non-default locale via SSR,
     * bypass by allowing them to toggle so they can get
     * back to the default.
     */
    if (this.locale !== this.defaultLocale.locale) return true;

    return this.multipleLocalesEnabled;
  }

  get defaultLocale() {
    return locales[
      Object.keys(locales).find((locale) => locales[locale].default)
    ];
  }

  addTranslationData(locale, data) {
    this.translationData[locale] = flatten(data, {
      safe: true,
    });
  }

  updateFormatter(locale) {
    if (!this.translationData[locale])
      throw new Error(`Missing translation data for locale "${locale}"!`);

    this.formatter = createIntl(
      {
        locale,
        defaultLocale: this.defaultLocale.locale,
        messages: this.translationData[locale],
        onError: (error) => {
          console.error(error.message);
        },
      },
      cache,
    );
  }

  async detectLocale() {
    const result = await suggestLocale();
    const store = getStore();
    store.dispatch(setLocaleSuggestion(result));
  }

  persistLocale(locale) {
    setCookie(CookieKey, locale, {
      domain: process.env.GATSBY_COOKIE_DOMAIN,
    });
  }

  // The cf (Contentful Filter) function. This function takes an array of
  // Contentful nodes and filters them so that node's 'node_locale' matches the
  // current Redux locale.
  cf(nodes, singleNode = false) {
    // Allow nodes to be undefined, for testing.
    if (!nodes) return;

    const locale = i18nSelectors.locale(getStore().getState());
    if (!Array.isArray(nodes)) {
      throw new Error(`intl.cf expected an array of nodes`);
    }

    const filteredNodes = nodes.filter((node) => {
      if (!node.node_locale) {
        throw new Error(`intl.cf node is missing a node_locale`);
      }
      return node.node_locale === locale;
    });

    // If we're expecting a single node (e.g. filtering for a single localized
    // asset), return the first element in the filtered array.
    if (singleNode) {
      if (filteredNodes.length === 1) {
        return filteredNodes[0];
      }
      throw new Error(`intl.cf expected to return a single node`);
    }

    // Otherwise return all the filtered nodes.
    return filteredNodes;
  }

  t(id, defaultMessage, values) {
    this.validateReady();
    return this.formatter.formatMessage({ id, defaultMessage }, values);
  }

  formatCurrency(number, options = {}) {
    this.validateReady();
    const postfix = options.postfix ? this.currency.postfix : "";
    const negativeSymbol = Math.sign(number) < 0 ? "-" : "";

    // See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
    const formattedNumber = this.formatter.formatNumber(Math.abs(number), {
      style: "decimal",
      minimumFractionDigits: options.round && number % 1 === 0 ? 0 : 2,
      ...options,
    });

    return (
      negativeSymbol +
      (this.currency.symbol || "$") +
      formattedNumber +
      (postfix || "")
    );
  }

  formatDateTime(date, options = {}) {
    this.validateReady();
    const dateObject = typeof date === "string" ? new Date(date) : date;

    return new Intl.DateTimeFormat([this.locale, this.defaultLocale.locale], {
      ...options,
    }).format(dateObject);
  }

  formatNumber(
    value,
    { minimumFractionDigits = 0, style = "decimal", ...options },
  ) {
    this.validateReady();
    return new Intl.NumberFormat([this.locale, this.defaultLocale.locale], {
      ...options,
      style,
      minimumFractionDigits,
    }).format(style === "percent" ? value / 100 : value);
  }

  // Returns the raw value from the translations file for the active locale
  // where we don't necessarily want it automatically formatted
  // and would prefer to handle it independently.
  unformattedTranslation(id) {
    this.validateReady();
    return this.translationData[this.locale][id];
  }

  validateReady() {
    if (!this.formatter)
      throw new Error("Intl formatter called before it is intialized!");
  }

  pathForSelection(key, { pathname = "", hash = "", search = "" } = {}) {
    const config = this.activeLocales.find((l) => key === l.locale);
    const path = this.sanitizePath(pathname);
    return `${config.path ? `/${config.path}` : ""}/${path + hash + search}`;
  }

  localizePath(input) {
    if (!this.path) return input;
    const match = input.split(domainRegex);
    const domainlessPath = match[1] || match[0];
    return !domainlessPath.startsWith("http") &&
      !domainlessPath.startsWith("mailto:") &&
      !domainlessPath.startsWith("#")
      ? `/${this.path}/${this.sanitizePath(domainlessPath)}`
      : domainlessPath;
  }

  // Removes localization paths and leading slashes
  sanitizePath(input) {
    const path = input.replace(/^\/|\/$/g, "");
    if (!this.path) return path;

    if (path.replace(/\/+$/, "") === this.path) return "";
    return path.startsWith(`${this.path}/`)
      ? path.split(`${this.path}/`)[1]
      : path;
  }
}

const intlService = new IntlService();
export default intlService;
export { IntlService, CookieKey, locales };
