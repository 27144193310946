import { reserve } from "../apply-code/actions";

export function addPendingCode(code) {
  return _updatePendingCode(code);
}

export function clearPendingCode() {
  // If we don't pass a `code` parameter, the code is cleared.
  return _updatePendingCode();
}

export function applyPendingCode() {
  return (dispatch, getState) => {
    const state = getState();
    const code = state.pendingCode.code;
    if (!code) return;

    return dispatch(reserve(code)).catch(() => {
      // If reserving the pending code fails, clear the pending code.
      dispatch(clearPendingCode());
    });
  };
}

function _updatePendingCode(code = "") {
  code = code.toUpperCase();

  return {
    type: "UPDATE_PENDING_CODE",
    payload: {
      code,
    },
  };
}
