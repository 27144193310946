import styled, { css } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";

export const FadeStyles = css`
  .magic-fade-enter {
    opacity: 0.01;
  }

  .magic-fade-enter.magic-fade-enter-active {
    opacity: 1;
    transition: opacity ${(p) => p.timeout || 300}ms ease-in;
  }

  .magic-fade-exit {
    opacity: 1;
  }

  .magic-fade-exit.magic-fade-exit-active {
    opacity: 0.01;
    transition: opacity ${(p) => p.timeout || 300}ms ease-in;
  }
`;

const MagicFade = ({ timeout = 300, transitionKey, children }) => {
  return (
    <SwitchTransition>
      <CSSTransition
        key={transitionKey}
        classNames="magic-fade"
        timeout={timeout}
      >
        {children}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default MagicFade;
