import throttle from "lodash/throttle";
import { useMemo, useState } from "react";
import { ObservedSize, ResizeHandler } from "use-resize-observer";
import useResizeObserver from "./useResizeObserver";

type UseResizeObserverOpts = Parameters<typeof useResizeObserver>[0];
type Opts = { wait?: number } & UseResizeObserverOpts;

const useThrottledResizeObserver = ({
  wait = 50,
  onResize,
  ...opts
}: Opts = {}) => {
  const [size, setSize] = useState<ObservedSize>({ width: 0, height: 0 });
  const handleResize: ResizeHandler = (size) => {
    setSize(size);
    onResize?.(size);
  };
  const memoizedHandleResize = useMemo(
    () => throttle(handleResize, wait),
    [wait, onResize],
  );
  const { ref } = useResizeObserver({
    ...opts,
    onResize: memoizedHandleResize,
  });

  return { ref, ...size };
};

export default useThrottledResizeObserver;
