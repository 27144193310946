import styled from "styled-components";
import { rem, responsive } from "../../utils/style";

// Constants
import { ActionType } from "../../constants/product";

// Store
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";

// Utils
import {
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import metrics from "../../utils/metrics";
import { getPromotionDetails } from "../../utils/promotion";

// Hooks
import useDiscount from "../../hooks/discount/useDiscount";

// Components
import QuickAddCardWide from "../QuickAddCardWide";
import ScrollableOverflow from "../ScrollableOverflow";
import Text from "../Text";
import PulsePill from "../../components/bundle/PulsePill";
import cartProductSelectors from "../../store/cart-product/selectors";

const UpsellContainer = styled.div`
  margin-top: auto;
  justify-self: flex-end;
`;

const UpsellHeader = styled.div`
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin-bottom: 8px;
`;

const QuickAddCardWrapper = styled.div`
  margin: 0 8px 0 0;
  flex: 1 0 calc(33.333% - (32px / 3));

  &:last-of-type {
    margin-right: 0;
  }
`;

const UpsellContentWrapper = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: 8px;

  ${responsive.md`
    margin-bottom: 16px;
  `}
`;

const UpsellContent = styled.div`
  width: 100%;
  display: flex;
`;

const PulseIconWrapper = styled.div`
  display: flex;
`;

export default function FlyoutCartUpsell({
  isProcessing,
  upsellSkus,
  cartQuantity,
  contentfulProducts,
}) {
  let inStockSkus = getInStockSkus(upsellSkus);

  if (!inStockSkus || !inStockSkus.length) return null;

  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);

  function contentfulProductStockStatus(sku) {
    const product = contentfulProducts.filter((product) => product.sku === sku);
    if (product.length > 0) return product[0].stockStatus;

    return null;
  }

  function getInStockSkus(skus) {
    const skuList = skus;

    let inStockSkus = skuList.reduce((result, sku) => {
      const productStockStatus = contentfulProductStockStatus(sku);
      if (productStockStatus === "in stock") {
        result.push(sku);
      }
      return result;
    }, []);

    return inStockSkus;
  }

  const hasProductOfferInCart = useSelector(
    cartProductSelectors.activeCartProducts,
  ).some((cartProduct) => cartProduct.productOfferId);

  const promotion = useDiscount(ActionType.UpsellAdd, 1);

  return (
    <UpsellContainer>
      <UpsellHeader>
        {!promotion || appliedPromotion || hasProductOfferInCart ? (
          <Text
            id="cart.flyout.upsell-heading-1"
            defaultMessage="You Might Also Like"
          />
        ) : (
          <PulseIconWrapper>
            <Text
              id="cart.flyout.upsell-heading-2"
              defaultMessage="Add an Item & Save"
            />
            <PulsePill className="small">
              <span>{getPromotionDetails(promotion).titleCopy}</span>
            </PulsePill>
          </PulseIconWrapper>
        )}
      </UpsellHeader>
      <UpsellContentWrapper className="d-flex">
        <ScrollableOverflow centered={false} bleedLeft={true} bleedRight={true}>
          <UpsellContent>
            {inStockSkus.map((sku) => (
              <UpsellCard
                key={sku}
                sku={sku}
                isProcessing={isProcessing}
                hasProductOfferInCart={hasProductOfferInCart}
              />
            ))}
          </UpsellContent>
        </ScrollableOverflow>
      </UpsellContentWrapper>
    </UpsellContainer>
  );
}

function UpsellCard({ sku, isProcessing, hasProductOfferInCart }) {
  const planId = getPlanIdForProductSku(sku);
  if (!planId) return null;

  const attributes = getProductAttributes(planId);
  const productName = attributes.name;

  function handleCTAClick() {
    metrics.track("CTA Clicked", {
      location: "Cart Flyout Upsell",
      title: productName,
    });
  }

  let promotion = useDiscount(
    ActionType.UpsellAdd,
    1,
    { sku },
    { considerBundling: false },
  );

  if (hasProductOfferInCart) {
    promotion = null;
  }

  return (
    <QuickAddCardWrapper key={planId}>
      <QuickAddCardWide
        planId={planId}
        disabled={isProcessing}
        onClick={handleCTAClick}
        promotion={promotion}
        blueButton={false}
        isLink={false}
        location="Flyout Cart Upsell"
      />
    </QuickAddCardWrapper>
  );
}
