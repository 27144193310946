import { getStore } from "./createStore";
import isEqual from "lodash/isEqual";

export default function observeStore(selector, onChange) {
  let currentState;

  function handleChange() {
    let nextState = selector(getStore().getState());
    if (!isEqual(nextState, currentState)) {
      currentState = nextState;
      onChange(currentState);
    }
  }

  let unsubscribe = getStore().subscribe(handleChange);
  handleChange();
  return unsubscribe;
}
