// Hooks
import useHasMounted from "../useHasMounted";
import useBundlePromotion from "./useBundlePromotion";
import useProductSpecificPromotion from "./useProductSpecificPromotion";
import useSiteWidePromotion from "./useSiteWidePromotion";
import { isAuthenticated } from "../../utils/authentication";

const useDiscount = (actionType, quantity, product, options = {}) => {
  const hasMounted = useHasMounted();
  const authenticated = isAuthenticated();

  const { considerBundling = true } = options;

  const bundlePromotion = useBundlePromotion(actionType, quantity);

  let siteWidePromotion = useSiteWidePromotion(actionType);

  const productSpecificPromotion = useProductSpecificPromotion(
    actionType,
    product,
  );

  const promotions = [
    productSpecificPromotion,
    siteWidePromotion,
    considerBundling && bundlePromotion,
  ]
    // Temporary fix for homepage-hello-bar A/B test
    .filter((promotion) => !(authenticated && promotion?.name === "25% off"))
    .filter(Boolean);

  if (!hasMounted || !promotions.length) {
    return null;
  }

  // Now that we've determined the eligibility for our different promotions,
  // we should ensure that they're sorted by priority.
  promotions.sort((a, b) => {
    if (a.priority !== b.priority) return a.priority - b.priority;
  });

  return promotions[0];
};

export default useDiscount;
