import Guest, { ModelType } from "./model";

import normalizedActions from "../utils/normalized/actions";
import fetch from "../utils/normalized/fetch";

export function fetchGuest(segmentAnonymousId) {
  let url = "guests/me";
  if (segmentAnonymousId) {
    url += `?segment_anonymous_id=${segmentAnonymousId}`;
  }

  return function (dispatch) {
    return fetch(
      url,
      {
        credentials: "include",
      },
      dispatch,
      ModelType,
    ).catch((error) => {
      console.error(error);
    });
  };
}

export function addNormalizedGuest(data, associations) {
  return function (dispatch) {
    let guest = new Guest();
    guest.deserialize(data, associations);
    dispatch(addGuest(guest));
  };
}

export function addGuest(data, associations) {
  return function (dispatch, getState) {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().guests,
      ModelType,
      data,
      associations,
    );
  };
}
