import { Component } from "react";
import styled from "styled-components";
import { rem } from "../../utils/style";
import MessageBanner from "../MessageBanner";
import { connect } from "react-redux";
import { micromark } from "micromark";

// Actions
import { addPendingCode } from "../../store/pending-code/actions";

export const PodcastMessage = styled.div`
  line-height: 20px;
  font-weight: 300;
  font-size: ${rem(16)};
  padding: 8px 0;

  p {
    margin-bottom: 0;
  }
  a {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
  }
`;

class PodcastBanner extends Component {
  componentDidMount() {
    const { banner, dispatchAddPendingCode } = this.props;

    if (banner && banner.promoCode) {
      dispatchAddPendingCode(banner.promoCode);
    }
  }

  render() {
    const { banner } = this.props;

    if (!banner) return null;

    const html = micromark(banner.content.content);

    return (
      <MessageBanner {...this.props}>
        <PodcastMessage
          // for testing
          id="podcast-message"
          dangerouslySetInnerHTML={{
            __html: html,
          }}
        />
      </MessageBanner>
    );
  }
}

export default connect(null, {
  // Pending Code Actions
  dispatchAddPendingCode: addPendingCode,
})(PodcastBanner);
