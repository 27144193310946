import { useState, useEffect } from "react";
import styled from "styled-components";
import { Icons } from "../../../utils/react-svg";
import { responsive, rem, rgba, Color, Opacity } from "../../../utils/style";

const Column = styled.aside`
  border-bottom: 1px solid ${Color.fadedGrey};
  &:last-of-type {
    border-bottom: none;
  }
  ${responsive.sm`
    &:first-of-type {
      padding-top: 0;
    }
    border-bottom: none;
    width: 25%;
    padding: 0;
    &:last-of-type {
      padding-top: 0;
    }
  `}
`;

export const ColumnHeader = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 24px 0;
  cursor: pointer;
  width: 100%;
  border: none;
  background-color: transparent;

  &.expanded {
    padding-bottom: 0;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const Heading = styled.h3`
  color: ${rgba(Color.ritualBlue, Opacity.light)};
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  font-weight: 500;
  letter-spacing: 0.8px;
  user-select: none;
`;

const Caret = styled.div`
  svg {
    width: 18px;
    height: 11px;
  }
`;

const SiteLinks = styled.ul`
  list-style: none;
  margin: 0;
  padding: 8px 0 24px;
  display: flex;
  flex-direction: column;

  ${responsive.sm`
      padding-bottom: 0;
  `}
  li {
    font-weight: 500;
    letter-spacing: 0px;
    margin-bottom: 0;
    a {
      font-size: ${rem(14)};
      line-height: ${rem(40)};
      ${responsive.md`
        font-size: ${rem(18)};
      `}
    }
  }
`;

const FooterLinkColumn = ({ links, header }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    // Shop should be open by default in xs viewports
    if (header === "Shop") {
      setExpanded(true);
    }
  }, [header]);

  return (
    <>
      {/* stateless columns for 750px + */}
      <Column className="d-none d-sm-block">
        <Heading>{header}</Heading>

        <SiteLinks role="list">{links}</SiteLinks>
      </Column>
      {/* Under "sm". This uses state to determine whether the accordian is open or not */}
      <Column className="d-sm-none d-md-none d-lg-none link-column">
        <ColumnHeader
          onClick={() => setExpanded(!expanded)}
          className={expanded ? "expanded" : ""}
          aria-expanded={expanded}
          aria-controls={`accordion-content-${header}`}
          id={`accordion-control-${header}`}
        >
          <Heading>{header}</Heading>
          <Caret>
            {expanded ? <Icons.CaretUpRounded /> : <Icons.CaretDownRounded />}
          </Caret>
        </ColumnHeader>

        {expanded && (
          <SiteLinks
            role="list"
            id={`accordion-content-${header}`}
            aria-labelledby={`accordion-control-${header}`}
          >
            {links}
          </SiteLinks>
        )}
      </Column>
    </>
  );
};

export default FooterLinkColumn;
