import { createSelector } from "reselect";

const _byId = (state) => state.subscriptionItems.byId;
const _allIds = (state) => state.subscriptionItems.allIds;

const allItems = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

const allItemsForSubscription = createSelector(
  allItems,
  (_, props) => props.id,
  (allItems, id) => allItems.filter((item) => item.subscriptionId === id),
);

const itemQuantityForSubscription = createSelector(
  allItemsForSubscription,
  (allItemsForSubscription) =>
    allItemsForSubscription.reduce((sum, item) => sum + item.quantity, 0),
);

export default {
  allItems,
  allItemsForSubscription,
  itemQuantityForSubscription,
};
