import { createSelector } from "reselect";
import { getStore } from "../createStore";
import observeStore from "../observeStore";

// Selectors
import couponSelectors from "../coupon/selectors";

// Actions
import { clearInput, addAppliedCode } from "../apply-code/actions";

let clearObserver = null;

// Select the coupon that is active on the cart and the current applyCode store.
const selector = createSelector(
  couponSelectors.activeCoupon,
  (state) => state.applyCode,
  (activeCoupon, applyCode) => {
    return {
      activeCoupon,
      applyCode,
    };
  },
);

function start() {
  clearObserver = observeStore(selector, handleChange);
}

function stop() {
  clearObserver && clearObserver();
}

function handleChange(selectedState) {
  const { activeCoupon, applyCode } = selectedState;

  if (activeCoupon) {
    // If an active coupon exists, ensure that the input is updated to reflect
    // the current coupon.
    getStore().dispatch(addAppliedCode(activeCoupon.code));
  } else if (applyCode.isValid) {
    // If no coupon exists and the input is currently populated with a valid
    // code, clear the code since there's no corresponding coupon.
    getStore().dispatch(clearInput());
  }
}

export default {
  start,
  stop,
};
