import { Component } from "react";
import { createPortal } from "react-dom";
import styled, { css, keyframes } from "styled-components";
import * as uuid from "uuid";

// Utils
import { Color, responsive, media, Opacity } from "../utils/style";
import { Icons } from "../utils/react-svg";
import { lockScroll } from "../utils/lockScroll";
import keyCodes from "../utils/keyCode";

// Components
import FocusTrap from "focus-trap-react";

// Styled Elements
const ModalOverlay = styled.div`
  visibility: ${(p) => (p.visible ? "visible" : "hidden")};
  opacity: ${(p) => (p.visible ? "1.0" : "0.0")};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;

  background-color: rgba(0, 0, 0, ${Opacity.light});
  z-index: 9999;
  width: 100vw;
  height: ${(p) => p.windowHeight}px;

  padding: ${(p) => (p.mobileFullPage ? "0" : "45px 20px")};

  ${(p) =>
    p.slideOut &&
    css`
      padding: 0;

      &.open {
        transition: visibility 300ms linear, opacity 300ms ease-out;
      }

      &.closed {
        transition: visibility 0s linear 300ms, opacity 100ms ease-out 200ms;
      }
    `}

  &.vertical-scroll {
    ${media.mobile`
      padding-bottom: 0px
    `}
  }
`;

const ModalContent = styled.div`
  padding: 40px 16px 16px 16px;
  background-color: ${Color.white};
  overflow-y: auto;
  position: relative;
  z-index: 10000;
  max-width: 100%;
  max-height: 100%;
  outline: none;

  ${media.mobile`
    ${(p) =>
      p.mobileFullPage &&
      css`
        width: 100%;
        height: 100%;
      `}
 `}

  ${responsive.sm`
    max-width: 770px;
    padding: 32px 40px;
  `};

  ${responsive.md`
    padding: 40px 80px;
  `};

  ${responsive.lg`
    padding: 40px 100px;
  `};

  ${(p) => p.contentStyle};
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SlideOutModalContent = styled.div`
  background-color: ${Color.white};
  overflow-y: auto;

  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
  z-index: 10000;
  outline: none;

  // Initial starting animation when isOpen is already true
  ${(p) =>
    p.isOpen &&
    css`
      animation: ${FadeIn} 300ms ease-out;
    `}

  // Toggle animation between isOpen true/false
  transition: transform 300ms ease-in-out;

  &.open {
    transform: translateX(0);
  }

  &.closed {
    transform: translateX(100%);
  }

  ${responsive.sm`
    width: 455px;
    padding: 56px 40px;

    &.closed {
      transform: translateX(455px);
    }
  `};

  ${(p) => p.contentStyle};
`;

const ModalCloseButton = styled.button`
  top: 24px;
  right: 24px;
  position: ${(p) => (p.mobileFixedClose ? "fixed" : "absolute")};
  padding: 0px;
  z-index: 1001;
  background: transparent;
  border: none;

  cursor: pointer;
  width: 16px;
  height: 16px;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  ${responsive.sm`
    position: absolute;
  `};

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    opacity: ${Opacity.light};
  }

  ${(p) => p.modalCloseButtonStyle};
`;

const CloseButtonWrapper = styled.div`
  ${(p) => p.closeButtonStyle};
`;

export const ModalStyle = css`
  bottom: 0;
  top: initial;
  padding: 0;

  ${responsive.sm`
    top: inherit;
    height: 100%;
    padding: 0;
  `}

  .magic-modal-close-button {
    width: 48px;
    height: 48px;
    top: 16px;
    right: 16px;

    &:hover {
      opacity: 1;
    }
  }
`;

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: 0,
      modalRoot: null,
      id: uuid.v4(),
    };
    this.handleEscDown = this.handleEscDown.bind(this);
    this.handleResize = this.handleResize.bind(this);

    // A reference to the element passed to the lockScroll util when locking
    // the scroll. We store this reference since the same reference must be
    // passed to the util when unlocking the scroll, and the modal ref can
    // change as the component updates.
    this.scrollLockRef = null;
  }

  componentDidMount() {
    const modalRoot = document.getElementById("modal-root");
    if (modalRoot) {
      this.setState({ modalRoot });
    }

    // If the component is open when it mounts, lock the scroll.
    const { isOpen, lockScrolling = true } = this.props;
    if (isOpen && this.modal && lockScrolling) {
      this.scrollLockRef = this.modal;
      lockScroll(true, this.scrollLockRef);
    }

    this.handleResize();

    window.addEventListener("keydown", this.handleEscDown);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    // If the component is not open when it mounts, lock the scroll when the
    // open state is updated.
    const { isOpen, lockScrolling = true } = this.props;
    if (prevProps.isOpen !== isOpen && this.modal && lockScrolling) {
      if (isOpen) this.scrollLockRef = this.modal;
      lockScroll(isOpen, this.scrollLockRef);
    }
  }

  componentWillUnmount() {
    if (this.scrollLockRef) {
      lockScroll(false, this.scrollLockRef);
    }
    window.removeEventListener("keydown", this.handleEscDown);
    window.removeEventListener("resize", this.handleResize);
  }

  handleEscDown(e) {
    if (e.keyCode === keyCodes.ESC && this.props.isOpen) {
      this.close();
    }
  }

  handleResize() {
    const windowHeight = window.innerHeight;
    this.setState({
      windowHeight,
    });
  }

  close() {
    this.props.onRequestClose();
  }

  renderModal() {
    const {
      isOpen,
      ariaLabel,
      children,
      contentStyle,
      mobileFullPage = false,
      mobileFixedClose = false,
      slideOut = false,
      className,
      closeButtonContentOverride,
      closeButtonStyle,
      modalCloseButtonStyle
    } = this.props;
    const { windowHeight, id } = this.state;

    const ContentComponent = slideOut ? SlideOutModalContent : ModalContent;

    let modalCloseButtonContent = <Icons.Close />;
    if (closeButtonContentOverride) {
      modalCloseButtonContent = closeButtonContentOverride;
    }

    return (
      <ModalOverlay
        className={`${className} ${isOpen ? "open" : "closed"}`}
        visible={isOpen}
        onClick={this.close.bind(this)}
        slideOut={slideOut}
        mobileFullPage={mobileFullPage}
      >
        <FocusTrap
          active={isOpen}
          focusTrapOptions={{
            initialFocus: `#magic-modal-close-button-${id}`,
            fallbackFocus: `#focus-trap-wrapper-${id}`,
            allowOutsideClick: true,
          }}
        >
          <ContentComponent
            id={`focus-trap-wrapper-${id}`}
            className={isOpen ? "open" : "closed"}
            ref={(r) => (this.modal = r)}
            aria-label={ariaLabel}
            windowHeight={windowHeight}
            onClick={(e) => {
              e.stopPropagation();
            }}
            isOpen={isOpen}
            contentStyle={contentStyle}
            mobileFullPage={mobileFullPage}
            tabIndex="-1"
          >
            <ModalCloseButton
              className={"magic-modal-close-button"}
              id={`magic-modal-close-button-${id}`}
              data-test-modal-close
              aria-label="Close"
              onClick={this.close.bind(this)}
              mobileFixedClose={mobileFixedClose}
              modalCloseButtonStyle={modalCloseButtonStyle}
            >
              <CloseButtonWrapper closeButtonStyle={closeButtonStyle}>
                {modalCloseButtonContent}
              </CloseButtonWrapper>
            </ModalCloseButton>
            {children}
          </ContentComponent>
        </FocusTrap>
      </ModalOverlay>
    );
  }

  render() {
    const { modalRoot } = this.state;
    if (modalRoot) {
      return createPortal(this.renderModal(), modalRoot);
    }
    return this.renderModal();
  }
}
