// Only one item can have the "default: true" key.
export default {
  "en-US": {
    default: true,
    path: ``,
    locale: `en-US`,
    siteLang: `en-US`,
    ogLocale: `en_US`,
    country: "US",
    storeCode: "US",
    currency: {
      type: "USD",
      postfix: "",
      symbol: "$",
    },
    talkable: "ritual",
    "talkable-staging": "ritual-staging",
    payments: {
      stripe: true,
      paypal: true,
      applepay: true,
      googlepay: true,
    },
    appleAuthLocale: "en_US",
    "newsletter-checkbox-enabled": true,
  },
  "en-GB": {
    path: `uk`,
    locale: `en-GB`,
    siteLang: `en-GB`,
    ogLocale: `en_GB`,
    country: "UK",
    storeCode: "GB",
    aliases: ["en-UK"],
    currency: {
      type: "GBP",
      postfix: "",
      symbol: "£",
    },
    talkable: "ritual-uk",
    "talkable-staging": "ritual-uk-staging",
    payments: {
      stripe: true,
      paypal: false,
      applepay: true,
      googlepay: true,
    },
    appleAuthLocale: "en_GB",
    "newsletter-checkbox-enabled": false,
  },
  "en-CA": {
    path: `ca`,
    locale: `en-CA`,
    siteLang: `en-CA`,
    ogLocale: `en_CA`,
    country: "CA",
    storeCode: "CA",
    aliases: ["fr-CA"],
    currency: {
      type: "CAD",
      postfix: " CAD",
      symbol: "$",
    },
    talkable: "ritual-canada",
    "talkable-staging": "ritual-canada-staging",
    payments: {
      stripe: true,
      paypal: false,
      applepay: true,
      googlepay: true,
    },
    appleAuthLocale: "en_US",
    "newsletter-checkbox-enabled": false,
  },
};
