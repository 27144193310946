import styled from "styled-components";
import { openConsentManager } from "@segment/consent-manager";

// Components
import Text from "../../Text";

// Utils
import { Color, Opacity } from "../../../utils/style";

const Wrapper = styled.div`
  margin-right: 16px;
  display: inline-block;
`;

const Button = styled.button`
  color: ${Color.ritualBlue};
  font-weight: 500;
  text-align: left;

  padding: 0;
  margin: 0;

  background: none;
  border: none;

  &:hover {
    opacity: ${Opacity.light};
  }

  transition: all 0.2s;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const DoNotTrack = () => {
  return (
    <Wrapper>
      <Button onClick={openConsentManager}>
        <Text
          id="footer.cookie-preferences"
          defaultMessage="Cookie Preferences"
        />
      </Button>
    </Wrapper>
  );
};

export default DoNotTrack;
