import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { MouseEventHandler, ReactNode, useState } from "react";
import styled from "styled-components";
import {
  InfoComponent,
  LinkComponent,
  CountdownComponent
} from "../../hooks/contentful/useHelloBarPromotion";
import useThrottledResizeObserver from "../../hooks/useThrottledResizeObserver";
import intl from "../../services/intl";
import { Icons } from "../../utils/react-svg";
import { Font, responsive } from "../../utils/style";
import { Color, Typography } from "../../utils/styleDesignSystem";
import MagicLink from "../MagicLink";
import PromotionDetailsModal from "./PromotionDetailsModal";
import CountdownTimer from "./CountdownTimer";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px 16px;
  ${responsive.sm`
    padding: 12px 34px;
  `}

  background: ${Color.indigoBlue};

  color: ${Color.white};
  ${Typography.body3}
  ${Font.circular}
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const Image = styled(GatsbyImage)`
  flex-shrink: 0;
  min-width: 24px;
  max-width: 48px;
  ${responsive.md`
    min-width: 32px;
    max-width: 64px;
  `}
  img {
    transition: none !important;
  }
`;

const Content = styled.div`
  p {
    margin: 0;
  }
  a,
  a:hover {
    color: ${Color.white};
    text-decoration: underline;
  }
`;

const InfoButton = styled.button`
  padding: 0;
  border: 0;
  background: none;
  line-height: 0;
  margin-left: 6px;
`;

const CloseButton = styled.button`
  flex-shrink: 0;
  ${responsive.sm`
    position: absolute;
    right: 16px;
  `}

  padding: 0;
  border: 0;
  background: none;
  line-height: 0;
`;

const CloseIcon = styled(Icons.Close)`
  path {
    stroke: ${Color.indigoBlue40};
    transition: stroke 200ms ease-in-out;
  }
  &:hover {
    path {
      stroke: ${Color.white};
    }
  }
`;

const Link: LinkComponent = ({ to, children }) => (
  <MagicLink to={to}>{children}</MagicLink>
);

const Info: InfoComponent = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <InfoButton
        onClick={openModal}
        aria-label={intl.t("general.learn-more", "Learn More")}
      >
        <Icons.Info $color={Color.indigoBlue40} />
      </InfoButton>
      <PromotionDetailsModal
        title={intl.t("banners.disclaimer.title", "Promo Details")}
        body={children}
        isOpen={modalOpen}
        onRequestClose={closeModal}
      />
    </>
  );
};

const Countdown: CountdownComponent = (props) => {
  return <CountdownTimer promotion={props} />
};

type HelloBarProps = {
  content: ReactNode;
  image?: IGatsbyImageData;
  onCloseClick: MouseEventHandler;
};
const HelloBar = ({ content, image, onCloseClick }: HelloBarProps) => {
  const { ref: contentRef, height: imageWidth } = useThrottledResizeObserver();

  return (
    <Wrapper>
      <ContentWrapper>
        {image && (
          <Image
            image={image}
            alt=""
            loading="eager"
            style={{ width: imageWidth }}
          />
        )}
        <Content ref={contentRef}>{content}</Content>
      </ContentWrapper>
      <CloseButton
        onClick={onCloseClick}
        aria-label={intl.t("general.close", "Close")}
      >
        <CloseIcon />
      </CloseButton>
    </Wrapper>
  );
};

export const renderContent = {
  Link,
  Info,
  Countdown
};

export default HelloBar;
