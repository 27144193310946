import { graphql, useStaticQuery } from "gatsby";
import intlService from "../../services/intl";

export type NavigationPanelNode = {
  panelId: string;
  node_locale: string;
  name: string;
  title: string | null;
  linkSets: NavigationLinkSetNode[];
};

export type NavigationLinkSetNode = {
  name: string;
  type: "L1" | "L2" | "tile" | "card" | "one-column" | "two-column";
  title: string | null;
  links: NavigationLinkNode[];
  style: "divider"[] | null;
};

export type NavigationLinkNode = {
  name: string;
  text: string;
  to: { panelId: string } | { slug: string };
  content: { raw: string } | null;
  image: { url: string } | null;
  altImage: { url: string } | null;
};

const filterNestedPanels = (
  nodes: NavigationPanelNode[],
  node: NavigationPanelNode,
) => {
  const nestedPanelIds = node.linkSets
    .map((linkSet) =>
      linkSet.links
        .map((link) => link?.to && "panelId" in link?.to && link?.to?.panelId)
        .filter((panel): panel is string => typeof panel === "string"),
    )
    .flat();

  const nestedPanels = nestedPanelIds.map((panelId) =>
    nodes.find((n) => n.panelId === panelId),
  );

  return [node, ...nestedPanels];
};

const useNavigation = (panelId: string) => {
  const { allContentfulNavigationPanel } = useStaticQuery(graphql`
    query NavigationPanel {
      allContentfulNavigationPanel {
        nodes {
          panelId: contentful_id
          node_locale
          name
          title
          linkSets {
            linkSetId: contentful_id
            name
            type
            title
            links {
              linkId: contentful_id
              name
              text
              to {
                ... on ContentfulNavigationPanel {
                  panelId: contentful_id
                }
                ... on ContentfulShopLandingPage {
                  path
                }
                ... on ContentfulBundleLandingPage {
                  path
                }
                ... on ContentfulProduct {
                  path
                }
                ... on ContentfulNavigationPath {
                  path
                }
                ... on ContentfulPageVariation {
                  path: slug
                }
              }
              content {
                raw
              }
              image {
                url
              }
              altImage {
                url
              }
              style
              flag {
                content
              }
            }
            style
          }
        }
      }
    }
  `);
  const localNodes = intlService.cf(
    allContentfulNavigationPanel.nodes,
  ) as NavigationPanelNode[];
  const node = localNodes.find((n) => n.panelId === panelId);

  return node
    ? {
        node,
        panels: filterNestedPanels(localNodes, node),
      }
    : null;
};

export default useNavigation;
