import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { detectIOS } from "./user-agent";

function lock(target) {
  disableBodyScroll(target, {
    allowTouchMove: (el) => {
      while (el && el !== document.body) {
        if (el.getAttribute("data-body-scroll-lock-ignore") !== null) {
          return true;
        }

        el = el.parentElement;
      }
    },
  });
}

function unlock(target) {
  enableBodyScroll(target);
}

export function lockScroll(open, target = undefined) {
  const isIOS = detectIOS();
  if (isIOS) return;
  open ? lock(target) : unlock(target);
}
