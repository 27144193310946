import { createSelector } from "reselect";

import cartSelectors from "../cart/selectors";

const _byId = (state) => state.coupons.byId;

const activeCoupon = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) return null;
    return byId[activeCart.couponId];
  },
);

export default {
  activeCoupon,
};
