import styled from "styled-components";

// Utils
import { media, Color, rem, Font, rgba } from "../../../utils/style";
import { FootnoteId } from "../../../data/constants/html";

// Components
import Text from "../../Text";

const DisclaimerTextWrapper = styled.div`
  p {
    ${Font.circular}
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 500;
    padding: 16px;
    margin: 0 0 16px;
    color: ${Color.ritualBlue};
    border: 1px solid ${Color.fadedGrey};

    ${media.mobile`
      margin: 24px 0;
    `}

    [data-whatintent="mouse"] & a,
    [data-whatintent="touch"] & a {
      visibility: hidden;
    }
  }
`;

const DisclaimerText = () => {
  return (
    <DisclaimerTextWrapper>
      <h2 className="sr-only" id="disclaimer-footnote-label">
        <Text id="footer.disclaimer.heading" defaultMessage="Footnotes" />
      </h2>
      <p id={FootnoteId}>
        <Text
          id="footer.disclaimer.main"
          defaultMessage={`* These statements have not been evaluated by the Food and Drug
          Administration. This product is not intended to diagnose, treat, cure or
          prevent any disease`}
        />
        {` `}
        <a href="#footnote-ref" aria-label="Back to content">
          &#9166;
        </a>
      </p>
    </DisclaimerTextWrapper>
  );
};
export default DisclaimerText;
