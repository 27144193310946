import classNames from "classnames/bind";

// Constants
import { ActionType } from "../../constants/product";

// Components
import CartBanner from "./CartBanner";

// Services
import intl from "../../services/intl";

// Redux
import { useSelector } from "react-redux";
import cartSelectors, {
  showProductOfferLimitBanner,
} from "../../store/cart/selectors";
import cartProductSelectors, {
  hasProductOfferInCart,
} from "../../store/cart-product/selectors";

// Utils
import { getBundleCartLimit } from "../../utils/bundle";
import { isBundlePromotion, getPromotionDetails } from "../../utils/promotion";

// Hooks
import useDiscount from "../../hooks/discount/useDiscount";
import { productOfferForId } from "../../store/product-offer/selectors";
import { getStore } from "../../store/createStore";

function getProductOfferDiscountValue(productOffer) {
  const contentfulProductBundle = productOffer?.contentfulProductBundle(
    getStore().getState(),
  );

  return contentfulProductBundle?.discountValue;
}

export default function CartTopBannerArea({ className }) {
  const shouldShowLimitBanner = useSelector(cartSelectors.showLimitBanner);
  const shouldShowWipeoutBanner = useSelector(cartSelectors.showWipeoutBanner);
  const productOfferInCart = useSelector(hasProductOfferInCart);
  const cartProducts = useSelector(cartProductSelectors.activeCartProducts);
  const cartProductCount = cartProducts?.length;
  const productOffer = useSelector((state) =>
    productOfferForId(state, cartProducts?.[0]?.productOfferId),
  );

  const quantityLimit = getBundleCartLimit();
  const shouldShowProductOfferLimitBanner = useSelector(
    showProductOfferLimitBanner,
  );

  const cartProductTotal = useSelector(
    cartProductSelectors.activeCartProductTotal,
  );
  const promotion = useDiscount(ActionType.Checkout);
  const promotionDetails = getPromotionDetails(promotion, cartProductTotal);
  const productOfferDiscountValue = getProductOfferDiscountValue(productOffer);

  const showProductOfferSavingsMessage =
    productOfferInCart && cartProductCount === 1 && !!productOfferDiscountValue;

  return (
    <>
      {showProductOfferSavingsMessage && (
        <CartBanner
          className={classNames(className, "yellow")}
          bannerMessage={intl.t(
            "cart.review-order.banner-product-offer-single-cart-product",
            `Good news! Your bundle is saving you {discountValue} off your first month.`,
            {
              discountValue: productOfferDiscountValue,
            },
          )}
        />
      )}
      {isBundlePromotion(promotion) && !productOfferInCart ? (
        <CartBanner
          className={classNames(className, "yellow")}
          bannerMessage={intl.t(
            "cart.review-order.banner-bundle",
            `Nice! You unlocked a {discount}.`,
            {
              discount: intl.t(
                "cart.review-order.banner-bundle-discount",
                "{copy} Bundle Discount",
                { copy: promotionDetails.formattedValue },
              ),
            },
          )}
        />
      ) : (
        promotion &&
        !productOfferInCart && (
          <CartBanner
            className={classNames(className, "yellow")}
            bannerMessage={intl.t(
              "cart.review-order.banner-sitewide-promo",
              `Nice! You unlocked the {sale}.`,
              { sale: promotion.displayName || "sale discount" },
            )}
          />
        )
      )}
      {shouldShowLimitBanner && (
        <CartBanner
          className={className}
          bannerMessage={intl.t(
            "cart.review-order.banner-limit",
            `You can only have {limit} items in your cart at a time—remove one to add another.`,
            { limit: quantityLimit },
          )}
        />
      )}
      {shouldShowProductOfferLimitBanner && (
        <CartBanner
          className={className}
          bannerMessage={intl.t(
            "cart.review-order.banner-product-offer-limit",
            `banner-product-offer-limit`,
          )}
        />
      )}
      {shouldShowWipeoutBanner && (
        <CartBanner
          className={className}
          bannerMessage={intl.t(
            "cart.review-order.banner-wipeout",
            `You can only have {limit} items in your cart at a time—we’ve replaced your previous items with the new ones added.`,
            { limit: quantityLimit },
          )}
        />
      )}
    </>
  );
}
