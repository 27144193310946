import styled from "styled-components";

// Services
import intl from "../../../services/intl";

// Utils
import { Color } from "../../../utils/style";

const SkipNavLink = styled.a`
  display: block;
  position: absolute;
  top: -10000px;
  left: 12px;
  z-index: 1;
  padding: 0;
  margin: 0;
  opacity: 0;
  color: ${Color.ritualBlue};
  background-color: ${Color.white};

  &:focus {
    top: 16px;
    z-index: 111;
    opacity: 1;
  }
`;

const SkipLink = (props) => {
  const { destination = "#main", linkText = intl.t("nav.skip-link") } = props;

  return <SkipNavLink href={destination}>{linkText}</SkipNavLink>;
};

export default SkipLink;
