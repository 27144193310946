import { createSelector } from "reselect";

const _byId = (state) => state.productGroups.byId;
const _allIds = (state) => state.productGroups.allIds;

const allProductGroups = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

const productGroupForId = createSelector(
  allProductGroups,
  (_, props) => props.id,
  (allProductGroups, id) =>
    allProductGroups.find((productGroup) => productGroup.id === id),
);

export default {
  allProductGroups,
  productGroupForId,
};
