import { useEffect, useState, useRef } from "react";
import styled from "styled-components";

// Utils
import { rem, responsive, Opacity } from "../../utils/style";
import { Color } from "../../utils/styleDesignSystem";
import { Icons } from "../../utils/react-svg";
import intl from "../../services/intl";
import { getPromotionDetails } from "../../utils/promotion";
import countdown from "../../../utils/vendor/countdown/countdown";

// Components
import MessageBanner from "../MessageBanner";
import PromotionDetailsModal from "./PromotionDetailsModal";
import Text from "../Text";

const BannerContent = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;

  ${responsive.sm`
    padding: 17px 0;
  `}

  ${responsive.md`
    justify-content: center;
  `}
`;

const BannerText = styled.div`
  display: inline;

  p {
    display: inline;
    font-weight: 500;
    font-size: ${rem(16)};
    line-height: ${rem(22)};
    margin-bottom: 0;

    span {
      display: inline-block;
      text-align: left;
      font-weight: 500;
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      min-width: 105px;
      width: 105px;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      color: inherit;
    }
  }

  ${responsive.md`
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: ${rem(18)};

      span {
        font-size: ${rem(18)};
        min-width: 116px;
        width: 116px;
      }
    }
  `}
`;

const TooltipButton = styled.button`
  background: transparent;
  border: none;
  opacity: ${Opacity.light};
  height: 16px;
  width: 16px;
  display: inline;
  position: relative;
  padding: 0;
  margin-left: 8px;
  vertical-align: text-bottom;

  &:hover {
    opacity: 1;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    height: 16px;
    width: 16px;
    margin-left: 1px;
    top: 0;
    left: 0;
  }
`;

export default function CountdownBanner(props) {
  const { title, timerTitle, promotion, link = null, prefix } = props;

  const [count, setCount] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const timerId = useRef();

  useEffect(() => {
    if (shouldShowCountdownTimer()) {
      timerId.current = startCountdown();
    }
    return () => {
      window.clearInterval(timerId.current);
    };
  }, []);

  function shouldShowBanner() {
    if (!promotion) return false;

    const { activatesAt, expiresAt } = promotion;

    return (
      (!activatesAt || new Date(activatesAt) <= Date.now()) &&
      (!expiresAt || Date.now() <= new Date(expiresAt))
    );
  }

  function shouldShowCountdownTimer() {
    if (!promotion) return false;

    const { expiresAt } = promotion;

    const window = 86400000 * 2; // 48 hours
    return expiresAt && new Date(expiresAt) - window < Date.now();
  }

  function startCountdown() {
    if (typeof window === "undefined") return;

    return countdown(
      (ts) => {
        if (!shouldShowCountdownTimer()) {
          endCountdown();
          return;
        }
        setCount(formatTS(ts));
      },
      new Date(promotion.expiresAt),
      countdown.HOURS | countdown.MINUTES | countdown.SECONDS,
    );
  }

  function endCountdown() {
    window.clearInterval(timerId.current);
    setCount("");
  }

  function formatTS(ts) {
    const hours = ts.hours < 10 ? `0${ts.hours}` : ts.hours;
    const minutes = ts.minutes < 10 ? `0${ts.minutes}` : ts.minutes;
    const seconds = ts.seconds < 10 ? `0${ts.seconds}` : ts.seconds;

    return `${hours}h ${minutes}m ${seconds}s`;
  }

  function openModal() {
    setModalOpen(true);
  }

  function closeModal() {
    setModalOpen(false);
  }

  function expires() {
    const { expiresAt } = promotion || {};
    if (!expiresAt) return "(never)";
    const timeZone = "America/Los_Angeles";

    return `${intl.formatDateTime(new Date(expiresAt), {
      timeZone,
    })} ${intl.formatDateTime(new Date(expiresAt), {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZone,
      timeZoneName: "short",
    })}`;
  }

  if (!shouldShowBanner()) return null;

  const promotionDetails = getPromotionDetails(promotion);
  const { formattedValue, maxDiscount } = promotionDetails;

  const titleTranslation =
    shouldShowCountdownTimer() && count ? timerTitle : title;

  const disclaimerText = intl.t(
    `banners.disclaimer.${prefix}.details`,
    "This offer expires on {expiry} and cannot be combined with other offers, discounts, or applied to prior purchases.",
    {
      expiry: expires(),
    },
  );

  return (
    <MessageBanner {...props}>
      <BannerContent>
        <BannerText>
          <p>
            <Text
              id={titleTranslation}
              values={{
                amount: formattedValue,
                count,
                link,
              }}
            />
          </p>
          <TooltipButton onClick={openModal}>
            <Icons.Info $color={Color.white} />
          </TooltipButton>
        </BannerText>
      </BannerContent>

      <PromotionDetailsModal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        title={intl.t("banners.disclaimer.title", "Promo Details")}
        body={disclaimerText}
      />
    </MessageBanner>
  );
}
