import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "SHIPPING-OPTION";

export default class ShippingOption extends Model {
  // Attributes
  name: string = "";
  amount: number = 0;
  threshold: number = 0;
  eligible: boolean = false;

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
