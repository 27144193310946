import { Component } from "react";
import RitualButton from "./RitualButton";
import { navigate } from "../../services/navigation";

export default class AsyncRitualButton extends Component {
  constructor() {
    super();

    this.state = {
      processing: false,
    };
  }

  _updateLocation(href) {
    window.location.href = href;
  }

  _navigate(to) {
    navigate(to);
  }

  async navigateHrefOnClick(onClick, href, e) {
    e.preventDefault();
    this.setState({
      processing: true,
    });
    await onClick(e);
    this._updateLocation(href);
  }

  async navigateToOnClick(onClick, to, e) {
    e.preventDefault();
    this.setState({
      processing: true,
    });
    await onClick(e);
    if (!this.props.noNavigate) this._navigate(to);
    this.setState({
      processing: false,
    });
  }

  render() {
    const { processing } = this.state;
    const disabled =
      typeof this.props.disabled === "undefined"
        ? processing
        : this.props.disabled;

    if (!this.props.onClick) {
      return <RitualButton {...this.props} disabled={disabled} />;
    }

    const newProps = { ...this.props };
    const onClick = newProps.onClick;

    if (newProps.to) {
      newProps.onClick = (e) => {
        this.navigateToOnClick(onClick, newProps.to, e);
      };
    } else if (newProps.href) {
      newProps.onClick = (e) => {
        this.navigateHrefOnClick(onClick, newProps.href, e);
      };
    }

    return <RitualButton {...newProps} disabled={disabled} />;
  }
}
