import ProductOfferPlan, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addProductOfferPlansFromGraphQL(productOfferData) {
  return (dispatch) => {
    productOfferData.forEach((data) => {
      dispatch(addProductOfferPlan(data));
    });
  };
}

export function addProductOfferPlan(data) {
  const productOfferPlan = new ProductOfferPlan();
  productOfferPlan.deserialize(data);

  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().productOfferPlans,
      ModelType,
      productOfferPlan,
    );
  };
}
