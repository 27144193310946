// Utils
import metrics from "../metrics";

// Store
import { getStore } from "../../store/createStore";
import cartSelectors from "../../store/cart/selectors";

export function trackCouponAdded(code) {
  const state = getStore().getState();
  const activeCart = cartSelectors.activeCart(state);

  if (!activeCart) return;

  const { discountCode, discountAmount } = activeCart;

  if (code !== discountCode) return;

  metrics.track("Coupon Applied", {
    cart_id: activeCart.id,
    coupon_id: code,
    coupon_name: code,
    discount: discountAmount / 100,
  });
}

export function trackCouponRemoved(code, amount) {
  const state = getStore().getState();
  const activeCart = cartSelectors.activeCart(state);

  if (!activeCart) return;

  metrics.track("Coupon Removed", {
    cart_id: activeCart.id,
    coupon_id: code,
    coupon_name: code,
    discount: amount / 100,
  });
}

export function trackCouponDenied(code) {
  const state = getStore().getState();
  const activeCart = cartSelectors.activeCart(state);

  if (!activeCart) return;

  metrics.track("Coupon Denied", {
    cart_id: activeCart.id,
    coupon_id: code,
    coupon_name: code,
  });
}
