import camelCase from "lodash/camelCase";
import { IsStorageSupported, safeParse } from "./helpers";

export function createSessionQueryParams(searchKeys = [], namespace) {
  if (typeof window === "undefined" || !IsStorageSupported) {
    return {};
  }

  const sessionStorageData = sessionStorage.getItem(namespace);
  if (!window.location.search && !sessionStorageData) return {};

  const queryParams = new URLSearchParams(window.location.search);

  // Note that we only pull params from session storage
  // if there are no other existing query params in the URL
  const persistedParams =
    (!window.location.search && safeParse(sessionStorageData)) || {};

  const activatedQueries = {};
  searchKeys.forEach((key) => {
    const value = persistedParams[key] || queryParams.get(key);
    if (value) activatedQueries[key] = value;
  });

  sessionStorage.setItem(namespace, JSON.stringify(activatedQueries));

  const formattedQueries = {};
  Object.keys(activatedQueries).forEach((key) => {
    formattedQueries[camelCase(key)] = activatedQueries[key];
  });
  return formattedQueries;
}
