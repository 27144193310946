import UAParser from "ua-parser-js";

const webViewBrowsers = ["Facebook", "Instagram"];
const userAgentPieces = ["musical_ly", "Snapchat"];

function uaContainsWebViewString(uaParser) {
  const userAgentString = uaParser.getUA();
  return userAgentPieces.find((piece) => userAgentString.includes(piece));
}

function hasWebViewBrowser(uaParser) {
  const browser = uaParser.getBrowser().name;
  return webViewBrowsers.find((webViewBrowser) => webViewBrowser === browser);
}

export function isWebView() {
  const uaParser = new UAParser();

  return hasWebViewBrowser(uaParser) || uaContainsWebViewString(uaParser);
}

export function detectIOS() {
  return (
    typeof window !== "undefined" &&
    ([
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator?.userAgentData?.platform || navigator?.platform) ||
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)) // iPad on iOS 13 detection
  );
};
