import { Component } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

import { Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import Text from "../Text";

const Quantity = styled.div`
  display: flex;
  align-items: center;

  &.rounded {
    border: 1px solid rgba(20, 43, 111, 0.24);
    padding: 8px 20px;
    ${responsive.md`
      padding: 11px 20px;
    `}
    border-radius: 21px !important;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  align-items: center;
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}

  svg {
    display: none;

    ${responsive.md`
      display: block;
      width: 14px;
      height: 8px;
      position: absolute;
      top: calc(50% - 4px);
      right: 0;
      pointer-events: none;
    `};
  }

  &.rounded svg {
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    top: calc(50% - 3px);
    right: 0;
    pointer-events: none;

    filter: grayscale(100%);
    opacity: 0.24;
  }

  > span {
    position: absolute;
    left: 0;
    pointer-events: none;
  }

  &.disabled {
    opacity: 0.24;
  }

  .sr-label {
    display: none;
  }
`;

const StyledLabel = styled.label`
  position: relative;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${Color.ritualBlue};
  margin: 0;
  padding: 0;

  [data-whatintent="keyboard"] &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }

  span {
    padding-right: 8px;
  }

  &.rounded {
    font-size: ${rem(16)};
  }
`;

const StyledMobileLabel = styled.label`
  position: relative;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  color: ${Color.ritualBlue};
  border-bottom: 2px solid ${Color.ritualBlue};
  margin: 0;
  padding: 0;

  [data-whatintent="keyboard"] &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
  }
`;

const Select = styled.select`
  position: absolute;
  font-size: ${rem(16)};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border: none;
  border-radius: 0px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  margin: 0;
  background: 0;
  width: 100%;
  color: ${Color.ritualBlue};

  ${responsive.md`
    position: relative;
    opacity: 1;
    font-size: inherit;
    color: ${Color.ritualBlue};
    cursor: pointer;
    border-bottom: 2px solid ${Color.ritualBlue};
    border-bottom: none;
    width: 30px;
    padding-right: 15px;
    text-align: left !important;
  `};

  &.rounded {
    position: relative;
    opacity: 1;
    font-size: inherit;
    border-bottom: none;
    width: 30px;
    padding-right: 15px;
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class QuantityPicker extends Component {
  renderSelect(label = false) {
    const {
      itemId,
      itemQuantity,
      totalQuantity,
      maxQuantity,
      disabled,
      allowZero,
      rounded,
    } = this.props;

    // Numbers of spaces currently available.
    let availableSpace = maxQuantity - totalQuantity;
    let hasOtherItems = totalQuantity !== itemQuantity;

    if (availableSpace < 1) {
      // Default to current quantity if there's no room for more items.
      availableSpace = itemQuantity;
    } else {
      availableSpace += itemQuantity;
    }

    return (
      <Select
        aria-labelledby={label ? label : undefined}
        id={itemId}
        value={itemQuantity}
        onChange={this.props.onQuantityChange}
        disabled={disabled}
        className={classNames(rounded && "rounded")}
      >
        <option disabled>Quantity</option>
        {allowZero && hasOtherItems && <option value={0}>0</option>}
        {[...Array(availableSpace)].map((_, i) => {
          const amount = i + 1;
          return (
            <option key={i} value={amount}>
              {amount}
            </option>
          );
        })}
      </Select>
    );
  }

  render() {
    const { itemId, itemQuantity, maxQuantity, rounded } = this.props;

    if (maxQuantity === 1) {
      return (
        <Quantity>
          <SelectWrapper>
            {!rounded ? (
              <Text
                id="qty-picker.label-qty"
                defaultMessage="Qty {itemQuantity}"
                values={{ itemQuantity }}
              />
            ) : (
              itemQuantity
            )}
          </SelectWrapper>
        </Quantity>
      );
    }

    const labelId = `${itemId}-label`;

    return (
      <Quantity className={classNames(rounded && "rounded")}>
        {!rounded && (
          <SelectWrapper className="d-flex d-md-none">
            <StyledMobileLabel
              id={`${labelId}-mobile`}
              aria-label="Quantity"
              htmlFor={itemId}
            >
              <Text
                id="qty-picker.label-qty"
                defaultMessage="Qty {itemQuantity}"
                values={{ itemQuantity }}
              />
              <Icons.CaretDown />
              {this.renderSelect(labelId)}
            </StyledMobileLabel>
          </SelectWrapper>
        )}
        <SelectWrapper
          className={"d-md-flex " + (rounded ? " rounded" : "d-none")}
        >
          <StyledLabel
            id={`${labelId}`}
            className={classNames(rounded && "rounded")}
            aria-label="Quantity"
            htmlFor={itemId}
          >
            {!rounded && (
              <span>
                <Text id="qty-picker.label" defaultMessage="Qty" />
              </span>
            )}
            {this.renderSelect()}
            <Icons.CaretDown />
          </StyledLabel>
        </SelectWrapper>
      </Quantity>
    );
  }
}
