const defaultConfig = {
  promotions: {
    bundlePromotion: {
      amountOff: 10,
    },
    newYearsPromotion: {
      amountOff: 30,
      percentOff: 21,
    },
  },
};

export default {
  US: {
    default: true,
    ...defaultConfig,
  },
  GB: {
    ...defaultConfig,
  },
  CA: {
    ...defaultConfig,
  },
};
