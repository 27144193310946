import { css } from "styled-components";

export function rem(value) {
  return `${value / 16}rem`;
}

export function maxLines(lineHeight, numberOfLines) {
  return `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: ${numberOfLines};
    max-height: ${lineHeight * numberOfLines};
  `;
}

export function capitalize(string) {
  let lowercaseString = string.toLowerCase();
  return lowercaseString.charAt(0).toUpperCase() + lowercaseString.slice(1);
}

// Font order: New font name, Shopify font name, backup;
export const Font = {
  dutch: `font-family: 'Dutch801', serif;`,
  circular: `font-family: 'LL Circular', sans-serif;font-feature-settings: "ss06" 1;`,
};

export const Variables = {
  navHeight: "64px",
  navHeightSmall: "48px",
  navAnimationTime: "300ms",
  navAnimationTimeMobile: "500ms",
  navContentAnimationTime: "250ms",
};

// Design System will fix this
export const Opacity = {
  lightest: "0.16",
  lighter: "0.24",
  light40: "0.40",
  light: "0.56",
  light60: "0.60",
};

export const Color = {
  ritualBlue: "#142b6f",
  ritualYellow: "#ffd600",
  ritualGrey: "#f2f1f5",
  ritualLightBlue: "#e1e9f0",
  ritualLightYellow: "#fff5c2",
  ritualUnderscoreYellow: "#ffe666",
  ritualSecondaryBlue: "#9fafc9",
  ritualDisabledBlue: "#8b95b4",
  ritualSecondaryDisabledBlue: "#B3BBD1",
  ritualRed: "#eb4343",
  ritualAccessibleRed: "#db3333", // contrast ratio of 4.5:1 on White background
  ritualPink: "#f97878",
  ritualGreen: "#55940b",
  ritualDarkGreen: "#4c840d",

  upsellYellow: "#ffeeaa",

  white: "#ffffff",
  black: "#000000",

  paypalGold: "#ffce31",

  eventsBlue: "#f5f7f8",

  superFadedBlue: "rgba(20, 43, 111, 0.16)",
  fadedBlue: "rgba(20, 43, 111, 0.24)",
  lessFadedBlue: `rgba(20, 43, 111, ${Opacity.light})`,
  fadedGrey: "rgba(0, 0, 0, 0.16)",
  veryFadedGrey: "rgba(0, 0, 0, 0.08)",

  indigoBlue60: "#62719F",
};

export function rgba(hexColor, opacity) {
  const hexToRgb = (hex) =>
    hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b,
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

  const rgb = hexToRgb(hexColor).map((val) => (val > -1 ? val : `255`));
  return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity || 1})`;
}

export const sizes = {
  sm: 749,
  tablet: 959,
  desktop: 960,
};

export const atMost = {
  mobile: () =>
    window.matchMedia && window.matchMedia("(max-width: 749px)").matches,
  tablet: () =>
    window.matchMedia && window.matchMedia("(max-width: 959px)").matches,
};

export const atLeast = {
  tablet: () =>
    window.matchMedia && window.matchMedia("(min-width: 750px)").matches,
  desktop: () =>
    window.matchMedia && window.matchMedia("(min-width: 960px)").matches,
  md: () =>
    window.matchMedia && window.matchMedia("(min-width: 1024px)").matches,
  largeDesktop: () =>
    window.matchMedia && window.matchMedia("(min-width: 1366px)").matches,
};

// Mobile-first & correspond to the same breakpoints
// in account-manager and checkout
export const responsive = {
  sm: (...args) => css`
    // 750px
    @media (min-width: 46.875em) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    // 960px
    @media (min-width: 60em) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    //  1200px
    @media (min-width: 75em) {
      ${css(...args)};
    }
  `,
  xxl: (...args) => css`
    //  1700px
    @media (min-width: 106.25em) {
      ${css(...args)};
    }
  `,
};

export const media = {
  mobile: (...args) => css`
    // 749px
    @media (max-width: 46.8125em) {
      ${css(...args)};
    }
  `,
  tablet: (...args) => css`
    // 959px
    @media (max-width: 59.9375em) {
      ${css(...args)};
    }
  `,
  desktop: (...args) => css`
    // 960px
    @media (min-width: 60em) {
      ${css(...args)};
    }
  `,
  reducedMotion: (...args) => css`
    @media screen and (prefers-reduced-motion: reduce) {
      ${css(...args)};
    }
  `,
  /*
   *  notDesktop: This is for the breakpoints between mobile & desktop that don't quite
   *  fit properly.
   */
  notDesktop: (...args) => css`
    // 1198px
    @media (max-width: 74.875em) {
      ${css(...args)};
    }
  `,
};

export const getBreakpoint = () => {
  if (atMost.mobile()) return "xs";
  if (atMost.tablet()) return "sm";
  if (!atLeast.largeDesktop()) return "md";
  return "lg";
};

export const hideScrollbars = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const link = css`
  border-bottom: 1px solid ${Color.ritualBlue};
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

export const yellowUnderline = css`
  u {
    a {
      position: relative;
      text-underline-position: from-front;
      text-decoration-color: #ffe666;
      text-decoration-line: underline;
      text-underline-offset: -2px;
      text-decoration-skip-ink: none;
      text-decoration-thickness: 6px;
      opacity: 1;
      transition: all 200ms ease-in-out;

      ${responsive.sm`
        text-decoration-thickness: 8px;
      `}

      &:hover {
        opacity: 0.6;
      }
    }
  }
`;

export const imageStyle = {
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  zIndex: "1",
  userSelect: "none",
  userDrag: "none",
  pointerEvents: "none",
  touchCallout: "none",
};
