import { combineReducers } from "redux";
import normalizedReducers from "../utils/normalized/reducers";
import { ModelType } from "./model";

function meta(state = {}, action) {
  state = normalizedReducers.metaReducer(ModelType, state, action);
  switch (action.type) {
    case `UPDATE_${ModelType}`:
    case `CREATE_${ModelType}`:
      return {
        ...state,
        activeId: action.payload.id,
      };
    case "UPDATE_CART_REPLACE_BANNER":
      return {
        ...state,
        showReplaceBanner: action.payload.shouldDisplay,
      };
    case "UPDATE_CART_LIMIT_BANNER":
      return {
        ...state,
        showLimitBanner: action.payload.shouldDisplay,
      };
    case "UPDATE_CART_PRODUCT_OFFER_LIMIT_BANNER":
      return {
        ...state,
        showProductOfferLimitBanner: action.payload.shouldDisplay,
      };
    case "UPDATE_CART_WIPEOUT_BANNER":
      return {
        ...state,
        showWipeoutBanner: action.payload.shouldDisplay,
      };
    case "UPDATE_FLYOUT_CART":
      return {
        ...state,
        showFlyoutCart: action.payload.shouldDisplay,
      };
    case "UPDATE_CART_FETCH_FAILED":
      return {
        ...state,
        fetchFailed: action.payload.fetchFailed,
      };
    case "UPDATE_CART_QUEUE":
      return {
        ...state,
        queue: action.payload.queue,
      };
    case "CART_ACTION_START":
      return {
        ...state,
        processing: true,
      };
    case "CART_ACTION_END":
      return {
        ...state,
        processing: false,
      };
    default:
      return state;
  }
}

function byId(state = {}, action) {
  return normalizedReducers.byIdReducer(ModelType, state, action);
}

function allIds(state = [], action) {
  return normalizedReducers.allIdsReducer(ModelType, state, action);
}

export default combineReducers({ meta, byId, allIds });
