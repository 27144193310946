import Bugsnag from "@bugsnag/js";

function info(error, metadata) {
  _notifyError(error, metadata, "info");
}

function warn(error, metadata) {
  _notifyError(error, metadata, "warning");
}

function error(error, metadata) {
  _notifyError(error, metadata, "error", true);
}

function _notifyError(
  error,
  metadata = undefined,
  severity,
  unhandled = false,
) {
  if (!(error instanceof Error)) {
    error = new Error(error);
  }

  _logToConsole(error, severity);

  if (!Bugsnag) return;

  Bugsnag.notify(error, (e) => {
    metadata && e.addMetadata(severity, metadata);
    e.severity = severity;
    e.unhandled = unhandled;
  });
}

function _logToConsole(error, severity) {
  switch (severity) {
    case "info":
      console.info(error);
      break;
    case "warning":
      console.warn(error);
      break;
    case "error":
      console.error(error);
      break;
  }
}

function getMetadata(error) {
  // If the error is an array, it's likely a response from the backend
  // including status and detail properties.
  if (error.errors && error.errors.length) {
    error = error.errors[0];
    return {
      status: error.status || null,
      message: error.detail || null,
    };
  }

  // If the error has a status but is not an array, it's likely a failed
  // response that could not be parsed.
  if (error.status) {
    return {
      status: error.status,
      message: error.detail || null,
    };
  }

  // If the error is a string, simply return the error itself as the message
  // attribute.
  if (typeof error === "string") {
    return { message: error };
  }

  // If the error is an instance of Error, return the error's message property.
  if (error instanceof Error) {
    return { message: error.message };
  }

  return {};
}

export default {
  info,
  warn,
  error,
  getMetadata,
};
