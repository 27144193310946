import { variation } from "../services/launchDarkly";
import { getCookie } from "./cookies";
import { safeParse, IsStorageSupported } from "./helpers";

let fallbackAnonymousId = "";

const FallbackUser = {
  id: () => "",
  anonymousId: () => fallbackAnonymousId,
  traits: () => {
    return {};
  },
};

export const analytics = {
  on: (...args) =>
    callIfPresent(window.analytics && window.analytics.on, { args }),

  /**
   * Waits for window.analytics to be available and window.analytics.ready to
   * be called before returning the Segment user info. If window.analytics is
   * not available when this function is called, we continuously poll until
   * it becomes available.
   *
   * @return {Object} the userInfo object { id:, anonymousId:, traits: }
   */
  waitForUserInfo() {
    return waitForAnalyticsAndGetUser();
  },

  /**
   * Synchronously gets the user info from stored Segment data without waiting
   * for window.analytics or window.analytics.ready. Most of the time (unless
   * Cookies are cleared or it's a first time load), the stored Segment data
   * will be accurate. If the stored data is not available, we return a user
   * with a mock anonymousId.
   */
  getStoredUserInfo() {
    return getUserInfo();
  },

  setAnonymousId: (anonymousId) => {
    fallbackAnonymousId = anonymousId;
    callIfPresent(window.analytics && window.analytics.setAnonymousId, {
      args: [anonymousId],
    });
  },

  get anonymousId() {
    return getAnonymousId();
  },
};

/**
 * Polls for windows.analytics and window.analytics.ready and resolves with
 * the Segment userInfo. Waiting for the ready function ensures that the
 * window.analytics.user function will be available, and that the userInfo
 * returned will be up to date.
 *
 * If window.analytics or window.analytics.ready never become available, then
 * this function will never resolve.
 *
 * @return {Promise<Object>} a promise that resolves to the userInfo
 */
const waitForAnalyticsAndGetUser = async () => {
  const fixFullstoryIntegration = ({ payload, next }) => {
    if (payload.obj.integrations?.["Fullstory (Actions)"]) {
      payload.obj.integrations.Fullstory =
        payload.obj.integrations["Fullstory (Actions)"];
      delete payload.obj.integrations["Fullstory (Actions)"];
    }
    next(payload);
  };

  const pushUserTraitsToDataLayer = ({ payload, next }) => {
    if (
      payload.obj.type === "identify" &&
      typeof window.dataLayer !== "undefined"
    ) {
      window.dataLayer.push(window.analytics.user().traits());
    }
    next(payload);
  };

  if (window.analytics && typeof window.analytics.ready === "function") {
    await new Promise((resolve) => {
      window.analytics.addSourceMiddleware(fixFullstoryIntegration);
      window.analytics.addSourceMiddleware(pushUserTraitsToDataLayer);
      window.analytics.ready(() => {
        const fullstorySampled = variation("fullstory-session-capture", false);
        const fsAvailable = window && window.FS;
        if (fullstorySampled && fsAvailable) {
          window.FS("start");
          console.log("FS: Sampled");
        } else {
          console.log("FS: Unsampled");
        }
        resolve();
      });
    });
    console.log("Analytics is ready");
    return getUserInfo();
  }

  await new Promise((resolve) => setTimeout(resolve, 100));
  return waitForAnalyticsAndGetUser();
};

function getUserInfo() {
  return {
    id: getUserId(),
    anonymousId: getAnonymousId(),
    traits: getUserTraits(),
  };
}

function getUser() {
  return callIfPresent(window.analytics && window.analytics.user, {
    defaultReturn: FallbackUser,
  });
}

/**
 * Retrieves Segment storage data from Cookies based on the specified
 * cookieKey. If the value is not available, the onNoValue function called
 * instead.
 *
 * @param {String} cookieKey
 * @param {Function} onNoValue the function to call if the value is not
 *  available in storage
 * @return {*} The parsed JSON value
 */
export function getCookieValue(cookieKey, onNoValue) {
  const cookieValue = getCookie(cookieKey);
  return cookieValue ? safeParse(cookieValue, cookieValue) : onNoValue();
}

/**
 * Retrieves Segment storage data from Local Storage based on the specified
 * storageKey. If the value is not available, the onNoValue function called
 * instead.
 *
 * @param {String} storageKey
 * @param {Function} onNoValue the function to call if the value is not
 *  available in storage
 * @return {*} The parsed JSON value
 */
export function getStorageValue(storageKey, onNoValue) {
  if (!IsStorageSupported) return onNoValue();

  const storeValue = window.localStorage.getItem(storageKey);
  return storeValue ? safeParse(storeValue, storeValue) : onNoValue();
}

function getUserId() {
  return getCookieValue("ajs_user_id", getUser().id);
}

function getAnonymousId() {
  return getCookieValue("ajs_anonymous_id", getUser().anonymousId);
}

function getUserTraits() {
  // The ajs_user_traits are only persisted in Local Storage.
  return getStorageValue("ajs_user_traits", getUser().traits);
}

/** Non-analytics related helper functions */

function callIfPresent(fn, options = {}) {
  const { args = [], defaultReturn } = options;
  const callee =
    typeof fn === "function"
      ? fn
      : () => {
          return defaultReturn;
        };
  return callee(...args);
}
