import { setCookie, getCookie } from "../../utils/cookies";
import productSelectors from "../product/selectors";

const CookiePrefix = "ritual_waitlist-modal_";

export function setInitialOutOfStockState() {
  return (dispatch, getState) => {
    const state = getState();
    const allProducts = productSelectors.allProducts(state);

    allProducts.forEach((product) => {
      const cookie = getCookie(`${CookiePrefix}${product.sku}`);
      if (cookie) {
        dispatch({
          type: "WAITLISTED",
          payload: {
            sku: product.sku,
          },
        });
      }
    });
  };
}

export function setWaitlistedForSku(sku) {
  return (dispatch) => {
    setCookie(`ritual_waitlist-modal_${sku}`, "true", {
      expires: 60,
    });

    dispatch({
      type: "WAITLISTED",
      payload: {
        sku,
      },
    });
  };
}
