export function findModels(store) {
  if (!store || !store.allIds || !store.byId) {
    throw Error(`No normalized store found!`);
  }

  return store.allIds.map((id) => {
    return store.byId[id];
  });
}

export function findModelsByAssociations(store, associations) {
  if (!store || !store.allIds || !store.byId) {
    throw Error(`No normalized store found!`);
  }
  const matchingModels = [];

  store.allIds.forEach((id) => {
    const model = store.byId[id];
    if (model.matchesAssociations(associations)) {
      matchingModels.push(model);
    }
  });

  return matchingModels;
}

export function findModelByAssociations(store, associations) {
  const matchingModels = findModelsByAssociations(store, associations);
  if (matchingModels.length > 1) {
    throw Error(`Multiple models found with associations!`);
  }
  return matchingModels[0];
}
