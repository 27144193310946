import styled from "styled-components";
import { useLocation } from "@gatsbyjs/reach-router";

import intl from "../../services/intl";

import { Color, rem } from "../../utils/style";
import Text from "../Text";

const Selector = styled.div`
  display: block;
  position: relative;
`;

const Link = styled.a`
  font-size: ${rem(13)};
  color: ${Color.ritualBlue};
  cursor: pointer;
  text-transform: uppercase;
  padding: 8px 14px;

  border: 1px solid
    ${(props) => (props.selected ? Color.ritualBlue : "transparent")};
`;

const LocaleSelector = (props) => {
  const locales = intl.activeLocales;
  const location = useLocation();
  return (
    <Selector {...props}>
      {locales.map((config) => {
        const key = config.locale;
        const selected = intl.locale === key;
        const nameTranslation = `locales.${key}.name`;
        const aria = selected
          ? intl.t(
              "locale-selector.aria-selected",
              "{locale} - Selected language",
              { locale: intl.t(nameTranslation) },
            )
          : intl.t(
              "locale-selector.aria-change",
              "{locale} - Change to language",
              { locale: intl.t(nameTranslation) },
            );

        const href = intl.pathForSelection(key, location);

        function onClick(e) {
          intl.persistLocale(key);
          window.location.href = href;
          e.preventDefault();
        }

        return (
          <Link
            key={key}
            href={href}
            selected={selected}
            aria-label={aria}
            onClick={onClick}
          >
            <Text id={nameTranslation} defaultMessage={key} />
          </Link>
        );
      })}
    </Selector>
  );
};

export default LocaleSelector;
