import SubscriptionItem, { ModelType } from "./model";

// Utils
import normalizedActions from "../utils/normalized/actions";

export function addSubscriptionItemFromNormalizedData(data, associations) {
  return (dispatch) => {
    let subscriptionItem = new SubscriptionItem();
    subscriptionItem.deserialize(data, associations);
    dispatch(addSubscriptionItem(subscriptionItem));
  };
}

export function addSubscriptionItem(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().subscriptionItems,
      ModelType,
      data,
      associations,
    );
  };
}
