// Action Types
export const UPDATE_BANNER = "UPDATE_BANNER";

export function setUtmCampaign(utmCampaign, utmContent) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_BANNER,
      payload: {
        utmCampaign,
        utmContent,
      },
    });
  };
}

export function setGiftCardCode(code) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_BANNER,
      payload: {
        giftCardCode: code,
      },
    });
  };
}

export function updateBannerType(bannerType) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_BANNER,
      payload: {
        bannerType,
      },
    });
  };
}
