import { Component } from "react";
import styled from "styled-components";
import { Color, rem } from "../../utils/style";
import PulseIcon from "../animation/PulseIcon";

const PulseIconWrapper = styled.div`
  padding: 12px 16px;
  background-color: ${Color.ritualLightYellow};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 27px;
  position: relative;
  width: 100%;
  justify-content: center;

  span {
    font-weight: 300;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-style: normal;
    color: ${Color.ritualBlue};
  }
`;

export default class CartBanner extends Component {
  render() {
    const { bannerMessage, className } = this.props;

    return (
      <PulseIconWrapper className={className}>
        <PulseIcon />
        <span>{bannerMessage}</span>
      </PulseIconWrapper>
    );
  }
}
