import { Component } from "react";
import styled from "styled-components";
import MagicLink from "../../MagicLink";

// Utils
import { CartIcons } from "../../../utils/react-svg";
import { rem, responsive } from "../../../utils/style";

// Store
import { connect } from "react-redux";
import cartProductSelectors from "../../../store/cart-product/selectors";

const QuantityIcon = styled.span`
  width: 9px;
  margin-left: 4px;
  user-select: none;

  font-weight: 500;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
`;

const CartLink = styled(MagicLink)`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    height: 20px;
    width: 20px;

    ${responsive.md`
      height: 24px;
      width: 24px;
    `}
  }
`;

export class CartIconLink extends Component {
  getCartLabelText() {
    const { cartQuantity } = this.props;
    if (!cartQuantity) {
      return `Cart`;
    } else if (cartQuantity === 1) {
      return `Cart - 1 Item`;
    } else {
      return `Cart - ${cartQuantity} Items`;
    }
  }

  render() {
    let { cartQuantity, className, onClick = null } = this.props;
    const cartLabelText = this.getCartLabelText();
    return (
      <CartLink
        id="cart_nav-icon"
        to="/cart"
        ariaLabel={cartLabelText}
        target="_self"
        onClick={onClick}
      >
        <CartIcons.CartBagEmpty className={className} />
        {cartQuantity > 0 && <QuantityIcon>{cartQuantity}</QuantityIcon>}
      </CartLink>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
  };
};

export default connect(mapStateToProps, {})(CartIconLink);
