import PromotionIntent, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addPromotionIntent(data) {
  const promotionIntent = new PromotionIntent();
  promotionIntent.deserialize(data);

  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().promotionIntents,
      ModelType,
      promotionIntent,
    );
  };
}
