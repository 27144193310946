import { useStaticQuery, graphql } from "gatsby";
import { useDispatch } from "react-redux";
import intl from "../../services/intl";
import { initializeTalkable } from "../../utils/talkable";

// Actions
import {
  setLocale,
  setActiveLocales,
  setStores,
} from "../../store/i18n/actions";

export default (props) => {
  let { locale, activeLocales, children } = props;

  const storesData = useStaticQuery(graphql`
    query NonPageQuery {
      allRitualStore {
        nodes {
          id: store_id
          store_id
          countries
          maxOrderItems: max_order_items
        }
      }
    }
  `);

  const stores = storesData.allRitualStore.nodes;

  if (!locale) locale = intl.defaultLocale.locale;
  if (!activeLocales) activeLocales = [intl.defaultLocale.locale];
  if (!stores) throw new Error("Stores are required but no data exists!");

  const dispatch = useDispatch();
  dispatch(setStores(stores));
  dispatch(setActiveLocales(activeLocales));
  dispatch(setLocale(locale));

  intl.updateFormatter(locale);
  intl.persistLocale(locale);
  intl.detectLocale();
  initializeTalkable(locale);

  return <>{children}</>;
};
