import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "PRODUCT-GROUP";

export default class ProductGroup extends Model {
  // Attributes
  name: string = "";
  defaultProductId: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.defaultProductId = this.getRelationshipId(
      relationships,
      "default_product",
    );
  }
}
