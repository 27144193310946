import { combineReducers } from "redux";
import normalizedReducers from "../utils/normalized/reducers";
import { ModelType } from "./model";

function meta(state = {}, action) {
  return normalizedReducers.metaReducer(ModelType, state, action);
}

function byId(state = {}, action) {
  return normalizedReducers.byIdReducer(ModelType, state, action);
}

function allIds(state = [], action) {
  return normalizedReducers.allIdsReducer(ModelType, state, action);
}

export default combineReducers({ meta, byId, allIds });
