import { createSelector } from "reselect";

const _meta = (state) => state.carts.meta;
const _byId = (state) => state.carts.byId;
const _allIds = (state) => state.carts.allIds;

const activeCart = createSelector(
  _meta,
  _byId,
  (meta, byId) => byId[meta.activeId],
);

const isProcessing = createSelector(_meta, (meta) => meta.processing);
const showLimitBanner = createSelector(_meta, (meta) => meta.showLimitBanner);
export const showProductOfferLimitBanner = createSelector(_meta, (meta) => meta.showProductOfferLimitBanner);
const showWipeoutBanner = createSelector(
  _meta,
  (meta) => meta.showWipeoutBanner,
);
const showFlyoutCart = createSelector(_meta, (meta) => meta.showFlyoutCart);
const fetchFailed = createSelector(_meta, (meta) => meta.fetchFailed);
const queue = createSelector(_meta, (meta) => meta.queue || []);

export default {
  activeCart,
  isProcessing,
  showLimitBanner,
  showWipeoutBanner,
  showFlyoutCart,
  fetchFailed,
  queue,
};
