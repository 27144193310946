import PropTypes from "prop-types";

export function MagicSVGImage(props) {
  return <img alt={props.alt || "Icon"} loading="lazy" {...props} />;
}

MagicSVGImage.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};
