export default function reducer(state = {}, action) {
  switch (action.type) {
    case "APP_LOADED":
      return {
        ...state,
        appLoaded: true,
      };
    case "AUTH_INITIALIZED":
      return {
        ...state,
        authInitialized: true,
      };
    case "FETCH_AFTER_AUTH_START":
      return {
        ...state,
        fetchingDataAfterAuth: true,
      };
    case "FETCH_AFTER_AUTH_SUCCESS":
      return {
        ...state,
        fetchingDataAfterAuth: false,
      };
    case "FETCH_AFTER_AUTH_FAILURE":
      return {
        ...state,
        fetchingDataAfterAuth: false,
      };
    default:
      return state;
  }
}
