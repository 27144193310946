import Bugsnag from "@bugsnag/js";
import { core, usStreet, internationalStreet } from "smartystreets-javascript-sdk";

const US_COUNTRY_CODE = "US";

// https://www.smarty.com/docs/cloud/licensing
const US_CORE_CLOUD = "us-core-cloud";
const INTERNATIONAL_GLOBAL_PLUS_CLOUD = "international-global-plus-cloud";

// https://www.smarty.com/docs/cloud/us-street-api#http-response-status
const US_DPV_MATCH_CODES = /Y|S/;
// https://www.smarty.com/docs/cloud/international-street-api#http-response-status
const INTERNATIONAL_VERIFIED_STATUS = "Verified";

// https://docs.bugsnag.com/platforms/javascript/#breadcrumbs
const BUGSNAG_INVALID_SHIPPING_ADDRESS = "Invalid shipping address";
const BUGSNAG_VALIDATING_SHIPPING_ADDRESS = "Validating shipping address";
const BUGSNAG_NO_SHIPPING_MATCHES_FOUND = "No shipping matches found";
const BUGSNAG_VALID_ADDRESS_MATCH_FOUND = "Valid address match found";

type LicenseType = "us-core-cloud" | "international-global-plus-cloud";
type Address = {
    line1: string;
    line2?: string;
    city: string;
    state: string;
    postal_code: string;
    country: string;
};

class Smarty {
    private key: string;
    private credentials: core.SharedCredentials;
    private clientBuilder: core.ClientBuilder<core.Client<any, any>, any>;
    private client: any;

    constructor() {
        this.key = process.env.GATSBY_SMARTY_EMBEDDED_KEY || '';
        this.credentials = new core.SharedCredentials(this.key);
        this.clientBuilder = new core.ClientBuilder(this.credentials);
    }

    private setupClient(address: Address) {
        if (address.country === US_COUNTRY_CODE) {
            this.clientBuilder.withLicenses([US_CORE_CLOUD]);
            this.client = this.clientBuilder.buildUsStreetApiClient();
        } else {
            this.clientBuilder.withLicenses([INTERNATIONAL_GLOBAL_PLUS_CLOUD]);
            this.client = this.clientBuilder.buildInternationalStreetClient();
        }
    }

    private async lookupInternationalAddress(address: Address): Promise<any> {
        try {
            let lookup = new internationalStreet.Lookup(address.country, `${address.line1} ${address.line2 || ""} ${address.city} ${address.state} ${address.postal_code}`);
            
            lookup.geocode = "false";
            lookup.address1 = address.line1;
            lookup.address2 = address.line2 || "";
            lookup.locality = address.city;
            lookup.administrativeArea = address.state;
            lookup.postalCode = address.postal_code;
            lookup.country = address.country;

            return this.client.send(lookup);
        } catch (error) {
            Bugsnag.notify(error as Error);
            return [];
        }
    }

    private async lookupUSAddress(address: Address): Promise<any> {
        try {
            let lookup = new usStreet.Lookup();
            
            lookup.street = address.line1;
            lookup.street2 = address.line2 || "";
            lookup.city = address.city;
            lookup.state = address.state;
            lookup.zipCode = address.postal_code;
            lookup.maxCandidates = 1;

            return this.client.send(lookup);
        } catch (error) {
            Bugsnag.notify(error as Error);
            return [];
        }
    }

    async validateAddress(address: Address): Promise<Address | null> {
        Bugsnag.leaveBreadcrumb(BUGSNAG_VALIDATING_SHIPPING_ADDRESS);

        this.setupClient(address);

        let validatedAddress;

        if (address.country === US_COUNTRY_CODE) {
            let response = await this.lookupUSAddress(address);

            let lookup = response.lookups[0];

            // if response array is empty, address is invalid and we should return
            if (!lookup || !lookup.result[0]) {
                Bugsnag.leaveBreadcrumb(BUGSNAG_NO_SHIPPING_MATCHES_FOUND);
                return null;
            }
            let result = lookup.result[0];

            if (!result.analysis.dpvMatchCode.match(US_DPV_MATCH_CODES)) {
                Bugsnag.leaveBreadcrumb(BUGSNAG_INVALID_SHIPPING_ADDRESS);
                return null;
            }
            validatedAddress = {
                line1: result.deliveryLine1,
                line2: result.deliveryLine2,
                city: result.components.cityName,
                state: result.components.state,
                postal_code: result.components.zipCode,
                country: address.country,
            };
        } else {
            let response = await this.lookupInternationalAddress(address);
            let result = response.result[0];

            // if response array is empty, address is invalid and we should return
            if (!result) {
                Bugsnag.leaveBreadcrumb(BUGSNAG_NO_SHIPPING_MATCHES_FOUND);
                return null;
            }

            if (result.analysis.verificationStatus !== INTERNATIONAL_VERIFIED_STATUS) {
                Bugsnag.leaveBreadcrumb(BUGSNAG_INVALID_SHIPPING_ADDRESS);
                return null;
            }

            validatedAddress = {
                line1: address.line1,
                line2: address.line2,
                city: result.components.locality,
                state: result.components.administrativeArea,
                postal_code: result.components.postalCode,
                country: address.country,
            };
        }

        Bugsnag.leaveBreadcrumb(BUGSNAG_VALID_ADDRESS_MATCH_FOUND);
        return validatedAddress;
    }
}

export default Smarty;