import Cookies from "js-cookie";

export function setCookie(name, value, options) {
  Cookies.set(name, value, _parseOptions(options));
}

export function getCookie(name) {
  return Cookies.get(name);
}

export function removeCookie(name, options) {
  Cookies.remove(name, _parseOptions(options));
}

function _parseOptions(options) {
  const parsedOptions = { ...options };

  // If we are on a different host than the provided COOKIE_DOMAIN, write the
  // cookie to the host instead. This allows us to set a cookie on Cloudflare Pages
  // deploy previews.
  if (options.domain) {
    parsedOptions.domain = cookieDomain(options.domain);
  }
  return parsedOptions;
}

export function cookieDomain(domain) {
  if (typeof window === "undefined") {
    return domain;
  }

  return window.location.host.includes(domain) ? domain : window.location.host;
}
