import queryString from "query-string";

// Constants
import { ActionType } from "../../constants/product";

// Hooks
import useHasMounted from "../useHasMounted";
import useVariation from "../useVariation";

// Selectors
import { useSelector } from "react-redux";
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";
import userSelectors from "../../store/user/selectors";

function useProspectOnlyEligibility() {
  const isUserAProspect = useSelector(userSelectors.isProspect);
  const prospectOnlyEnabled = useVariation("bundle-promo-prospect-only");

  return !prospectOnlyEnabled || isUserAProspect;
}

const useBundlePromotion = (actionType, quantity, options = {}) => {
  const promotion = useSelector(promotionSelectors.bundlePromotion);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const hasActiveSubscription = useSelector(
    subscriptionSelectors.hasActiveSubscription,
  );
  const hasBundlePromoEligibleSubscription = useSelector(
    subscriptionSelectors.hasBundlePromoEligibleSubscription,
  );
  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCartProductQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );

  const prospectOnlyEligibility = useProspectOnlyEligibility();

  const hasMounted = useHasMounted();

  if (!hasMounted || !promotion) {
    return null;
  }

  const { ignoreEligibility = false } = options;

  if (ignoreEligibility) return promotion;

  if (!prospectOnlyEligibility) return null;

  // TODO: Move a global selector once the query params are stored in Redux.
  if (typeof window !== "undefined") {
    const urlSearchParams = queryString.parse(window.location.search);
    if (urlSearchParams.sbd === "1") {
      return promotion;
    }
  }

  if (actionType === ActionType.Checkout) {
    if (appliedPromotion === promotion) return promotion;

    // If the user does not have an applied promotion, but is an active
    // subscriber who is eligible for the bundle promotion, we can assume
    // they'll receive the promotion in checkout. However if they already have a
    // coupon attached to the cart, we should not promote the bundle promotion.
    return hasBundlePromoEligibleSubscription &&
      activeCartProductQuantity > 0 &&
      !activeCart?.discountCode
      ? promotion
      : null;
  }

  if (actionType === ActionType.Switch) {
    return null;
  }

  if (actionType === ActionType.AddToCart) {
    if (hasActiveSubscription) {
      if (!hasBundlePromoEligibleSubscription) {
        return null;
      }

      return activeCartProductQuantity === 0 ? promotion : null;
    }

    if (activeCartProductQuantity > 1) {
      return null;
    }

    return quantity + activeCartProductQuantity > 1 ? promotion : null;
  }

  if (actionType === ActionType.UpsellAdd) {
    return quantity === 1 && !appliedPromotion ? promotion : null;
  }

  return hasBundlePromoEligibleSubscription ? promotion : null;
};

export default useBundlePromotion;
