import { Component } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

// Services
import { navigate } from "../services/navigation";

// Utils
import { Color, rem, responsive, rgba } from "../utils/style";
import { Icons } from "../utils/react-svg";
import metrics from "../utils/metrics";

const Overlay = styled.div`
  position: fixed;
  height: auto;
  width: calc(100% - 16px);
  background: ${Color.ritualLightYellow};
  border-radius: 0px 8px 8px;
  margin: auto;
  bottom: 8px;
  right: 8px;
  z-index: 1000;

  &:hover {
    cursor: pointer;

    .link-arrow {
      background: ${Color.ritualBlue};

      svg {
        g {
          stroke: white;
        }
      }
    }
  }

  ${responsive.sm`
    max-width: 320px;
  `}

  &.white {
    background: ${Color.white};
    box-shadow: 0px 8px 40px -10px rgb(20 43 111 / 36%);
  }

  &.wide {
    ${responsive.sm`
      max-width: 400px;
    `}
  }
`;

const OverlayContainer = styled.div`
  position: relative;
  display: flex;
  padding: 16px;

  ${responsive.sm`
    padding: 24px;
  `}
`;

const CloseButton = styled.button`
  position: absolute;
  top: -16px;
  left: 0;
  height: 36px;
  width: 36px;
  background: ${Color.ritualLightYellow};
  padding: 0;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  div {
    position: relative;
    height: 12px;
    width: 12px;
  }

  &.white {
    background-color: ${Color.white};
  }

  svg {
    g {
      transition: all 0.2s ease;
    }
  }

  &:hover {
    svg {
      g {
        stroke: ${rgba(Color.ritualBlue, 0.16)};
      }
    }

    &.white {
      opacity: 1;
      svg {
        g {
          stroke: ${rgba(Color.ritualBlue, 1)};
        }
      }
    }

    & + div {
      .link-arrow {
        background: ${Color.ritualYellow};
        &.white {
          background: ${Color.ritualBlue};
        }

        svg {
          g {
            stroke: ${Color.ritualBlue};
          }
        }
      }
    }
  }
`;

const CloseIcon = styled(Icons.Close)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  g {
    stroke: ${rgba(Color.ritualBlue, 0.24)};
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 8px;

  h4 {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
  }

  p {
    font-size: ${rem(14)};
    font-weight: 300;
    line-height: ${rem(20)};
    margin: 0;
    padding: 0;
    max-width: 210px;

    ${responsive.sm`
      max-width: unset;
    `}
  }
`;

const LinkArrow = styled.button`
  height: 48px;
  width: 48px;
  margin-left: 8px;
  border: none;
  border-radius: 50%;
  background: ${Color.ritualYellow};
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.white {
    background: ${Color.ritualBlue};
    g {
      stroke: ${Color.white} !important;
    }
  }
`;

export default class OverlayLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      overlayClosed: false,
    };

    this.handleOverlayCloseClick = this.handleOverlayCloseClick.bind(this);
    this.handleOverlayLinkClick = this.handleOverlayLinkClick.bind(this);
  }

  handleOverlayLinkClick() {
    const { location, title, onClick } = this.props;
    if (onClick) return onClick();

    metrics.track("CTA Clicked", {
      location: location ? location : "Shop Landing Page Overlay",
      title: title ? title : "Build a Bundle",
    });

    const destination = this.props.linkDestination || "/build-a-bundle";
    navigate(destination);
  }

  handleOverlayCloseClick(e) {
    const { onCloseClick } = this.props;
    if (onCloseClick) onCloseClick(e);

    // dont propagate since close handler is inside navigate handler
    e.stopPropagation();
    this.setState({
      overlayClosed: true,
    });
  }

  render() {
    const { heading, subheading, theme, wide } = this.props;
    const { overlayClosed } = this.state;

    const isOverlayDisplayed = !overlayClosed;
    return (
      <>
        {isOverlayDisplayed && (
          <Overlay className={classNames(theme, wide && "wide")}>
            <OverlayContainer onClick={this.handleOverlayLinkClick}>
              <CloseButton
                className={theme}
                onClick={this.handleOverlayCloseClick}
              >
                <div>
                  <CloseIcon />
                </div>
              </CloseButton>

              <Body>
                <TextContainer>
                  <h4>{heading}</h4>
                  <p>{subheading}</p>
                </TextContainer>

                <LinkArrow className={classNames("link-arrow", theme)}>
                  <Icons.ArrowRoundedRight />
                </LinkArrow>
              </Body>
            </OverlayContainer>
          </Overlay>
        )}
      </>
    );
  }
}
