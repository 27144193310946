// Helpers
import { safeStringify } from "./helpers";

// Services
import intl from "../services/intl";
import siteStore from "../services/siteStore";

// Utils
import { setCookie } from "./cookies";
import { authAndFetchUser } from "./currentUser";
import fetch from "./fetch";

// Importing itself to give Jest the same reference for testing
import * as authenticateModule from "./authenticate";

export const SET_COOKIE =
  (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) !== "production";

export function createAccount(firstName, lastName, email, password) {
  const body = {
    first_name: firstName,
    last_name: lastName,
    email,
    password,
    locale: intl.locale,
    default_store_id: siteStore.storeId,
  };

  return fetch("users", {
    method: "POST",
    body: JSON.stringify(body),
    fetchOptions: {
      addCastleRequestToken: true,
    },
  }).then(() => {
    return authenticateModule.authenticate(email, password);
  });
}

export function authenticate(email, password, setCookie = SET_COOKIE) {
  return fetch("oauth/token", {
    method: "POST",
    credentials: "include",
    body: `grant_type=password&username=${encodeURIComponent(
      email,
    )}&password=${encodeURIComponent(password)}&scope=customer`,
    headers: {
      Accept: "application/json", // Guarantees returns JSON
      "Content-Type": "application/x-www-form-urlencoded",
    },
    fetchOptions: {
      addCastleRequestToken: true,
    },
  })
    .then((res) => {
      if (res.access_token) {
        // Handle auth token and set
        return authenticateModule.handleAuthenticationResponse(res, setCookie);
      } else {
        // eslint-disable-next-line no-throw-literal
        throw {
          error: "No access_token",
        };
      }
    })
    .catch((e) => {
      throw e;
    });
}

export async function handleAuthenticationResponse(
  data,
  setCookie = SET_COOKIE,
) {
  if (setCookie) {
    // Create new expiry date
    const now = new Date();
    const expiresAt = new Date(now.getTime() + data.expires_in * 1000);

    // Stores oauth response as cookie
    const cookieData = {
      data,
      expires: expiresAt,
    };

    authenticateModule.updateAuthenticationCookie(cookieData);
  }

  await authAndFetchUser();
}

// Creates authentication cookies read across codebases
export function updateAuthenticationCookie(data) {
  if (!data || !data.data) {
    return;
  }

  const {
    access_token,
    created_at,
    expires_in,
    refresh_token,
    token_type,
    scope,
  } = data.data;
  const expires_at = data.expires.getTime();

  const newCookie = safeStringify({
    authenticated: {
      access_token,
      authenticator: "authenticator:oauth2",
      created_at,
      expires_at,
      expires_in,
      refresh_token,
      token_type,
      scope,
    },
  });

  setCookie("ritual_auth-session", newCookie, {
    expires: 30, // expiration time in days
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });
}

export function getSupportMailTo(type, properties = {}) {
  const address = "my@ritual.com";
  const subject = encodeURIComponent(
    intl.t(
      "authenticate.support-email.subject",
      "Account has been flagged for suspicious activity",
    ),
  );
  const body = encodeURIComponent(
    intl.t(
      `authenticate.support-email.body.${type}`,
      "Hello,\n\nI’m requesting assistance as I received an error informing me that suspicious activity was detected when trying to sign-in.",
      properties,
    ),
  );

  return `mailto:${address}?subject=${subject}&body=${body}`;
}
