import ShippingOption, { ModelType } from "./model";
import normalizedActions from "../utils/normalized/actions";

export function addShippingOptionFromNormalizedData(data, associations) {
  return (dispatch) => {
    const shippingOption = new ShippingOption();
    shippingOption.deserialize(data);
    dispatch(addShippingOption(shippingOption, associations));
  };
}

export function addShippingOption(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().shippingOptions,
      ModelType,
      data,
      associations,
    );
  };
}
