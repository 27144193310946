import { useEffect, useMemo, useState } from "react";

export default function useIsInView(ref) {
  const observer = useMemo(() => {
    if (!window.IntersectionObserver) return null;
    return new window.IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting),
    );
  }, []);

  const [isIntersecting, setIsIntersecting] = useState(!observer);

  useEffect(() => {
    if (!observer) return;
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
