import { createSelector } from "reselect";
import cartSelectors from "../cart/selectors";

const _byId = (state) =>
  state.shippingOptions ? state.shippingOptions.byId : {};

const activeShippingOptions = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) return [];
    return activeCart.activeShippingOptionIds
      .map((id) => byId[id])
      .sort((a, b) => a.threshold - b.threshold)
      .filter(Boolean);
  },
);

const appliedShippingOption = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) return null;
    return byId[activeCart.shippingOptionId];
  },
);

export default {
  activeShippingOptions,
  appliedShippingOption,
};
