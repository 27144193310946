// Action Types
import {
  UPDATE_NAVIGATION,
  UPDATE_FOOTER_USP,
  UPDATE_NAVIGATION_PANELS,
} from "./actions";

const initialState = {
  scrolled: false,
  offset: 0,
  showFooterUSPNotice: false,
  panels: [null, null, null],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NAVIGATION:
      return {
        ...state,
        ...action.navigation,
      };
    case UPDATE_FOOTER_USP:
      return {
        ...state,
        showFooterUSPNotice: action.showFooterUSPNotice,
      };
    case UPDATE_NAVIGATION_PANELS:
      return {
        ...state,
        panels: action.payload.panels,
      };
    default:
      return state;
  }
}
