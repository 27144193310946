import { Component } from "react";
import styled from "styled-components";
import { rem, Color, Font, responsive, rgba } from "../../utils/style";

import MagicModal from "../MagicModal";
import RitualButton from "../global/RitualButton";
import Text from "../Text";

const ContentWrapper = styled.div`
  display; flex;
  flex-direction: column;
  box-sizing: content-box;

  ${responsive.sm`
    width: 340px;
    padding: 8px 20px;
  `}

  ${responsive.md`
    width: 454px;
    padding: 16px 0;
  `}

  ${responsive.lg`
    width: 570px;
  `}
`;

const ButtonWrapper = styled.div`
  &:first-of-type {
    margin-right: 0px;
    margin-bottom: 16px;
    ${responsive.sm`
      margin-right: 32px;
      margin-bottom: 0px;
    `}
  }

  width: 100%;
  ${responsive.sm`
    width: 148px;
  `}

  ${responsive.md`
    width: 184px;
  `}
`;

const Title = styled.div`
  h1 {
    margin: 0;
    font-weight: 500;

    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: ${rem(-0.2)};

    ${responsive.md`
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: ${rem(-0.4)};
    `}
  }

  padding-bottom: 24px;
  ${responsive.md`
    padding-bottom: 40px;
  `}

  border-bottom: 2px solid ${Color.ritualBlue};
`;

const Message = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }

  margin-top: 16px;
  ${responsive.md`
    margin-top: 24px;
  `}
  margin-bottom: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${responsive.sm`
    flex-direction: row;
  `}
`;

export default class RemoveItemModal extends Component {
  render() {
    const { isOpen, onRequestClose, onRemove } = this.props;
    return (
      <MagicModal
        isOpen={isOpen}
        contentClass={"top-margin-none"}
        onRequestClose={onRequestClose}
        disableScrollLock={true}
      >
        <ContentWrapper>
          <Title>
            <h1>
              <Text
                id="cart.remove-modal.title"
                defaultMessage="Remove from cart?"
              />
            </h1>
          </Title>
          <Message>
            <p>
              <Text
                id="cart.remove-modal.message"
                defaultMessage="Are you sure you want to let this item go?"
              />
            </p>
          </Message>
          <ButtonContainer>
            <ButtonWrapper>
              <RitualButton
                onClick={onRequestClose}
                className="fullwidth bordered"
                isLink={false}
              >
                <Text
                  id="cart.remove-modal.button-nevermind"
                  defaultMessage="Nevermind"
                />
              </RitualButton>
            </ButtonWrapper>
            <ButtonWrapper>
              <RitualButton
                onClick={onRemove}
                className="fullwidth"
                isLink={false}
              >
                <Text
                  id="cart.remove-modal.button-remove"
                  defaultMessage="Remove"
                />
              </RitualButton>
            </ButtonWrapper>
          </ButtonContainer>
        </ContentWrapper>
      </MagicModal>
    );
  }
}
