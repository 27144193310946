import classNames from "classnames";
import styled from "styled-components";

// Components
import PulseIcon from "../animation/PulseIcon";

// Utils
import useBundlePromotion from "../../hooks/discount/useBundlePromotion";
import { ActionType } from "../../constants/product";
import { getPromotionDetails } from "../../utils/promotion";
import { Color, rem, responsive } from "../../utils/style";

const Pill = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px 5px 6px;
  height: 26px;
  background-color: ${Color.ritualLightYellow};
  border-radius: 28px;

  font-weight: 500;
  font-size: ${rem(10)};
  line-height: ${rem(14)};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-left: 12px;

  ${responsive.sm`
    height: 30px;
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    padding: 6px 12px 6px 6px;

    margin-left: 16px;
  `}

  &.small {
    font-size: ${rem(10)};
    line-height: ${rem(14)};
  }

  &.hidden {
    opacity: 0;
  }

  &.no-margin {
    margin: 0;

    ${responsive.sm`
      margin-left: 0;
    `};
  }
`;

export default function PulsePill(props) {
  const { actionType = ActionType.AddToCart, quantity = 2 } = props;
  const promotion = useBundlePromotion(actionType, quantity);

  if (!promotion) {
    return null;
  }

  const copy = getPromotionDetails(promotion).titleCopy;

  return (
    <Pill className={classNames(!copy && "hidden", props.className)}>
      <PulseIcon />
      {copy}
    </Pill>
  );
}
