import { memo } from "react";
import styled from "styled-components";

// Components
import MagicModal, { ModalStyle } from "../MagicModal";
import MagicFade, { FadeStyles } from "../MagicFade";
import PromoCode from "./PromoCode";
import FlyoutUpsell from "./FlyoutCartUpsell";
import FlyoutCartBottom from "./FlyoutCartBottom";
import FlyoutCartItemList from "./FlyoutCartItemList";
import CartTopBannerArea from "./CartTopBannerArea";
import Text from "../Text";

// Redux
import { useSelector } from "react-redux";
import cartSelectors from "../../store/cart/selectors";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import cartProductSelectors, {
  hasProductOfferInCart,
} from "../../store/cart-product/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";
import staticSelectors from "../../store/staticData/selectors";

// Services
import cartService from "../../services/cart";

// Utils
import { Font, rem, responsive, Opacity } from "../../utils/style";
import { Icons } from "../../utils/react-svg";
import { getBundleCartLimit } from "../../utils/bundle";

const PromoCodeWrapper = styled.div`
  margin: 25px 0 35px;
`;

const ModalContents = styled.section`
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ModalContentsInner = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  padding: 24px 24px 0 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow-x: hidden;

  ${responsive.sm`
    padding: 40px 40px 0 40px;
  `};

  ${FadeStyles}
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  svg {
    width: 6rem;
    margin-top: 60px;
    margin-bottom: 15px;
  }
`;

const CloseButtonWrapper = styled.div`
  background: #fff;
  border-radius: 50%;
  padding: 0;
  border: 0;
  margin-top: -10px;
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    position: relative;
    width: initial;
    height: initial;
  }

  &:hover svg {
    opacity: ${Opacity.light};
  }
`;

const ModalTitle = styled.h1`
  font-family: ${Font.circular};
  font-size: ${rem(22)};
  letter-spacing: ${rem(0.2)};
  line-height: ${rem(25)};
  margin: 0 0 14px;
`;

const BottomNav = styled.div`
  width: 100%;
  flex-shrink: 0;

  padding: 0 24px;

  ${responsive.sm`
    padding: 0 40px;
  `};
`;

const CartItemList = memo(FlyoutCartItemList);
const Upsell = memo(FlyoutUpsell);
const BannerArea = memo(CartTopBannerArea);

function LoadingComponent() {
  return (
    <Loading>
      <Icons.Loading />
      <Text id="cart.flyout.loading" defaultMessage="Loading..." />
    </Loading>
  );
}

export default function FlyoutCart(props) {
  const isOpen = useSelector(cartSelectors.showFlyoutCart);
  const activeCart = useSelector(cartSelectors.activeCart);
  const activeCoupon = useSelector(couponSelectors.activeCoupon);
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const hasActiveSubscription = useSelector(
    subscriptionSelectors.hasActiveSubscription,
  );
  const activeCartProducts = useSelector(
    cartProductSelectors.sortedActiveCartProducts,
  );
  const cartQuantity = useSelector(
    cartProductSelectors.activeCartProductQuantity,
  );
  const contentfulProducts = useSelector(staticSelectors.contentfulProducts);
  const isProcessing = useSelector(cartSelectors.isProcessing);
  const productOfferInCart = useSelector(hasProductOfferInCart);

  const isAddingToSubscription =
    hasActiveSubscription && cartQuantity !== getBundleCartLimit();

  const upsellCartAmount = 2;
  const shouldShowUpsell =
    activeCart &&
    !isAddingToSubscription &&
    cartQuantity <= upsellCartAmount &&
    !productOfferInCart;

  function onRequestClose(toggle) {
    if (!toggle) cartService.closeCart();
    if (props.onRequestClose) props.onRequestClose();
  }

  const shouldShowPromoCodeComponent =
    !isAddingToSubscription && !!cartQuantity && !productOfferInCart;

  return (
    <MagicModal
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
      slideOut={true}
      contentStyle={ModalStyle}
      closeButtonContentOverride={
        <CloseButtonWrapper>
          <Icons.Close />
        </CloseButtonWrapper>
      }
    >
      <ModalContents data-body-scroll-lock-ignore={true}>
        <ModalContentsInner timeout={500} transitionKey={!!activeCart}>
          <div>
            <ModalTitle>
              {isAddingToSubscription && !!cartQuantity ? (
                <Text
                  id="cart.review-order.heading-is-adding"
                  defaultMessage="Adding to your subscription"
                />
              ) : (
                <Text id="cart.flyout.heading" defaultMessage="Your Cart" />
              )}
            </ModalTitle>
            <MagicFade transitionKey={!!activeCart}>
              {!activeCart ? (
                <LoadingComponent />
              ) : (
                <>
                  {!!cartQuantity && <BannerArea className={"compact"} />}
                  <CartItemList
                    data-body-scroll-lock-ignore={true}
                    cartProducts={activeCartProducts}
                    contentfulProducts={contentfulProducts}
                    isProcessing={isProcessing}
                    cartQuantity={cartQuantity}
                    isFlyout={true}
                  />
                  {shouldShowPromoCodeComponent && (
                    <PromoCodeWrapper>
                      <PromoCode isProcessing={isProcessing} />
                    </PromoCodeWrapper>
                  )}
                </>
              )}
            </MagicFade>
          </div>
          <MagicFade transitionKey={!!activeCart}>
            <>
              {shouldShowUpsell && (
                <Upsell
                  upsellSkus={activeCart.productRecommendations}
                  isProcessing={isProcessing}
                  cartQuantity={cartQuantity}
                  contentfulProducts={contentfulProducts}
                />
              )}
            </>
          </MagicFade>
        </ModalContentsInner>
        <BottomNav>
          <FlyoutCartBottom
            activeCart={activeCart}
            isAddingToSubscription={isAddingToSubscription}
            isProcessing={isProcessing}
            cartQuantity={cartQuantity}
            cartProducts={activeCartProducts}
            activeCoupon={activeCoupon}
            appliedPromotion={appliedPromotion}
          />
        </BottomNav>
      </ModalContents>
    </MagicModal>
  );
}
