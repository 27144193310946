import { useRef } from "react";
import { useSelector } from "react-redux";
import cartSelectors from "../store/cart/selectors";
import cartProductSelectors from "../store/cart-product/selectors";
import subscriptionSelectors from "../store/subscription/selectors";
import shippingOptionSelectors from "../store/shipping-option/selectors";
import intl from "../services/intl";

export const useShipping = () => {
  const hasAvailableSubscription = useSelector(
    subscriptionSelectors.hasActiveSubscription,
  );
  const appliedShippingOption = useSelector(
    shippingOptionSelectors.appliedShippingOption,
  );
  const activeShippingOptions = useSelector(
    shippingOptionSelectors.activeShippingOptions,
  );
  const activeCartProductTotal = useSelector(
    cartProductSelectors.activeCartProductTotal,
  );
  const activeCartOneTimeOnly = useSelector(
    cartProductSelectors.activeCartOneTimeOnly,
  );
  const isProcessing = useSelector(cartSelectors.isProcessing);

  const shippingData = useRef();

  function getShippingData() {
    const FreeForSubscribers = intl.t(
      "shipping.free-for-subscribers",
      "Shipping is free for subscribers!",
    );
    const OnUs = intl.t("shipping.on-us", "Shipping is on us!");

    if (hasAvailableSubscription) {
      return {
        copy: FreeForSubscribers,
        percent: 100,
        shippingAmount: 0,
      };
    }

    const freeShippingOption = activeShippingOptions.find(
      (option) => option.amount === 0,
    );

    // If there's no free shipping option we know that either the active
    // shipping options have not yet loaded, or there are no active shipping
    // options being sent down to the client.
    if (!freeShippingOption) {
      return null;
    }

    // Currently the only case where a shipping option would not be applied
    // is if the cart is empty.
    if (!activeCartProductTotal || !appliedShippingOption) {
      return {
        copy: FreeForSubscribers,
        percent: 0,
        shippingAmount: 0,
      };
    }

    const { threshold } = freeShippingOption;
    const remaining = Math.max(threshold - activeCartProductTotal, 0);
    const percent = threshold <= 0 ? 100 : 100 - (remaining / threshold) * 100;

    if (freeShippingOption.eligible || !remaining) {
      return {
        copy: activeCartOneTimeOnly ? OnUs : FreeForSubscribers,
        percent: 100,
        shippingAmount: 0,
      };
    }

    return {
      copy: intl.t(
        "shipping.amount-remaining",
        "You're {amount} away from free shipping!",
        { amount: intl.formatCurrency(remaining / 100, { round: true }) },
      ),
      percent,
      shippingAmount: appliedShippingOption.amount,
    };
  }

  // Don't update the shipping data until the cart has finished processing.
  // This prevents the shipping threshold from flickering when products are
  // added and removed.
  if (!isProcessing || !shippingData.current) {
    shippingData.current = getShippingData();
  }

  return shippingData.current;
};
