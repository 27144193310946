import sumBy from "lodash/sumBy";
import uniqBy from "lodash/uniqBy";
import { getPlanForProductSku, getProductForSku } from "./planToProduct";

// Services
import intl from "../services/intl";
import siteStore from "../services/siteStore";

export function getBundleTotal(bundle) {
  const plans = bundle.products.map((product) =>
    getPlanForProductSku(product.sku),
  );

  return sumBy(plans, (p) => (p && p.amount) || 0) / 100;
}

export function getBundleCartLimit() {
  return siteStore.maxOrderItems;
}

export function getPlanIdAndQuantityArray(planIds) {
  const planToQuantityMap = {};
  planIds.forEach((planId) => {
    if (!planToQuantityMap[planId]) {
      planToQuantityMap[planId] = 0;
    }
    planToQuantityMap[planId]++;
  });

  return Object.keys(planToQuantityMap).map((planId) => {
    return {
      planId,
      quantity: planToQuantityMap[planId],
    };
  });
}

/**
 * Returns the correct banner copy depending on whether the
 * bundle is multi-item and has discount for amount off
 * @param {Boolean} multiItem Is this multi-item?
 * @param {Object} discountData Discount Data object REQUIRED
 * @param {Object} promotion Promotion object
 * @returns {String} Translated string for banner
 */
export function getBannerCopy(multiItem, promotionDetails) {
  return multiItem
    ? promotionDetails.amountOff
      ? promotionDetailsAmountMessage(
          promotionDetails.promotion.name,
          promotionDetails.amountOff,
        )
      : intl.t(
          "build-bundle.slides.results.multi-bundle.unlock-percent",
          "You unlocked a {off}% discount",
          { off: promotionDetails.percentOff },
        )
    : intl.t(
        "build-bundle.slides.results.single-bundle.banner",
        "Add another to save {copy}",
        { copy: promotionDetails.formattedValue },
      );
}

/**
 * Returns a translated string detailing the contents
 * of an array of skus, aka a bundle
 * @param {Array} skuArray list of product skus
 * @returns {String} Translated string for bundles
 */
export function getBundleContents(skuArray) {
  if (!skuArray) return "";

  // Get all packaging types
  const productPackagingTypes = skuArray.map(
    (sku) => getProductForSku(sku).packagingType,
  );
  // Get all unique packaging types
  const uniqueTypes = uniqBy(productPackagingTypes, (type) => type);

  // Create items "1 Bottle" or "1 Bottle and 1 Bag" or "2 Bottles"
  const items = uniqueTypes
    .map((productPackagingType) => {
      // Count occurrences of packaging type
      const count = productPackagingTypes.filter(
        (type) => type === productPackagingType,
      ).length;
      // Get pluralized packaging type
      const translatedType = intl.t(
        `general.packaging-type.${productPackagingType}`,
        `bottle`,
        { count },
      );
      return `${count} ${translatedType}`;
    })
    .join(` ${intl.t("general.and", "and")} `);

  // Return "Includes X packagingTypes"
  return intl.t("general.includes", `Includes ${items}`, {
    items,
  });
}

export function getFeaturedBundleName(featuredBundle) {
  const { title, labels } = featuredBundle;
  let name = title;
  labels?.forEach((label) => {
    name += ` ${label}`;
  });

  return name;
}

export function getStylizedFeaturedBundleName(featuredBundle) {
  const { title, labels } = featuredBundle;

  return (
    <>
      {title}
      {labels.map((label) => (
        <em key={`styled-${label}`}>
          {` `}
          {label}
        </em>
      ))}
    </>
  );
}

function promotionDetailsAmountMessage(promotionName, amountOff) {
  return promotionName === "bundle"
    ? intl.t(
        "build-bundle.slides.results.multi-bundle.unlock-initial-promo",
        "Bundle and save {off} on your first order",
        {
          off: intl.formatCurrency(amountOff, {
            round: true,
          }),
        },
      )
    : intl.t(
        "build-bundle.slides.results.multi-bundle.unlock",
        "You unlocked a {off} discount",
        {
          off: intl.formatCurrency(amountOff, {
            round: true,
          }),
        },
      );
}
