import { Component } from "react";
import styled from "styled-components";
import { RitualLogos } from "../../utils/react-svg";
import MagicLink from "../MagicLink";

const RitualLogo = styled(MagicLink)``;

export default class CloseButton extends Component {
  render() {
    let { handleNavigateClick, title = "Ritual", to = "/" } = this.props;
    return (
      <RitualLogo to={to} title={title} onClick={handleNavigateClick}>
        <RitualLogos.RitualLogo />
      </RitualLogo>
    );
  }
}
