import styled, { css } from "styled-components";

// Utils
import { media } from "../utils/style";

const Container = styled.section.attrs({ className: "container" })`
  ${(p) =>
    p.padding20 &&
    css`
      ${media.mobile`
        padding: 0 20px;
      `};
    `}

  ${(p) =>
    p.allowOverflow &&
    css`
      overflow: visible !important;
    `}
`;

export default Container;
