import metrics from "./metrics";
import locales from "../../config/locales";

export function setupTalkableHooks(talkableHooks) {
  for (let talkableHook of talkableHooks) {
    console.log("Talkable Hook setup: ", talkableHook);
    window.talkable.subscribe(talkableHook, "talkable-offer", () => {
      console.log("Talkable Hook fired: ", talkableHook);
      metrics.track("Talkable iFrame", {
        name: talkableHook,
      });
    });
  }
}

export function initializeTalkable(locale) {
  if (
    typeof window !== "undefined" &&
    typeof window._talkableq !== "undefined"
  ) {
    const activeEnv = process.env.GATSBY_ACTIVE_ENV || "development";
    const rule = activeEnv === "production" ? "talkable" : "talkable-staging";
    const siteId = locales[locale][rule];
    window._talkableq.push(["init", { site_id: siteId }]);
  }
}
