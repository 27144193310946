import * as LDClient from "launchdarkly-js-client-sdk";

const evaluatedSessionFlags: {
  [path: string]: { [flagKey: string]: unknown };
} = {};

export const fsInspector: LDClient.LDInspection = {
  type: "flag-used",
  name: "fullstory-inspector",
  method: (key: string, detail: LDClient.LDEvaluationDetail) => {
    // FullStory attaches getCurrentSessionURL and setVars to the window object.
    // https://help.fullstory.com/hc/en-us/articles/1500004101581-FS-setVars-API-Sending-custom-page-data-to-FullStory
    if (
      (window as any)?.FS === undefined ||
      typeof (window as any)?.FS.getCurrentSessionURL !== "function"
    ) {
      return;
    }
    const url = (window as any)?.FS.getCurrentSessionURL(true);
    if (url === undefined) {
      return;
    }
    evaluatedSessionFlags[url] = {
      ...evaluatedSessionFlags[url],
      [key]: detail.value,
    };
    const arrayOfFlags = Object.keys(evaluatedSessionFlags[url]).map(
      (flagKey) =>
        `${flagKey}=${JSON.stringify(
          evaluatedSessionFlags[url][flagKey],
          null,
          1,
        )}`,
    );
    if (typeof (window as any)?.FS.setVars === "function") {
      (window as any)?.FS.setVars(
        "page",
        { ldflags: arrayOfFlags },
        { integration: "launchdarkly" },
      );
    }
  },
};
