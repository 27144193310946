export const lux = {
  initialize() {
    this._setInitialLuxData();
  },

  onPreRouteUpdate(prevLocation) {
    // Init should only be called if we're transitioning routes and not on
    // initial load. We determine if we're in a transition by checking the
    // previous location.
    if (prevLocation) {
      window.LUX && window.LUX.init();
    }
  },

  onRouteUpdate(prevLocation, label) {
    if (window.LUX) {
      window.LUX.label = label;
      // Send should only be called if we're transitioning routes and not on
      // initial load. We determine if we're in a transition by checking the
      // previous location.
      prevLocation && window.LUX.send();
    }
  },

  _setInitialLuxData() {
    if (!window.LUX) return;

    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get("utm_source");
    const referrer = document.referrer;

    if (referrer) {
      window.LUX.addData("referrer", referrer);
    }

    if (utmSource) {
      window.LUX.addData("utm_source", utmSource);
    }
  },

  setLuxDataFromUser(userInfo) {
    if (!window.analytics || !window.LUX) return;
    window.LUX.addData("user_id", userInfo.id);
    window.LUX.addData("anonymous_id", userInfo.anonymousId);
  },
};
