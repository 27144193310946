// Redux
import { getStore } from "../store/createStore";
import i18nSelectors from "../store/i18n/selectors";

// Config
import siteStores from "../../config/siteStores";

// Services
import { get } from "lodash";

class SiteStoreService {
  get stores() {
    return i18nSelectors.stores(getStore().getState());
  }

  get storeCode() {
    return i18nSelectors.storeCode(getStore().getState());
  }

  get activeStore() {
    return i18nSelectors.activeStore(getStore().getState()) || {};
  }

  get maxOrderItems() {
    return i18nSelectors.maxOrderItems(getStore().getState());
  }

  get storeId() {
    return this.activeStore.id;
  }

  getConfig(rule, defaultValue) {
    const value = get(
      siteStores,
      [this.storeCode, ...rule.split(".")],
      defaultValue,
    );

    return value;
  }
}

const siteStoreService = new SiteStoreService();
export default siteStoreService;
export { SiteStoreService, siteStores };
