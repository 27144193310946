import queryString from "query-string";

import { ROUTE_UPDATE } from "./actions";

const initialState = {
  stickySearchParams: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ROUTE_UPDATE: {
      const search = queryString.parse(action.payload.location.search);
      return {
        ...state,
        stickySearchParams: {
          ...state.stickySearchParams,
          ...search,
        },
      };
    }
    default:
      return state;
  }
}
