export const IsStorageSupported = supportsLocalStorage();

export function safeParse(json, fallback = null) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return fallback;
  }
}

export function safeStringify(json) {
  try {
    return JSON.stringify(json);
  } catch (e) {
    return null;
  }
}

export function supportsLocalStorage() {
  const val = "ritual-ils";
  try {
    window.localStorage.setItem(val, val);
    window.localStorage.getItem(val);
    window.localStorage.removeItem(val);
    return true;
  } catch (e) {
    return false;
  }
}

export const emailRegex =
  /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
