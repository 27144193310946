import { css } from "styled-components";
import { Font, responsive } from "./style";

// Colors
export const Color = {
  // Primary Palette
  indigoBlue: "#142B6F",
  indigoBlue80: "#43558C",
  indigoBlue60: "#62719F",
  indigoBlue40: "#A1AAC5",
  indigoBlue20: "#D0D5E2",

  yellowOchre: "#FFD600",
  yellowOchre80: "#FFDE33",
  yellowOchre60: "#FFE666",
  yellowOchre40: "#FFEF99",
  yellowOchre20: "#FFF7CC",

  // Secondary Palette
  darkSkyBlue: "#96BACC",
  darkSkyBlue80: "#ABC8D6",
  darkSkyBlue60: "#C0D6E0",
  darkSkyBlue40: "#D5E3EB",
  darkSkyBlue20: "#EAF1F5",

  electricBlue: "#0B38BD",
  electricBlue80: "#3C60CA",
  electricBlue60: "#6D88D7",
  electricBlue40: "#9DAFE5",
  electricBlue20: "#CED7F2",

  // Neutral Palette
  cool: "#C3CFD5",
  cool60: "#DBE2E6",
  cool40: "#EAEEF0",
  cool20: "#F5F7F8",

  white: "#FFFFFF",

  warm: "#E4C25E",
  warm60: "#F0DBA8",
  warm40: "#F6EACB",
  warm20: "#FCF8EE",

  // Semantic Palette
  errorRed: "#C83D1E",
  warningOrange: "#DB7F16",
  successGreen: "#4C840D",
};

// Typography Styles
export const Typography = {
  display: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 1.25em;
    letter-spacing: -2%;

    ${responsive.md`
      font-size: 4.125rem;
      line-height: 1.1em;
    `};
  `,
  headline1: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 2rem;
    line-height: 1.25em;
    letter-spacing: -1%;

    ${responsive.md`
      font-size: 3rem;
      line-height: 1.2em;
      letter-spacing: -2%;
    `};
  `,
  headline2: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.25em;
    letter-spacing: -1%;

    ${responsive.md`
      font-size: 2.5rem;
      letter-spacing: -2%;
    `};
  `,
  lead1: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.25em;
    letter-spacing: -1%;

    ${responsive.md`
      font-size: 2rem;
    `};
  `,
  lead2: css`
    ${Font.circular}
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.25em;
    letter-spacing: 0%;

    ${responsive.md`
      font-size: 1.5rem;
      letter-spacing: -1%;
    `};
  `,
  body1: css`
    ${Font.dutch}
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5em;
    letter-spacing: 0%;

    ${responsive.md`
      font-size: 1.125rem;
    `};
  `,
  body2: css`
    ${Font.dutch}
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.5em;
    letter-spacing: 0%;

    ${responsive.md`
      font-size: 1rem;
    `};
  `,
  body3: css`
    ${Font.dutch}
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.5em;
    letter-spacing: 0%;
  `,
  label1: css`
    ${Font.circular}
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0%;

    ${responsive.md`
      font-size: 1.25rem;
    `};
  `,
  label2: css`
    ${Font.circular}
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0%;

    ${responsive.md`
      font-size: 1rem;
    `};
  `,
  // same on mobile & desktop
  label3: css`
    ${Font.circular}
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: 0%;
  `,
  // same on mobile & desktop
  label4: css`
    ${Font.circular}
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.5em;
    letter-spacing: 0%;
  `,
  eyebrow1: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5em;
    letter-spacing: 8%;
    text-transform: uppercase;

    ${responsive.md`
      font-size: 1rem;
    `};
  `,
  // same on mobile & desktop
  eyebrow2: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5em;
    letter-spacing: 8%;
    text-transform: uppercase;
  `,
  // same on mobile & desktop
  caption: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5em;
    letter-spacing: 0%;
  `,
  badge: css`
    ${Font.circular}
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.5em;
    letter-spacing: 8%;

    ${responsive.md`
      font-size: 0.75rem;
    `};
  `,
};
