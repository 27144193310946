import { createSelector } from "reselect";
import locales from "../../../config/locales";

const i18n = (state) => state.i18n;

const locale = createSelector(i18n, (i18n) => locales[i18n.locale].locale);
const country = createSelector(i18n, (i18n) => locales[i18n.locale].country);
const siteLang = createSelector(i18n, (i18n) => locales[i18n.locale].siteLang);
const ogLocale = createSelector(i18n, (i18n) => locales[i18n.locale].ogLocale);
const currency = createSelector(i18n, (i18n) => locales[i18n.locale].currency);
const path = createSelector(i18n, (i18n) => locales[i18n.locale].path);
const payments = createSelector(i18n, (i18n) => locales[i18n.locale].payments);
const activeLocales = createSelector(i18n, (i18n) => i18n.activeLocales);
const localeSuggestion = createSelector(i18n, (i18n) => i18n.localeSuggestion);

const stores = createSelector(i18n, (i18n) => i18n.stores);
const storeCode = createSelector(
  i18n,
  (i18n) => locales[i18n.locale].storeCode,
);

const activeStore = createSelector(stores, storeCode, (stores, storeCode) =>
  stores.find((store) => store.countries.includes(storeCode)),
);

const FallbackLimit = 6;
const maxOrderItems = createSelector(
  activeStore,
  (activeStore) => activeStore?.maxOrderItems || FallbackLimit,
);

export default {
  locale,
  country,
  siteLang,
  ogLocale,
  currency,
  path,
  payments,
  activeLocales,
  localeSuggestion,
  stores,
  storeCode,
  activeStore,
  maxOrderItems,
};
