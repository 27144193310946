import { globalHistory } from "@gatsbyjs/reach-router";
import {
  updateFlyoutCart,
  updateLimitBanner,
  updateProductOfferLimitBanner,
  updateReplaceBanner,
  updateWipeoutBanner,
} from "../store/cart/actions";
import { getStore } from "../store/createStore";
import { trackCartViewed } from "../utils/tracking/cart";

class CartService {
  store;

  constructor(store) {
    this.store = store || getStore();
    this.subscribeToRouteChanges();
  }

  subscribeToRouteChanges() {
    globalHistory.listen(({ action, location }) => {
      if (action === "PUSH") this.closeCart();
    });
  }

  openCart(e) {
    this.store.dispatch(updateFlyoutCart(true));
    trackCartViewed("Flyout Cart Viewed");
    if (e) e.preventDefault();
  }

  async closeCart(e) {
    const dispatch = (f) => this.store.dispatch(f);
    dispatch(updateFlyoutCart(false));
    if (e) e.preventDefault();

    return new Promise((resolve) =>
      setTimeout(() => {
        dispatch(updateReplaceBanner(false));
        dispatch(updateLimitBanner(false));
        dispatch(updateWipeoutBanner(false));
        dispatch(updateProductOfferLimitBanner(false));
        resolve();
      }, 300),
    );
  }
}

const cartService = new CartService();
export default cartService;
export { CartService, cartService };
