import styled from "styled-components";
import { Color, Font, rem, link } from "../../../utils/style";

const TextNoticeWrapper = styled.p`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  font-weight: 500;
  margin: 0;
  color: ${Color.ritualBlue};

  a {
    ${link}
  }
`;

const TextNotice = (props) => <TextNoticeWrapper {...props} />;
export default TextNotice;
