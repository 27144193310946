import { v4 } from "uuid";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";
import cloneDeep from "lodash/cloneDeep";

export default class Model {
  key: string = "";
  id: string = "";

  constructor() {
    this.key = v4();
    this.id = `stub-${v4()}`;
  }

  addAttributes(attributes: any) {
    if (!attributes) return;
    Object.keys(attributes).forEach((key: string) => {
      const modelKey = camelCase(key);
      if (!this.hasOwnProperty(modelKey)) {
        return;
      }
      if (typeof attributes[key] === "undefined") {
        return;
      }
      Object.defineProperty(this, modelKey, { value: attributes[key] });
    });
  }

  getRelationshipId(relationships: any, name: string) {
    const data = this._getRelationshipData(relationships, name);
    return data ? data.id : "";
  }

  getRelationshipIds(relationships: any, name: string) {
    const data = this._getRelationshipData(relationships, name);
    return data ? data.map((data: any) => data.id) : [];
  }

  _getRelationshipData(relationships: any, name: string) {
    if (!relationships) {
      return undefined;
    }
    if (!relationships[name] || !relationships[name].data) {
      return undefined;
    }
    return relationships[name].data;
  }

  matchesAssociations(associations: { [key: string]: any }) {
    return Object.keys(associations).every((key: string) => {
      const value = this._getValue(key);
      const association = associations[key];
      if (typeof association === "function") {
        return association(value);
      } else {
        return value === association;
      }
    });
  }

  serializeInternal(attributes: { [key: string]: any }) {
    const serializedAttributes: { [key: string]: any } = {};
    Object.keys(attributes).forEach((key: string) => {
      if (!this.hasOwnProperty(key)) return;
      const serializedKey = snakeCase(key);
      serializedAttributes[serializedKey] = attributes[key];
    });

    return {
      data: {
        id: this._getValue("id"),
        attributes: serializedAttributes,
      },
    };
  }

  _getValue(key: string) {
    const descriptor = Object.getOwnPropertyDescriptor(this, key);
    if (!descriptor) return null;
    return descriptor.value;
  }

  cloneWithUpdates(attributes: { [key: string]: any }) {
    const clone = cloneDeep(this);
    clone.addAttributes(attributes);
    return clone;
  }
}
