function uniq(arr) {
  return arr.filter((el, index, self) => self.indexOf(el) === index);
}

function normalizeLocales(arr) {
  return arr.map((el) => {
    if (!el || el.indexOf("-") === -1 || el.toLowerCase() !== el) {
      return el;
    }

    const splitEl = el.split("-");
    return `${splitEl[0]}-${splitEl[1].toUpperCase()}`;
  });
}

export function getBrowserLocales() {
  let languageList = [];

  if (typeof window !== "undefined") {
    const { navigator } = window;
    languageList = [
      ...(navigator.languages || []),
      navigator.language,
      navigator.userLanguage,
      navigator.browserLanguage,
      navigator.systemLanguage,
    ].filter((entry) => entry);
  } else
    throw new Error("Cannot check window.navigator in a server side context!");

  return normalizeLocales(uniq(languageList));
}

const browserLocale = () => getBrowserLocales()[0] || null;

export default browserLocale;
