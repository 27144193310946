import Currency from "../Currency";
import StrikeText from "../global/StrikeText";
import { getPromotionDetails } from "../../utils/promotion";

export default function DiscountButtonCopy({
  prefix,
  price,
  showDash = true,
  allowCurrencyPostfix = true,
  showPricing = true,
  promotion,
  discountPrice,
}) {
  const promotionDetails = getPromotionDetails(promotion, price);
  const promotionDiscountPrice =
    discountPrice || promotionDetails.discountPrice || price;

  if (!showPricing) {
    return prefix;
  }

  if (price === promotionDiscountPrice || promotionDetails.hideStrikethrough) {
    return (
      <span className={"d-flex"}>
        {prefix} {!!prefix && showDash && <>&#8212;</>}{" "}
        {price && (
          <Currency value={price} round={true} postfix={allowCurrencyPostfix} />
        )}
      </span>
    );
  }

  return (
    <>
      {prefix} {!!prefix && showDash && <>&#8212;</>}{" "}
      <Currency value={promotionDiscountPrice} round={true} />{" "}
      <StrikeText>
        {price && <Currency value={price} round={true} />}
      </StrikeText>
    </>
  );
}
