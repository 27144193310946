import { ResizeObserver as juggleResizeObserver } from "@juggle/resize-observer";
import useResizeObserver from "use-resize-observer";

const hasWindow = typeof window !== "undefined";

if (hasWindow && !window.ResizeObserver) {
  window.ResizeObserver = juggleResizeObserver;
}

export const ResizeObserver = hasWindow
  ? window.ResizeObserver
  : juggleResizeObserver;

export default useResizeObserver;
