import { combineReducers } from "redux";
import normalizedReducers from "../utils/normalized/reducers";
import { ModelType } from "./model";

function meta(state = { authenticated: false }, action) {
  state = normalizedReducers.metaReducer(ModelType, state, action);
  switch (action.type) {
    case `UPDATE_${ModelType}`:
    case `CREATE_${ModelType}`:
      return {
        activeId: action.payload.id,
        authenticated: true,
      };
    case `REMOVE_${ModelType}`:
      return {
        ...state,
        authenticated: false,
      };
    default:
      return state;
  }
}

function byId(state = {}, action) {
  return normalizedReducers.byIdReducer(ModelType, state, action);
}

function allIds(state = [], action) {
  return normalizedReducers.allIdsReducer(ModelType, state, action);
}

export default combineReducers({ meta, byId, allIds });
