import * as LDClient from "launchdarkly-js-client-sdk";
import UAParser from "ua-parser-js";
import { v4 as uuidv4 } from "uuid";

import { analytics } from "../utils/analytics";
import { beforeNavigate } from "../utils/navigate";
import intlService from "./intl";

import { fsInspector } from "./launchDarklyInspectors/fullstory";

interface UserContext {
  kind: "user";
  key: string;
  name?: string;
  email?: string;
  cart_id?: string;
  _meta?: {
    privateAttributes: string[];
  };
}

interface DeviceContext {
  kind: "device";
  key: string;
  device: Record<string, any>;
  query_string: Record<string, any>;
  store: Record<string, any>;
  cart_id?: string;
  session_id: string;
  _meta?: {
    privateAttributes: string[];
  };
}

interface MultiContext {
  kind: "multi";
  user: UserContext;
  device: DeviceContext;
}

class LaunchDarklyInstance {
  _ldClient?: LDClient.LDClient;
  _context?: MultiContext | DeviceContext;
  ldOptions: LDClient.LDOptions = {
    evaluationReasons: true,
    sendEventsOnlyForVariation: true,
    bootstrap: (window as any)?.ldFlags,
    inspectors: [fsInspector],
    application: {
      id: "home",
    },
  };

  get ldClient() {
    if (!this._ldClient) {
      throw new Error("LaunchDarkly client not initialized");
    }

    return this._ldClient;
  }

  get context() {
    if (!this._context) {
      throw new Error("LaunchDarkly context not initialized");
    }

    return this._context;
  }

  async initialize(): Promise<void> {
    this._context = this.getSetContext();

    if (!process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID) {
      throw new Error("GATSBY_LAUNCHDARKLY_CLIENT_ID is required");
    }

    console.log("Initializing LaunchDarkly client");
    console.log("Context", this.context);

    this._ldClient = LDClient.initialize(
      process.env.GATSBY_LAUNCHDARKLY_CLIENT_ID,
      this.context as
        | LDClient.LDSingleKindContext
        | LDClient.LDMultiKindContext,
      this.ldOptions,
    );

    console.log("LaunchDarkly client initialized");

    return this.ldClient.waitUntilReady();
  }

  private getSetContext(
    attributes: Record<string, any> = { user: {}, device: {} },
  ): MultiContext | DeviceContext {
    let userInfo = analytics.getStoredUserInfo();
    return (this._context = userInfo.id
      ? this.multiContext(attributes)
      : this.deviceContext(attributes.device));
  }

  private multiContext(
    attributes: Record<string, any> = { user: {}, device: {} },
  ): MultiContext {
    return {
      kind: "multi",
      user: this.userContext(attributes.user),
      device: this.deviceContext(attributes.device),
    };
  }

  // Construct the userContext object and set it on the singleton
  private userContext(attributes: Record<string, any> = {}): UserContext {
    let userInfo = analytics.getStoredUserInfo();

    return {
      kind: "user",
      key: userInfo.id,
      name: userInfo.traits.first_name + " " + userInfo.traits.last_name,
      email: userInfo.traits.email,
      cart_id: userInfo.traits.cart_id,
      ...attributes,
      _meta: {
        privateAttributes: ["email"],
      },
    };
  }

  private deviceContext(attributes: Record<string, any> = {}): DeviceContext {
    let userInfo = analytics.getStoredUserInfo();
    let uaParser = new UAParser(navigator.userAgent);
    let params = new URLSearchParams(window.location.search);

    let anonymousId = userInfo.anonymousId;

    if (!anonymousId) {
      anonymousId = params.get("ajs_aid") || uuidv4();
      analytics.setAnonymousId(anonymousId);
    }

    const session_id = sessionStorage.getItem("session_id") || uuidv4();
    sessionStorage.setItem("session_id", session_id);

    return {
      kind: "device",
      key: anonymousId,
      session_id,
      device: {
        client: uaParser.getBrowser().name || null,
        os_name: uaParser.getOS().name || null,
        os_version: uaParser.getOS().version || null,
        device_name: uaParser.getDevice().model || null,
        device_type: uaParser.getDevice().type || null,
      },
      query_string: {
        utm_campaign: params.get("utm_campaign") || null,
        utm_source: params.get("utm_source") || null,
        utm_medium: params.get("utm_medium") || null,
        utm_content: params.get("utm_content") || null,
      },
      store: {
        locale: intlService.locale,
        currency: intlService.currency.type,
      },
      cart_id: userInfo?.traits?.cart_id,
      ...attributes,
    };
  }

  variation(featureKey: string, defaultValue: any = false): any {
    console.log("Getting variation for feature", featureKey);

    if (!this.ldClient) return defaultValue;

    const detail = this.ldClient.variationDetail(featureKey, defaultValue);
    return detail.value;
  }

  track(event: string, properties: any): void {
    if (!this.ldClient) return;

    return this.ldClient.track(event, properties);
  }

  async identify(
    attributes: Record<string, any> = { user: {}, device: {} },
  ): Promise<void> {
    if (!this.ldClient) return;

    this.getSetContext(attributes);

    console.log("Identifying user with context", this.context);

    await this.ldClient.identify(this.context);
  }

  async flush(): Promise<void> {
    return this.ldClient.flush();
  }
}

let launchDarkly: LaunchDarklyInstance | null = null;
if (typeof window !== "undefined") {
  launchDarkly = new LaunchDarklyInstance();

  beforeNavigate(async () => {
    await launchDarkly?.flush();
  });
}

export function variation(featureKey: string, defaultValue: any = false): any {
  return launchDarkly && launchDarkly.variation(featureKey, defaultValue);
}

export function track(event: string, properties: any): void {
  launchDarkly && launchDarkly.track(event, properties);
}

export async function identify(attributes: { user: {}; device: {} }) {
  launchDarkly && launchDarkly.identify(attributes);
}

export async function flush() {
  launchDarkly && launchDarkly.flush();
}

export default launchDarkly;
