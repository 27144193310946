export function iterablePost(publicIdString, email) {
  const iterableUrl = new URL(
    "https://links.iterable.com/lists/publicAddSubscriberForm",
  );
  iterableUrl.searchParams.set("publicIdString", publicIdString);

  const formData = new FormData();
  formData.append("email", email);

  return fetch(iterableUrl.toString(), {
    method: "POST",
    body: formData,
  });
}
