import { createSelector } from "reselect";
import i18nSelectors from "../i18n/selectors";

const staticData = (state) => state.staticData;

const contentfulProducts = createSelector(
  staticData,
  i18nSelectors.locale,
  (staticData, locale) => {
    return staticData.contentfulProducts.filter(
      (contentfulProduct) => contentfulProduct.node_locale === locale,
    );
  },
);

const contentfulProductBundles = createSelector(
  staticData,
  i18nSelectors.locale,
  (staticData, locale) => {
    return staticData.contentfulProductBundles.filter(
      (contentfulProductBundle) => contentfulProductBundle.node_locale === locale,
    );
  },
);

const contentfulProductForSku = createSelector(
  contentfulProducts,
  (_, props) => props.sku,
  (contentfulProducts, sku) => {
    return contentfulProducts.find(
      (contentfulProduct) => contentfulProduct.sku === sku,
    );
  },
);

export const contentfulProductBundlesForSlug = createSelector(
  contentfulProductBundles,
  (_, slug) => slug,
  (contentfulProductBundles, slug) => {
    return contentfulProductBundles.find(
      (productBundle) => productBundle.slug === slug,
    );
  },
);

const contentfulProductForId = createSelector(
  contentfulProducts,
  (_, props) => props.contentfulId,
  (contentfulProducts, contentfulId) => {
    return contentfulProducts.find(
      (contentfulProduct) => contentfulProduct.contentfulId === contentfulId,
    );
  },
);

export default {
  contentfulProducts,
  contentfulProductForSku,
  contentfulProductForId,
};
