import normalize from "json-api-normalizer";

function normalizeData(data) {
  return normalize(data, {
    camelizeKeys: false,
    camelizeTypeValues: false,
  });
}

export default {
  normalizeData,
};
