import InvoiceItem, { ModelType } from "./model";

// Utils
import normalizedActions from "../utils/normalized/actions";

export function addInvoiceItemFromNormalizedData(data, associations) {
  return (dispatch) => {
    let invoiceItem = new InvoiceItem();
    invoiceItem.deserialize(data, associations);
    dispatch(addInvoiceItem(invoiceItem));
  };
}

export function addInvoiceItem(data, associations) {
  return (dispatch, getState) => {
    return normalizedActions.updateOrCreateModel(
      dispatch,
      getState().invoiceItems,
      ModelType,
      data,
      associations,
    );
  };
}
