/**
 * Provides utility functions that can utilize the browser to navigate outside
 * the app and interact with the browser's history.
 */

const beforeNavigateCbs = [];
const callBeforeNavigateCbs = (...args) =>
  Promise.allSettled(beforeNavigateCbs.map((cb) => cb(...args)));

/**
 * Navigates to the specified external url.
 *
 * When navigating to an internal url, instead use Gatsby Routing.
 *
 * @param {string} url
 * @param {object} options
 */
export const navigateTo = async (url, options = {}) => {
  await callBeforeNavigateCbs(url);

  if (options.replace) {
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
};

/**
 * Register a function to await for before navigating away from the app.
 *
 * @param {function} cb
 */
export const beforeNavigate = (cb) => {
  beforeNavigateCbs.push(cb);
};

export const reload = () => {
  window.location.reload();
};
