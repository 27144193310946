import styled from "styled-components";
import { Font, Color, rem, responsive, Opacity } from "../../../utils/style";
import MagicLink from "../../MagicLink";
import DoNotTrack from "./DoNotTrack";
import Text from "../../Text";
import intl from "../../../services/intl";

const CopyrightTextWrapper = styled.div.attrs({
  id: "footer_container_row-2_column-0_copyright-text",
})`
  ${Font.circular}
  font-size: ${rem(12)};
  line-height: ${rem(22)};
  font-weight: 500;
  color: ${Color.ritualBlue};
  margin: 0;

  a {
    margin-right: 16px;
    font-weight: 500;
    color: ${Color.ritualBlue};
    display: inline-block;
    transition: all 0.2s;
    cursor: pointer;

    &:hover {
      opacity: ${Opacity.light};
    }
  }

  span {
    ${Font.circular}
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    font-weight: 500;
    font-weight: 500;
    white-space: nowrap;
    display: block;
    margin-top: 4px;

    ${responsive.md`
      display: inline;
    `}
  }
`;

const CopyrightText = () => {
  const year = new Date().getFullYear();
  const links = [
    {
      to: "/terms-of-service",
      text: intl.t("footer.copyright.link.terms", "Terms of Service"),
    },
    {
      to: "/privacy-policy",
      text: intl.t("footer.copyright.link.privacy", "Privacy Policy"),
    },
    {
      to: intl.t(
        "footer.copyright.link.editorial-link",
        "/articles/protecting-people-supply-chain",
      ),
      text: intl.t("footer.copyright.link.editorial", "CA Transparency Act"),
    },
    {
      // https://www.thoughtco.com/html5-placeholder-links-3468070
      text: intl.t("footer.copyright.link.accessibility", "Accessibility"),
      class: "accessibility-link",
    },
  ];

  return (
    <CopyrightTextWrapper>
      {links.map((link, index) => (
        <MagicLink key={index} to={link.to} className={link.class}>
          {link.text}
        </MagicLink>
      ))}
      <DoNotTrack />
      <br className="d-block d-lg-none" />
      <span>
        <Text
          id="footer.copyright.copyright"
          defaultMessage="© {year} Ritual. All rights reserved."
          values={{ year }}
        />
      </span>
    </CopyrightTextWrapper>
  );
};
export default CopyrightText;
