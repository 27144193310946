import { createSelector } from "reselect";
import siteStore from "../../services/siteStore";

const _byId = (state) =>
  state.productOfferPlans ? state.productOfferPlans.byId : {};
const _allIds = (state) =>
  state.productOfferPlans ? state.productOfferPlans.allIds : [];

const allProductOfferPlans = createSelector(_byId, _allIds, (byId, allIds) =>
  allIds.map((id) => byId[id]),
);

export const productOfferPlanForId = createSelector(
  allProductOfferPlans,
  (_, id) => id,
  (allProductOfferPlans, id) =>
    allProductOfferPlans.find((productOfferPlan) => productOfferPlan.id === id),
);

export const allProductOffersPlansForCurrentStoreByIds = createSelector(
  allProductOfferPlans,
  (_, ids) => ids,
  (state) => state,
  (allProductOfferPlans, ids, state) => {
    return allProductOfferPlans.filter((productOfferPlan) => {
      const initialPlan = productOfferPlan.initialPlan(state);
      return (
        ids.includes(productOfferPlan.id) &&
        initialPlan?.storeId === siteStore.storeId
      );
    });
  },
);
