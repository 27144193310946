import { Component } from "react";
import styled from "styled-components";
import { Opacity } from "../../../utils/style";
import { Icons } from "../../../utils/react-svg";

// Redux
import { connect } from "react-redux";
import { closeNavPanel } from "../../../store/navigation/actions";

const CloseButtonWrapper = styled.div``;

const CloseButtonElement = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  height: 44px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const CloseButtonIconWrapper = styled.div`
  position: relative;
  height: 16px;
  width: 16px;
  padding: 0;
  margin-top: 3px;

  svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export class CloseButtonComponent extends Component {
  render() {
    return (
      <CloseButtonWrapper>
        <CloseButtonElement
          onClick={this.props.dispatchClosePanel.bind(this, 0)}
          aria-label="Close"
        >
          <CloseButtonIconWrapper>
            <Icons.Close></Icons.Close>
          </CloseButtonIconWrapper>
        </CloseButtonElement>
      </CloseButtonWrapper>
    );
  }
}

export default connect(null, {
  dispatchClosePanel: closeNavPanel,
})(CloseButtonComponent);
