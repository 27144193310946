import classNames from "classnames";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import useThrottledResizeObserver from "../hooks/useThrottledResizeObserver";
import intl from "../services/intl";
import { updateNavigation } from "../store/navigation/actions";
import { Icons } from "../utils/react-svg";
import { Font, Opacity, responsive } from "../utils/style";

export const Wrapper = styled.section`
  margin-bottom: -4px;
  &.scrolled {
    margin-bottom: 0;
  }
  &.legacy {
    position: relative;
    overflow: hidden;
    text-align: center;
    font-size: 13px;
    line-height: 17px;
    ${Font.circular}
    background: #142b6f;
    color: #fff;
  }
`;

const Container = styled.div`
  position: relative;
  padding: 0 40px;
  height: auto !important;

  padding: 0 36px 0px 15px;
  text-align: left;

  ${responsive.sm`
    padding-left: 24px;
  `}

  ${responsive.md`
    text-align: center;
    padding: 0px 48px;
  `}
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 15px;

  ${responsive.sm`
    padding-right: 24px;
  `}

  ${responsive.lg`
    padding-right: 40px;
  `}
`;

const CloseButton = styled.button`
  height: 16px;
  width: 16px;
  position: relative;
  opacity: ${Opacity.light};
  transition: opacity 200ms ease-in-out;

  appearance: none;
  background: none;
  border: 0;
  padding: 0;

  &:hover {
    opacity: 1;
  }
`;

const CloseIcon = styled(Icons.Close)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  g {
    stroke: white;
  }
`;

const useScrolled = () => useSelector((state) => state.navigation.scrolled);

const useNavigationOffset = (offset) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateNavigation({ offset }));
  }, [offset, dispatch]);
};

const MessageBanner = ({ closeAction, renderTopBar, children }) => {
  const [isOpen, setOpen] = useState(true);
  const scrolled = useScrolled();
  const { ref: wrapperRef, height } = useThrottledResizeObserver();
  useNavigationOffset(height || 0);

  const closeBanner = () => {
    setOpen(false);
    closeAction?.();
  };

  const legacy = !renderTopBar;

  // Hide the banner if the current url contains "checkout"
  if (
    typeof window !== "undefined" &&
    window.location.pathname.includes("checkout")
  ) {
    return null;
  }

  return (
    <Wrapper
      className={classNames(
        isOpen ? "d-block" : "d-none",
        scrolled && "scrolled",
        legacy && "legacy",
      )}
      ref={wrapperRef}
    >
      {renderTopBar?.({ closeTopBar: closeBanner }) || (
        <>
          <Container>{children}</Container>
          <CloseButtonContainer>
            <CloseButton
              onClick={closeBanner}
              aria-label={intl.t("general.close", "Close")}
            >
              <CloseIcon />
            </CloseButton>
          </CloseButtonContainer>
        </>
      )}
    </Wrapper>
  );
};

export default MessageBanner;
