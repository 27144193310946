import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import { productPromotionPrice } from "../../utils/productPromotion";
import { buildUrl } from "../../utils/imgix";
import bindClassNames from "classnames/bind";

// Components
import Currency from "../Currency";
import Text from "../Text";
import {
  CartItemContainer,
  CartItemSection,
  ImageAndSectionContainer,
  ProductTitleDefault,
  SectionWrapper,
  SubscriptionInfo,
} from "./CartItem";

// Redux
import { useSelector } from "react-redux";
import promotionSelectors from "../../store/promotion/selectors";

// Styled Elements
const Price = styled.p`
  color: ${Color.ritualGreen};
  font-weight: 300;
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `};

  span {
    margin-right: 9px;
    text-decoration: line-through;
    color: ${Color.lessFadedBlue};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  margin-right: 12px;

  ${responsive.md`
    width: 120px;
    min-width: 120px;
    height: 120px;
    margin-right: 24px;
  `}

  &.simplified {
    width: 108px;
    min-width: 108px;
    height: 108px;
    margin-right: 16px;

    ${responsive.md`
      :not(.flyout) {
        width: 136px;
        min-width: 136px;
        height: 136px;
        margin-right: 16px;
      }
    `}
  }
`;

function ProductPromotionCartItem({ className, isFlyoutContext, simplified }) {
  const product = useSelector(
    promotionSelectors.appliedProductPromotionProduct,
  );

  if (!product) {
    return null;
  }

  const classNames = bindClassNames(
    className,
    simplified && "simplified",
    isFlyoutContext && "flyout",
  );

  return (
    <>
      <CartItemContainer className={classNames}>
        <ImageAndSectionContainer>
          <ImageWrapper className={classNames}>
            <img
              loading="eager"
              alt={product.name}
              src={buildUrl(product.imagePath)}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: "0",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          </ImageWrapper>
          <SectionWrapper className={classNames}>
            <CartItemSection>
              <ProductTitleDefault>{product.name}</ProductTitleDefault>
            </CartItemSection>
            <CartItemSection>
              <SubscriptionInfo>
                <Text
                  id={"product.promotions.free-gift"}
                  defaultMessage={"Free Gift"}
                />
              </SubscriptionInfo>
              <Price>
                <span>
                  <Currency
                    round={true}
                    value={productPromotionPrice(product.sku)}
                  />
                </span>
                <Text id={"product.promotions.free"} defaultMessage={"Free"} />
              </Price>
            </CartItemSection>
            <CartItemSection>
              <SubscriptionInfo>
                <Text
                  id={"product.promotions.limited-availability"}
                  defaultMessage={"Limited Availability"}
                />
              </SubscriptionInfo>
            </CartItemSection>
          </SectionWrapper>
        </ImageAndSectionContainer>
      </CartItemContainer>
    </>
  );
}

export default ProductPromotionCartItem;
