import { useEffect, useState } from "react";
import styled from "styled-components";

// Segment
import {
  ConsentManager,
  onPreferencesSaved,
  savePreferences,
} from "@segment/consent-manager";
import inEU from "@segment/in-eu";

// Services
import intl from "../../services/intl";

// Utils
import { cookieDomain, getCookie } from "../../utils/cookies";
import { Color, responsive } from "../../utils/style";
import { updateUserPreferences } from "../../utils/segment";

// Components
import MagicLink from "../../components/MagicLink";
import errorReporter from "../../utils/errorReporter";
import USCookieBanner from "./USCookieBanner";

// Styled Components
const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999;

  & > div {
    padding: 16px;
    text-align: left;
    ${responsive.md`
      padding-left: 40px;
    `}
  }

  p {
    margin: 0 !important;

    &:nth-child(2) {
      display: none;
    }
  }

  &.simple {
    p {
      padding-right: 15px;
    }
  }

  & button[title~="Close"] {
    top: 24px;
    font-size: 24px;
  }
`;

const Copy = styled.p`
  margin: 0;
`;

const fetchSegmentCategories = async () => {
  const segmentCategoriesUrl = `${process.env.GATSBY_CLOUDFLARE_HOST}/segment-categories`;
  try {
    const response = await fetch(segmentCategoriesUrl);
    const json = await response.json();
    return json;
  } catch (e) {
    errorReporter.error(e, {
      location: "SegmentConsentManager.fetchSegmentCategories",
    });
  }
};

const segmentConsentManager = () => {
  const [showBanner, setShowBanner] = useState(true);
  const [showEUBanner, setShowEUBanner] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [customCategories, setCustomCategories] = useState(null);

  const PreferencesDialogTitle = intl.t(
    "segment.consent.pref-title",
    "Website Data Collection Preferences",
  );
  const PreferencesDialogTemplate = {
    headings: {
      allowValue: "Allow",
      categoryValue: "Category",
      purposeValue: "Purpose",
      toolsValue: "Tools",
    },
    checkboxes: {
      noValue: "No",
      yesValue: "Yes",
    },
    actionButtons: {
      cancelValue: "Cancel",
      saveValue: "Save",
    },
    cancelDialogButtons: {
      cancelValue: "Yes, Cancel",
      backValue: "Go Back",
    },
    // These categories are used if our API call to `segment-categories` fails
    categories: [
      {
        key: "functional",
        name: "Functional",
        description:
          "To monitor the performance of our site and to enhance your browsing experience.",
        example:
          "For example, these tools enable you to communicate with us via live chat.",
      },
      {
        key: "marketing",
        name: "Analytics",
        description:
          "To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site.",
        example:
          "For example, we collect information about which pages you visit to help us present more relevant information.",
      },
      {
        key: "advertising",
        name: "Advertising",
        description:
          "To personalize and measure the effectiveness of advertising on our site and other websites.",
        example:
          "For example, we may serve you a personalized ad based on the pages you visit on our site.",
      },
      {
        key: "essential",
        name: "Essential",
        description:
          "We use browser cookies that are necessary for the site to work as intended.",
        example:
          "For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.",
      },
    ],
  };
  const PreferencesDialogContent = intl.t(
    "segment.consent.pref-content",
    "We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.",
  );
  const CancelDialogTitle = intl.t(
    "segment.consent.cancel-title",
    "Are you sure you want to cancel?",
  );
  const CancelDialogContent = intl.t(
    "segment.consent.cancel-content",
    "Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Privacy Policy.",
  );

  const otherWriteKeys = [
    process.env.GATSBY_CHECKOUT_SEGMENT_WRITE_KEY,
    process.env.GATSBY_ACCOUNT_SEGMENT_WRITE_KEY,
    process.env.GATSBY_THESIS_SEGMENT_WRITE_KEY,
    process.env.GATSBY_INSTAPAGE_SEGMENT_WRITE_KEY,
  ].filter(Boolean);

  const consentManagerOptions = {
    writeKey: process.env.GATSBY_SEGMENT_WRITE_KEY,
    otherWriteKeys,
    cdnHost: "analytics.seg.ritual.com",
    cookieDomain: cookieDomain(process.env.GATSBY_COOKIE_DOMAIN),
    defaultDestinationBehavior: "enable",
    shouldRequireConsent: () => showEUBanner,
    preferencesDialogTitle: PreferencesDialogTitle,
    preferencesDialogTemplate: PreferencesDialogTemplate,
    preferencesDialogContent: PreferencesDialogContent,
    cancelDialogTitle: CancelDialogTitle,
    cancelDialogContent: CancelDialogContent,
    closeBehavior: "dismiss",
  };
  const bannerOptions = {
    bannerContent: (
      <Copy>
        Ritual, and the companies we work with, use cookies and other
        technologies to collect data to improve your experience on our site,
        analyze site usage, and facilitate advertising.By clicking "Allow All",
        you agree to the storing of cookies as outlined in our{" "}
        <MagicLink to="/privacy-policy">Privacy Policy</MagicLink>.
      </Copy>
    ),
    bannerActionsBlock: showEUBanner,
    bannerHideCloseButton: showEUBanner,
    bannerSubContent: null,
    bannerTextColor: Color.ritualBlue,
    bannerBackgroundColor: "#FDF8EC",
  };

  let options = { ...consentManagerOptions, ...bannerOptions };

  if (!showEUBanner) {
    options = {
      ...options,
      initialPreferences: {
        Functional: true,
        Analytics: true,
        Advertising: true,
        "Session Replay": true,
        Essential: "N/A",
      },
    };
  }

  if (customCategories) {
    options = {
      ...options,
      customCategories,
    };
  }

  useEffect(() => {
    const trackingPreferences = getCookie("tracking-preferences");
    // Requires `window` to call inEU
    const isInEU = inEU();
    if (isInEU) {
      onPreferencesSaved(updateUserPreferences);
    } else if (!isInEU && !trackingPreferences) {
      savePreferences({
        customPreferences: {
          Functional: true,
          Analytics: true,
          Advertising: true,
          "Session Replay": true,
          Essential: "N/A",
        },
      });
    }

    // Only show banner if no tracking preferences are set
    // or if user is in EU and has not set preferences
    setShowBanner(!isInEU && !trackingPreferences);
    setShowEUBanner(isInEU && !trackingPreferences);

    if (window.navigator.globalPrivacyControl) {
      savePreferences({
        // Preferences object found here: https://github.com/segmentio/consent-manager/blob/c536330fd87904d95724b4d96a8f63b8cdf802ec/src/types.ts#L51
        customPreferences: {
          Functional: true,
          Analytics: true,
          Advertising: false,
          "Session Replay": false,
          Essential: "N/A",
        },
      });
    }

    fetchSegmentCategories()
      .then((response) => {
        setCustomCategories(response);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });
  }, []);

  if (!loaded) return null;

  return (
    <Container id="consent-manager-eu" className={!showEUBanner && "simple"}>
      <ConsentManager {...options} />
      {!showEUBanner && <USCookieBanner showBanner={showBanner} />}
    </Container>
  );
};

export default segmentConsentManager;
