import { createSelector } from "reselect";

const app = (state) => state.app;

const isLoaded = createSelector(app, (app) => app.appLoaded);

/**
 * Whether we finished trying to load the user. When authed, this is false until
 * the user has been fetched. When unauthed, this is true as soon as the
 * guest_id has been loaded.
 */
const isUserLoaded = createSelector(app, (app) => app.authInitialized);

// Whether we are actively fetching any user data. This can be true both at
// initial load and after auth. If the user is unauthed, this will still be
// true if we're fetching the user's cart.
const isFetchingUserData = createSelector(
  app,
  (app) => app.fetchingDataAfterAuth,
);

const isUserDataLoaded = createSelector(
  isUserLoaded,
  isFetchingUserData,
  (isUserLoaded, isFetchingUserData) => {
    return isUserLoaded && !isFetchingUserData;
  },
);

export default {
  isLoaded,
  isUserLoaded,
  isFetchingUserData,
  isUserDataLoaded,
};
