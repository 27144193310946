import Model from "../model";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "USER";

export default class User extends Model {
  // Attributes
  email: string = "";
  firstName: string = "";
  lastName: string = "";
  castleJwt: string = "";
  status: string = "";
  locale: string = "";
  intercomHash: string = "";
  phone: string = "";

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  serialize(updatedAttributes: { [key: string]: any }) {
    return this.serializeInternal(updatedAttributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);
  }
}
