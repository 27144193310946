import { useLocation } from "@gatsbyjs/reach-router";
import OverlayLink from "../OverlayLink";

// Helpers
import intl from "../../services/intl";
import { detectLocale } from "../../utils/suggestLocale";

// Redux
import { useSelector } from "react-redux";
import i18nSelectors from "../../store/i18n/selectors";
import { IsStorageSupported } from "../../utils/helpers";

const HideKey = "hide-locale-prompt";

const LocaleSuggester = () => {
  if (typeof window === "undefined" || !IsStorageSupported) return null;

  const suggestedLocale = useSelector(i18nSelectors.localeSuggestion);
  const hidden = sessionStorage.getItem(HideKey);

  if (!suggestedLocale || hidden) return null;

  const location = useLocation();

  const currentLocale = intl.locale;
  const isDirectMatch = detectLocale() === suggestedLocale;

  const getHeading = () => {
    const values = {
      locale: intl.t(`locales.${suggestedLocale}.name-long`, suggestedLocale),
    };

    return isDirectMatch
      ? intl.t(
          "locale-selector.suggestion-heading",
          "Shopping in {locale}?",
          values,
        )
      : intl.t(
          "locale-selector.suggestion-heading-no-match",
          "Switch to {locale}?",
          values,
        );
  };

  const getSubheading = () => {
    const values = {
      viewing: intl.t(`locales.${currentLocale}.name-long`, currentLocale),
      actual: intl.t(`locales.${suggestedLocale}.name-long`, suggestedLocale),
    };

    return isDirectMatch
      ? intl.t(
          "locale-selector.suggestion-copy",
          "You’re viewing our website for {viewing}, but it looks like you're in {actual}.",
          values,
        )
      : intl.t(
          "locale-selector.suggestion-copy-no-match",
          "You’re viewing our website for {viewing}, but you may want to shop on our site in {actual}.",
          values,
        );
  };

  const destination = intl.pathForSelection(suggestedLocale, location);

  const onCloseClick = () =>
    sessionStorage.setItem("hide-locale-prompt", "true");

  const onClick = () => {
    intl.persistLocale(suggestedLocale);
    window.location.href = destination;
  };

  return (
    <OverlayLink
      theme="white"
      wide={true}
      heading={getHeading()}
      subheading={getSubheading()}
      onCloseClick={onCloseClick}
      onClick={onClick}
    />
  );
};

export default LocaleSuggester;
