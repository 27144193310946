import CountdownBanner from "./CountdownBanner";

export default function BFCMProspectBanner({ promotion }) {
  if (!promotion) return null;

  return (
    <CountdownBanner
      title={`banners.bfcm-prospect.title`}
      timerTitle={`banners.bfcm-prospect.title-timer`}
      prefix={"bfcm-prospect"}
      promotion={promotion}
    />
  );
}
