import Model from "../model";
import { Promotions } from "./types";
import { NormalizedModel } from "../utils/normalized/types";

export const ModelType = "SUBSCRIPTION";

export default class Subscription extends Model {
  // Attributes

  // Relationships
  userId: string = "";
  promotions: Array<Promotions> = [];

  constructor(attributes: { [key: string]: any }) {
    super();
    this.addAttributes(attributes);
  }

  deserialize(input: NormalizedModel) {
    this.id = input.id;
    this.addAttributes(input.attributes);

    const relationships = input.relationships;
    this.userId = this.getRelationshipId(relationships, "user");
  }
}
