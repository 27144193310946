import { useEffect } from "react";
import styled from "styled-components";
import throttle from "lodash/throttle";
import classNames from "classnames/bind";

// Utils
import { Variables, Color, media, rem, responsive } from "../../../utils/style";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { updateNavigation } from "../../../store/navigation/actions";

// Services
import cartService from "../../../services/cart";
import intl from "../../../services/intl";

// Components
import Text from "../../Text";
import MagicLink from "../../MagicLink";
import CloseButton from "./CloseButton";
import RitualLogo from "../RitualLogo";
import AccountDropdown from "./AccountDropdown";
import CartIconLink from "./CartIconLink";
import { Icons } from "../../../utils/react-svg";

const CATMS = Variables.navContentAnimationTime;
// Styled Elements
const Wrapper = styled.div`
  position: fixed;
  z-index: 110;
  // Width excluding scrollbars.
  width: calc(100vw - (100vw - 100%));
`;

const NavContainer = styled.div`
  width: 100%;
  margin: 0 auto;

  ${responsive.sm`
    width: calc(100vw - 48px);
  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    max-width: 1170px;
  `}
`;

const NavRow = styled.div`
  height: 100%;
  padding: 0 15px;

  ${responsive.sm`
    padding: 0;
  `}
`;

const TopNavElement = styled.nav`
  display: flex;
  border-top: 4px solid ${Color.ritualBlue};
  position: fixed;
  background-color: ${Color.white};
  z-index: 110;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${Color.white};
  transition: border-bottom 200ms ease-in-out;
  box-sizing: content-box;

  &.scrolled {
    border-top: none;
    border-bottom: 1px solid #ccc;
  }

  ${responsive.sm`
    transition: none;

    &.scrolled {
      border-top: none;
      border-bottom: 1px solid #ccc;
    }
  `}

  ${responsive.md`
    height: 64px;
  `}
`;
const NavItemList = styled.ul`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
`;

const Hover = styled.span`
  background-color: ${Color.ritualYellow};
  width: 100%;
  height: 0px;
  left: 0;
  position: absolute;
  bottom: -1px;
  transition: height 200ms ease-in-out;
`;

const NavItem = styled.li`
  align-items: center;
  align-content: center;
  display: flex;
  height: 100%;
  position: relative;
`;

const MenuItem = styled(NavItem)`
  flex-grow: 1;

  ${media.mobile`
    visibility: visible;
    transform: translate(0, 0);
    transition: transform 500ms ease-in-out;

    &.open {
      visibility: hidden;
      transform: translate(-100vw, 0);
      transition: transform 500ms ease-in-out, visibility 1ms linear 500ms;
    }
  `}
`;

const LogoItem = styled(NavItem)`
  position: absolute;
  left: calc(50% - 25px);

  ${media.mobile`
    transition: left 400ms ease-in-out;
    visibility: visible;

    &.open {
      left: 6px;

      &.submenu {
        visibility: hidden;
        left: -65px;
        transition: left 400ms ease-in-out, visibility 1ms linear 400ms;
      }
    }
  `}
`;

const FeaturedItem = styled(NavItem)`
  display: flex;
  margin-right: 56px;

  ${media.mobile`
    display: none;
  `}
`;

const RightItem = styled(NavItem)`
  ${media.mobile`
    transition: transform 500ms ease-in-out;
    transform: translate(0, 0);

    &.open {
      transform: translate(-38px, 0);
    }
  `}
`;

const CartIem = styled(RightItem)`
  width: 30px;

  ${responsive.md`
  width: 30px;
  `}
`;

const AccountItem = styled(RightItem)`
  margin-right: 16px;

  ${responsive.md`
    margin-right: 24px;
  `}
`;

const BackItem = styled(NavItem)`
  position: absolute;
  left: 40px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: left ${CATMS} ease-in-out, opacity ${CATMS} ease-in-out,
    visibility 1ms ease-in-out ${CATMS};

  &.submenu {
    left: 0;
    visibility: visible;
    opacity: 1;
    z-index: 1;
    transition: left 400ms ease-in-out 200ms, opacity 400ms ease-in-out 200ms,
      visibility 400ms ease-in-out 200ms;
  }

  ${responsive.sm`
    display: none;
  `}
`;

const CloseItem = styled(NavItem)`
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
  transform: translate(17vw, 0);
  transition: transform 500ms ease-in-out, visibility 1ms linear 500ms;

  &.open {
    visibility: visible;
    transform: translate(0, 0);
    transition: transform 500ms ease-in-out, visibility 1ms linear;
  }

  ${responsive.sm`
    display: none;
  `};
`;

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${responsive.sm`
    display: none;
  `}
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  padding: 0;
`;

const MenuText = styled.p`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  margin: 0;
`;

const LogoWrapper = styled.div`
  svg {
    height: 16px;
    width: 51px;

    ${responsive.sm`
      height: 18px;
      width: 58px;
    `}

    ${responsive.md`
      height: 22px;
      width: 70px;
    `}
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
`;

const FeaturedPageLink = styled(MagicLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
`;

const NavItemContent = styled.div`
  position: relative;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  [data-whatintent="mouse"] &:hover {
    ${Hover} {
      height: 4px;
    }
  }
`;

export default function TopNavComponent(props) {
  const navigation = useSelector((state) => state.navigation);

  const dispatch = useDispatch();

  const { isOpen, panels, toggleNavigation } = props;
  const { scrolled } = navigation;

  useEffect(() => {
    const throttledHandleScroll = throttle(handleScroll, 10);

    window.addEventListener("scroll", throttledHandleScroll);

    return () => {
      window.removeEventListener("scroll", throttledHandleScroll);
    };
  }, [scrolled]);

  function handleScroll() {
    if (window.scrollY >= 12 && !scrolled) {
      dispatch(updateNavigation({ scrolled: true }));
    } else if (window.scrollY < 12 && scrolled) {
      dispatch(updateNavigation({ scrolled: false }));
    }
  }

  function handleCartClick(e) {
    if (!cartService.isFlyout) props.handleNavClick(e);
    else props.closeNavPanel(0);
    cartService.openCart(e);
  }

  function handleBackClick() {
    const { panels } = props;
    let currentDepth = 0;
    for (let i = panels.length - 1; i >= 0; i--) {
      if (panels[i]) {
        currentDepth = i;
        break;
      }
    }
    props.closeNavPanel(currentDepth);
  }

  const topNavClassNames = classNames({
    open: isOpen,
    submenu: panels[1],
    scrolled,
  });
  const menuButtonText = "Menu";

  return (
    <Wrapper>
      {props.children}
      <TopNavElement className={topNavClassNames}>
        <NavContainer>
          <NavRow>
            <NavItemList>
              <MenuItem className={topNavClassNames}>
                <UnderscoreHover>
                  <MenuButton
                    onClick={props.toggleNavigation}
                    aria-haspopup="true"
                    aria-expanded={isOpen ? "true" : "false"}
                  >
                    <MenuText>{menuButtonText}</MenuText>
                  </MenuButton>
                </UnderscoreHover>
              </MenuItem>

              <LogoItem className={topNavClassNames}>
                <LogoWrapper>
                  <RitualLogo
                    to="/"
                    title="Ritual"
                    handleNavigateClick={props.handleNavClick}
                  />
                </LogoWrapper>
              </LogoItem>

              <BackItem className={topNavClassNames}>
                <BackButton
                  aria-label={intl.t("general.back", "Back")}
                  onClick={handleBackClick}
                >
                  <Icons.ArrowRoundedLeft />
                </BackButton>
              </BackItem>

              <TraceabilityFeaturedLink {...props} />

              <AccountItem className={topNavClassNames}>
                <UnderscoreHover>
                  <AccountDropdown onClick={() => props.closeNavPanel(0)} />
                </UnderscoreHover>
              </AccountItem>
              <CartIem className={topNavClassNames}>
                <UnderscoreHover>
                  <CartIconLink className="path" onClick={handleCartClick} />
                </UnderscoreHover>
              </CartIem>

              <CloseItem className={topNavClassNames}>
                <CloseButtonWrapper>
                  <CloseButton handleCloseClick={toggleNavigation} />
                </CloseButtonWrapper>
              </CloseItem>
            </NavItemList>
          </NavRow>
        </NavContainer>
      </TopNavElement>
    </Wrapper>
  );
}

function TraceabilityFeaturedLink(props) {
  function handleTraceabilityClick(e) {
    props.handleNavClick(e, "Header Nav");
  }

  return (
    <FeaturedItem>
      <UnderscoreHover>
        <FeaturedPageLink
          to="/made-traceable"
          onClick={handleTraceabilityClick}
          hrefLang="x-default"
        >
          <Text id="nav.links.our-standards" defaultMessage="our Standards" />
        </FeaturedPageLink>
      </UnderscoreHover>
    </FeaturedItem>
  );
}

function UnderscoreHover({ children }) {
  return (
    <NavItemContent>
      {children}
      <Hover />
    </NavItemContent>
  );
}
