import styled from "styled-components";
import { rem } from "../../utils/style";

import ProgressBar from "../global/ProgressBar";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Message = styled.span`
  display: block;
  font-weight: 300;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  margin-bottom: 8px;
`;

export default function ShippingProgress({ copy, percent }) {
  return (
    <Container>
      <Message>{copy}</Message>
      <ProgressBar percent={percent} />
    </Container>
  );
}
